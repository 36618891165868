import React, { useContext, useEffect, useState } from 'react';
import Container from "../Container";
import Context from "../../../context";
import { useLocation, useParams } from "react-router-dom";
import Spinner from "../Spinner";

const DocumentViewer = ({ type, dailyData, docData = null }) => {
  const [loading, setLoading] = useState(true);

  const { viewMode, setScreenNameOverride, itin } = useContext(Context);
  const { docId } = useParams();
  const { pathname } = useLocation();

  const isDocuments = type?.includes("Documents");
  const docs = dailyData ? dailyData : type === "singleDocuments" ? [...itin.destinationDocuments, ...itin.travelDocuments] : itin[type];
  const item = isDocuments
    ? (docs.find(item => item.id === parseInt(docId)))
    : docData;

  useEffect(() => {
    setScreenNameOverride(item?.name);

    return () => {
      setScreenNameOverride(null);
    };
  }, [setScreenNameOverride, setLoading, item?.name]);

  const handleLoad = () => {
    setLoading(false);
  };

  return (
    <Container
      className="container-doc-viewer"
      mainElementClassName={`doc-viewer doc-viewer-${viewMode}`}
      closePath={pathname.replace(/\/view.*/, '')}
    >
      {loading && <Spinner type="small" />}
      <iframe
        title="PDF Viewer"
        src={item?.attachment?.fileUrl}
        width="100%"
        height="100%"
        style={{
          overflow: 'scroll',
          display: loading ? 'none' : 'block',
          border: 'none',
          maxHeight: '100svh'
        }}
        onLoad={handleLoad}
      ></iframe>
    </Container>
  );
};

export default DocumentViewer;