import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useParams, useNavigate, Route, Routes, useSearchParams } from 'react-router-dom';
import Context from '../../context';
import { getPreviewItinerary } from "../../libraries/api";
import LoadingScreen from '../global/LoadingScreen';
import Brief from "../Brief";
import DailySummary from "../Brief/Summary";
import DailyFlights from "../Brief/Flights";
import DailyMap from "../Brief/Map";
import DailyWeather from "../Brief/Weather";
import Map from '../Map';
import appConfig  from '../../config/app';
import DailyDocumentViewer from "../Brief/DocumentViewer";

const Preview = () => {
  const [previewType, setPreviewType] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { removeItemsFromCustomHistory, setGlobalModalContent, t, setItin, itin } = useContext(Context);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { previewLinkId } = useParams();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (pathname.match(new RegExp('^/sample-trip'))) {
      removeItemsFromCustomHistory(-1);
      navigate(pathname.replace(/sample-trip/i, 'preview'), { replace: true });
    }

    setPreviewType(() => {
      if (pathname.match(new RegExp('^/preview-maps'))) {
        return 'maps';
      }
      return 'default';
    });
  }, [navigate, pathname, removeItemsFromCustomHistory]);

  useEffect(() => {
    if (!previewLinkId) return;
    let isActive = true;

    const run = async () => {
      const { data, res, error } = await getPreviewItinerary(previewLinkId);

      if (isActive && (!res.ok || error)) {
        setGlobalModalContent(() => ({
          closable: true,
          // TODO: translation
          message: res.status === 404 ? t('not_found', { lng: itin?.language }) : t('error_api_unknown', { lng: itin?.language }),
          buttons: [{
            text: t('ok', { lng: itin?.language }),
            onClick: () => {
              setGlobalModalContent(null);
              navigate('/' + window.location.origin);
            }
          }]
        }));
        return;
      }

      if (data.type === 'stay') {
        removeItemsFromCustomHistory(-1);
        // React router v6 doesn't deal with external navigation
        window.location.replace(appConfig.generateWebstaysUrl(data.operatorCode, searchParams.get('branding')));
      } else if (previewLinkId !== data.previewLinkId && data.previewLinkId) {
        removeItemsFromCustomHistory(-1);
        navigate(pathname.replace(previewLinkId, data.previewLinkId), { replace: true });
      } else {
        setItin(data);
        setIsLoading(false);
      }

    };

    run();
    return () => {
      isActive = false;
    };
  }, [navigate, previewLinkId, removeItemsFromCustomHistory, setGlobalModalContent, setItin, t, pathname, searchParams, itin?.language]);

  if (!previewType || isLoading) {
    return <LoadingScreen />;
  }

  if (previewType === 'maps') {
    return (
      <Routes>
        <Route path="/" element={<Map />} >
          <Route path=":poiId" element={<Map />} />
        </Route>
      </Routes>
    );
  }

  return (
    <Routes>
      <Route path="/" element={ <Brief type="storyboard" />}>
        <Route path=":slideNum" element={<DailySummary />} />
        <Route path=":slideNum/flights" element={<DailyFlights />} >
          <Route path=":id" element={<DailyFlights />} />
        </Route>
        <Route path=":slideNum/maps" element={<DailyMap/>}>
          <Route path=":poiId" element={<DailyFlights/>}/>
        </Route>
        <Route path=":slideNum/weather" element={<DailyWeather />} />
      </Route>
      <Route path=":slideNum/view/:docId" element={<DailyDocumentViewer type="previewDocuments" />} />
    </Routes>
  );
};

export default Preview;
