import { useEffect, useContext, useCallback } from 'react';
import Context from '../../context';
import { db } from '../../db';

const MapNavigationSettings = ({ children }) => {
  const { itin, setItin, previewMode, globalMapSettings, setGlobalMapSettings } = useContext(Context);

  const generateSettingsObject = useCallback((proposedSettings = null) => {
    // Builds settings object
    // Compares proposed settings with the pois/tracks/routes available on the itinerary
    // Discards proposed settings for pois/tracks/routes which are no longer in the current version of the itinerary

    const settings = {
      pois: {
        ...itin.pois.reduce((out, x) => ({ ...out, [x.id]: { ...x, type: 'poi'} }), {}),
        ...itin.tracks?.reduce((out, x) => ({ ...out, [x.id]: { ...x, type: 'track'} }), {}),
        ...itin.routes?.reduce((out, x) => ({ ...out, [x.id]: { ...x, type: 'route'} }), {}),
      },
      connectedLocations: {
        ...itin.locations?.reduce((out, x) => {
          if (x.nesting) {
            out[x.id] = { ...x, type: 'location' };
          }
          return out;
        }, {}),
      },
      poiCategories: {},
      showLocationMarkerLabels: proposedSettings?.showLocationMarkerLabels || false
    };

    const populateCategories = x => {
      if (!settings.poiCategories[x.iconId]) settings.poiCategories[x.iconId] = { id: x.iconId, label: x.iconLabel, count: 1, active: false }
      else settings.poiCategories[x.iconId].count++;
    }

    itin.pois.forEach(x => populateCategories(x));
    itin.tracks?.forEach(x => populateCategories(x));
    itin.routes?.forEach(x => populateCategories(x));
    // itin.tracks.forEach(x => {
    //   // itin.tracks.pois?.forEach(p => settings.poiCategories[p.iconId] = { label: p.iconLabel });
    //   settings.poiCategories[x.iconId] = { label: x.iconLabel };
    // });

    if (proposedSettings) {
      for (const id in proposedSettings.poiCategories) {
        if (settings.poiCategories[id]) {
          settings.poiCategories[id].active = proposedSettings.poiCategories[id].active;
        }
      }

      for (const id in proposedSettings.pois) {
        if (settings.pois[id]) {
          settings.pois[id].active = proposedSettings.pois[id].active;
        }
      }
    }

    return settings;
  }, [itin?.pois, itin?.tracks, itin?.routes, itin?.locations]);

  // Resets state object on itinerary change.
  useEffect(() => setGlobalMapSettings(null), [setGlobalMapSettings, itin?.localData?.id]);

  useEffect(() => {
    // Sets initial map settings
    if (!itin) return;
    if (!itin.pois || !itin.tracks || !itin.routes) return;
    setGlobalMapSettings(globalMapSettings => {
      if (globalMapSettings) return globalMapSettings;
      return generateSettingsObject(itin?.localData?.globalMapSettings);
    });
  }, [generateSettingsObject, itin, setGlobalMapSettings]);


  useEffect(() => {
    // Persists updates made to the map settings
    if (!globalMapSettings) return;
    if (previewMode) return;
    if (!itin?.localData?.id) return;
    let isActive = true;
    const updateDb = async () => {
      const settings = generateSettingsObject(globalMapSettings);
      await db.itineraries.update(itin.localData.id, { globalMapSettings: settings });
      if (isActive) {
        setItin(itin => ({ ...itin, localData: { ...itin.localData, globalMapSettings: settings }}));
      }
    };

    updateDb();

    return () => {
      isActive = false;
    };
  }, [generateSettingsObject, globalMapSettings, itin?.localData?.id, previewMode, setItin]);

  return children;
};

export default MapNavigationSettings;

