import React, { useRef, useEffect, useState, useContext, useCallback, useLayoutEffect, useMemo } from 'react';
import ReactDOMServer from 'react-dom/server';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import Context from '../../context';
import Container from '../global/Container';
import './index.scss';
import Popup from './Popup';
import MapNav from './MapNav';
import PoiCarousel from './PoiCarousel';
import useKeypress from '../../hooks/useKeypress';
import { toggleAllPoiCategoryAndPoiType } from "../../helpers";

import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
mapboxgl.accessToken = 'pk.eyJ1IjoiaGFsc3RlYWQiLCJhIjoiY2tsdGE0dzB4MXVnazJwcW1kNmJ2bzFyNSJ9.bnNuaLXB89x8RQtXQ--oRw';

const Map = (props) => {
  const {
    itin: itinerary,
    icons,
    viewMode,
    previewMode,
    globalMapSettings,
    setGlobalMapSettings,
    setScreenNameOverride,
    setHeaderColorOverrideClass,
    customHistory,
    removeItemsFromCustomHistory,
    userCameFromPoiList,
    setUserCameFromPoiList,
    setBackPathOverride,
  } = useContext(Context);

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { previewLinkId, ...rest } = useParams();
  const poiId = parseInt(rest.poiId);
  const slideNum = parseInt(rest.slideNum);
  const showInMapsLocations = useMemo(() => {
    return itinerary?.locations.filter(loc => loc.showInMaps) || [];
  }, [itinerary?.locations]);

  let focusLocation;
  if (poiId) {
    const nestedPois = [...itinerary.pois, ...itinerary.tracks, ...itinerary.routes].reduce(
      (prev, curr) => {
        if (curr.pois?.length > 0) prev = [...curr.pois];
        return prev;
      }, []);
    focusLocation = [...itinerary.pois, ...itinerary.tracks, ...itinerary.routes, ...nestedPois, ...Object.values(globalMapSettings?.connectedLocations)].find(x => x.id === poiId);
  } else if (props.locationId) {
    focusLocation = showInMapsLocations.find(x => x.id === props.locationId);
  }

  const lng = focusLocation?.longitude ?? showInMapsLocations?.[0]?.longitude ?? -70.9;
  const lat = focusLocation?.latitude ?? showInMapsLocations?.[0]?.latitude ?? 42.35;
  const zoom = 13;
  const mapContainer = useRef(null);
  const poiMarkers = useRef({});

  const [map, setMap] = useState(null);
  const [locationMarkers, setLocationMarkers] = useState(null);
  const [satelliteViewActive, setSatelliteViewActive] = useState(false);
  const [showLabelsOverride, setShowLabelsOverride] = useState(true);
  const [emptyContainerHeight, setEmptyContainerHeight] = useState(null);
  const [closePath, setClosePath] = useState(null);
  const [isShowAllPressed, setIsShowAllPressed] = useState(false);

  // Grouping by arrays of poi with the same coordinates
  const groupingPoisWithSameCoordinates = (globalMapSettings) => {
    const groupedPois = [];

    const poisWithSameCoordinates = Object.values(globalMapSettings.pois).filter((curr, index, array) => {
      return array.some(poi => poi.id !== curr.id && poi.latitude === curr.latitude && poi.longitude === curr.longitude);
    });

    const sortingPoisWithSameCoordinates = (poi) => {
      let out = null;
      groupedPois.forEach((x, i) => {
        if (x[0].latitude === poi.latitude && x[0].longitude === poi.longitude) {
          out = i;
        }
      });
      return out;
    };

    poisWithSameCoordinates.forEach(x => {
      let itemPosition = sortingPoisWithSameCoordinates(x);
      if (itemPosition !== null) {
        groupedPois[itemPosition].push(x);
      } else {
        groupedPois.push([x]);
      }
    });

    return groupedPois;
  };

  const groupedPoisWithSameCoordinates = useMemo(() => groupingPoisWithSameCoordinates(globalMapSettings), [globalMapSettings]);

  useEffect(() => {
    setScreenNameOverride(poiId ? '' : itinerary?.menu?.find(x => x.segue === 'Maps')?.label);
    return () => {
      setScreenNameOverride(null);
      setBackPathOverride(null);
    };
  }, [setBackPathOverride, itinerary?.menu, setScreenNameOverride, poiId]);

  useEffect(() => {
    if (viewMode === 'mobile') {
      setHeaderColorOverrideClass('color-black');
    }

    return () => setHeaderColorOverrideClass(null);
  }, [setHeaderColorOverrideClass, viewMode]);

  // If there is a non-poi/track location we want to center the map on, we use the override values for showing labels.
  // We always initially want to show labels. The user can toggle them, but we don't want to update the global value for it.
  const getShowLabelsVal = useCallback(() => {
    return props.locationId ? showLabelsOverride : globalMapSettings.showLocationMarkerLabels;
  }, [globalMapSettings.showLocationMarkerLabels, props.locationId, showLabelsOverride]);

  const updateShowLabelsVal = useCallback(() => {
    if (props.locationId) {
      setShowLabelsOverride(showLabelsOverride => !showLabelsOverride);
      return;
    }

    setGlobalMapSettings(globalMapSettings => ({
      ...globalMapSettings,
      showLocationMarkerLabels: !globalMapSettings?.showLocationMarkerLabels
    }));
  }, [props.locationId, setGlobalMapSettings]);

  useEffect(() => {
    if (map && mapContainer.current.classList.length > 1) return; // initialize map only once /classlist to check if there is a map in the DOM

    const options = {
      container: mapContainer.current,
    };

    if (showInMapsLocations?.length < 2 || focusLocation) {
      options.center = [lng, lat];
      options.zoom = zoom;
    } else {
      const bounds = {
        west: lng,
        south: lat,
        east: lng,
        north: lat
      };

      for (const { latitude, longitude } of showInMapsLocations) {
        if (bounds.north < latitude) bounds.north = latitude;
        if (bounds.south > latitude) bounds.south = latitude;
        if (bounds.west > longitude) bounds.west = longitude;
        if (bounds.east < longitude) bounds.east = longitude;
      }

      options.bounds = Object.values(bounds);
      options.fitBoundsOptions = {
        padding: { bottom: 200, left: 100, right: 100, top: viewMode === 'mobile' ? 200 : 100 }
      }
    }
    setMap(new mapboxgl.Map(options));
  }, [showInMapsLocations, lat, lng, map, focusLocation, viewMode, zoom, mapContainer.current?.classList.length]);

  //add zoom and full screen buttons to the map
  useEffect(() => {
    if (!map || viewMode === 'mobile') return;
    map.addControl(new mapboxgl.FullscreenControl({ container: document.querySelector('.map') }));
    map.addControl(new mapboxgl.NavigationControl());
  }, [map, props.type, viewMode])

  useEffect(() => {
    if (/\/\d+$/.test(pathname) && emptyContainerHeight && mapContainer) {
      const offsetValue = (mapContainer.current.offsetHeight / 2) - (emptyContainerHeight / 2)

      map?.fitBounds([
        [focusLocation.longitude, focusLocation.latitude],
        [focusLocation.longitude, focusLocation.latitude]
      ], {
        maxZoom: 13,
        offset: [0, -(offsetValue / 2)],
        duration: 500,
      })
    }
  }, [pathname, focusLocation, map, emptyContainerHeight, mapContainer])

  const generateMarker = useCallback((type, data, markerLabel = null, markerColor = null) => {
    let el;
    if (type === 'location') {
      // // TODO use svg file for marker icon instead of mapbox api
      // const el = document.createElement('div');
      // el.className = 'marker';
      //
      // const img = document.createElement('img');
      // img.src = '/icons/markerPin.svg';
      // img.alt = '';
      //
      // el.appendChild(img);
      //
      // const label = document.createElement('span');
      // label.appendChild(document.createTextNode('1'));
      //
      // el.appendChild(label);

      const markerIcon = `https://api.mapbox.com/v4/marker/pin-l${markerLabel ? '-' + (markerLabel).toString() : ''}+${markerColor}.png?access_token=${mapboxgl.accessToken}`;
      el = document.createElement('img');
      el.src = markerIcon;
      el.alt = '';
    } else {
      el = document.createElement('DIV');
      el.style.backgroundImage = 'url(' + icons[data.iconId] + ')';
    }
    el.className = 'marker marker-' + type;

    const marker = new mapboxgl.Marker(el);

    //if there are pois with the same coordinates then to each next marker with same coordinates will be assigned coordinate with an offset by 0.00005
    if (groupedPoisWithSameCoordinates.flat().find(x => x.id === data.id)) {
      groupedPoisWithSameCoordinates.forEach(x => {
        for (let i = 0; i < x.length; i++) {
          if (x[i].id === data.id) {
            marker.setLngLat([data.longitude + i * 0.00005, data.latitude + i * 0.00005]);
          }
        }
      })
    } else {
      marker.setLngLat([data.longitude, data.latitude]);
    }

    const popup = new mapboxgl.Popup({
      offset: type === 'location' ? 45 : 25,
      closeButton: false,
      className: 'popup',
      focusAfterOpen: false,
    });

    let onInfoBtnClick;
    if (type === 'location') {
      const generatePopupInfoPath = (data) => {
        if (!data.nesting) return data.pdfNode?.file?.httpsUrl || data.pdfNode?.remoteUrl; // for non Stays location

        if (previewMode) {
          let path = window.location.origin + '/preview/';
          if (data.nesting.previewLinkId) path += data.nesting.previewLinkId;
          else path += btoa(`${data.nesting.operatorCode}-${data.nesting.referenceCode}`);
          path += '?branding=' + itinerary.operatorInfo.code;
          return path; // this path will redirect to the link like this: https://{itinerary.operatorInfo.code}.stays.vamoos.com/{data.nesting.operatorCode}/
        }
      };
      const popupInfoUrl = generatePopupInfoPath(data);
      if (popupInfoUrl) onInfoBtnClick = () => {
        window.open(popupInfoUrl, '_blank')}
    }
    if (!onInfoBtnClick) {
      let path = (!previewLinkId && props.type === 'daily')
        ? `/${itinerary.localData?.urlKey}/daily/${slideNum}/maps`
        : `/${itinerary.localData?.urlKey}/maps`
      if (previewLinkId) {
        const pathStart = `/${pathname.match(new RegExp(`^/(preview.*)/${previewLinkId}`))[1]}`;
        if (pathStart === '/preview-maps') {
          path = `${pathStart}/${previewLinkId}`;
        } else {
          path = `${pathStart}/${previewLinkId}/${slideNum}/maps`;
        }
      }
      if (type === 'location' && !data.nesting) { // for location without attachments
        onInfoBtnClick = null;
      } else {
        onInfoBtnClick = () => navigate(`${path}/${data.id}`);
      }
    }
    popup.setHTML(ReactDOMServer.renderToString(<Popup name={type === 'location' ? data.name : data.infoLabel} hideInfo={!onInfoBtnClick}/>));

    popup.on('open', (el) => {
      const element = el.target.getElement();
      element.querySelector('.leftButton')?.addEventListener('click', () => {
        window.open(`https://www.google.com/maps/dir/?api=1&destination=${data.latitude},%20${data.longitude}&dir_action=navigate`, '_blank');
      });
      element.querySelector('.rightButton')?.addEventListener('click', (e) => {
        e.stopPropagation();
        e.cancelBubble = true;
        onInfoBtnClick();
      });
    });
    marker.setPopup(popup);
    return marker;
  }, [slideNum, props.type, icons, itinerary.localData?.urlKey, itinerary.operatorInfo.code, previewMode, navigate, pathname, previewLinkId, groupedPoisWithSameCoordinates]);

  useEffect(() => {
    if (!map) return;
    const global = document.documentElement;
    const accentColor = getComputedStyle(global).getPropertyValue('--accent-color').trim().slice(1);
    const markers = {};
    const showNumbersOnMarkers = showInMapsLocations.length > 1;
    for (let i = 0, n = showInMapsLocations.length; i < n; i++) {
      const l = showInMapsLocations[i];
      const marker = generateMarker('location', l, showNumbersOnMarkers ? (i + 1) : '', accentColor);
      marker.addTo(map);
      markers[l.id] = marker;
    }
    setLocationMarkers(markers);
  }, [generateMarker, showInMapsLocations, map]);

  useEffect(() => {
    if (!map || itinerary?.mapOverlays?.length === 0) return;

    map.on('styledata', function () {
      itinerary?.mapOverlays.forEach(overlay => {
        const isLayerExist = map.getLayer(`image-${overlay.image.id}`)
        if (isLayerExist) return;

        const { topLeft, topRight, bottomRight, bottomLeft } = overlay.coordinates;
        const coords = [
          [topLeft.longitude, topLeft.latitude],
          [topRight.longitude, topRight.latitude],
          [bottomRight.longitude, bottomRight.latitude],
          [bottomLeft.longitude, bottomLeft.latitude],
        ];

        map.addSource(`overlay-image-${overlay.image.id}`, {
          type: "image",
          url: overlay.image.file.httpsUrl,
          coordinates: coords,
        });

        map.addLayer({
          id: `image-${overlay.image.id}`,
          source: `overlay-image-${overlay.image.id}`,
          type: "raster",
          paint: { "raster-opacity": 1 }
        });
      })
    });
  }, [map, itinerary]);

  useEffect(() => {
    if (!map) return;
    const newMarkers = {};
    let oldMarkers = { ...poiMarkers.current };

    let trackBounds = {
      west: null,
      south: null,
      east: null,
      north: null
    };

    let allIsActive = false;

    const addPoi = (poi, parent = null) => {
      if (poiId && poiId === poi.id) {
        // Accept no matter what -- it's our active POI ID!
      } else if (allIsActive) {
        // Accept all
      } else {
        if ((poi.type === 'track' || poi.type === 'route') && !poi.active) return;
        if (!poi.active && !globalMapSettings.poiCategories?.[parent ? parent.iconId : poi.iconId]?.active) return;
      }
      if (newMarkers[poi.id]) return;

      poi.pois?.forEach(x => addPoi(x, poi));

      if (oldMarkers[poi.id] && !poiId) {
        newMarkers[poi.id] = oldMarkers[poi.id];
        delete oldMarkers[poi.id];
        return;
      }

      let marker = !poiId
        ? generateMarker('poi', poi)
        : (poi.id === poiId) ? generateMarker('poi', poi) : null

      if (marker === null) return
      marker.addTo(map);
      newMarkers[poi.id] = marker;

      if (poi.waypoints && map.loaded() && !map?.getLayer('poi' + poi.id)) {
        addPoiType(poi);
        focusOnPoiType(poi);
      }
    }

    const addPoiType = (poi) => {
      if (!poi.active || map?.getLayer('poi' + poi.id)) return

      map.addSource('poi-type-' + poi.id, {
        type: 'geojson',
        data: {
          type: 'Feature',
          geometry: {
            type: 'LineString',
            coordinates: poi.waypoints.map(wp => [wp.longitude, wp.latitude]),
          }
        },
      });
      map.addLayer({
        id: 'poi-outline-' + poi.id,
        type: 'line',
        source: 'poi-type-' + poi.id,
        paint: {
          'line-color': '#000000',
          'line-width': 10,
        },
      });
      map.addLayer({
        id: 'poi' + poi.id,
        type: 'line',
        source: 'poi-type-' + poi.id,
        paint: {
          'line-color': poi.color ?? '#ff0000',
          'line-width': 6,
        },
      });
    }

    const focusOnPoiType = (poi) => {
      if (isShowAllPressed) return;
      if (trackBounds.west === null) {
        trackBounds = {
          west: poi.waypoints[0].longitude,
          south: poi.waypoints[0].latitude,
          east: poi.waypoints[0].longitude,
          north: poi.waypoints[0].latitude
        };
      }

      //find edge boundaries of poiType
      for (const { latitude, longitude } of poi.waypoints) {
        if (trackBounds.north < latitude) trackBounds.north = latitude;
        if (trackBounds.south > latitude) trackBounds.south = latitude;
        if (trackBounds.west > longitude) trackBounds.west = longitude;
        if (trackBounds.east < longitude) trackBounds.east = longitude;
      }

      const bounds = new mapboxgl.LngLatBounds(
        [trackBounds.west, trackBounds.south],
        [trackBounds.east, trackBounds.north],
      );

      map.fitBounds(bounds, {
        padding: 150
      });
    }

    if (poiId && !Object.values(globalMapSettings.poiCategories).find(x => x.active)) {
      allIsActive = true;
    }

    Object.values(globalMapSettings.pois).forEach(x => addPoi(x));

    for (const poiId in oldMarkers) {
      oldMarkers[poiId].remove();
      if (map.getLayer('poi' + poiId)) {
        map.removeLayer('poi-outline-' + poiId);
        map.removeLayer('poi' + poiId);
        map.removeSource('poi-type-' + poiId);
      }
    }
    poiMarkers.current = newMarkers;

    const activeTracks = Object.values(globalMapSettings.pois).filter(x => (x.type === 'track' || x.type === 'route') && x.active);

    //adding a poiType layer every time the map style is change and page load
    map.on('styledata', () => {
      activeTracks.forEach(x => addPoiType(x));
    });

    //checked if any of poiType are not rendered, then run again addPoiType and focusOnPoiType
    map.on('idle', () => {
      activeTracks.forEach(x => {
        if (!map.getLayer('poi' + x.id) && x.active) {
          addPoiType(x);
          focusOnPoiType(x);
        }
      })
    });

  }, [generateMarker, map, globalMapSettings.poiCategories, globalMapSettings.pois, poiId, pathname, customHistory.length, isShowAllPressed]);

  useEffect(() => {
    if (!globalMapSettings) return;
    if (!locationMarkers) return;
    if (!map) return;
    const handleLocationMarkerLabels = (poiId) => {
      const activeLocation = poiId ? showInMapsLocations.find(x => x.id === poiId) : null;
      const showLocationMarkerLabels = activeLocation ? props.type !== 'daily' : getShowLabelsVal();
      for (const m of Object.values(locationMarkers)) {
        const p = m.getPopup();
        if (!p) continue;
        const isOpen = p.isOpen();
        if ((showLocationMarkerLabels && isOpen) || (!showLocationMarkerLabels && !isOpen)) continue;
        m.togglePopup();
      }
    };
    // Initial handling of location marker labels must be done this way. Otherwise it will cause the daily section to scroll
    // to the left when the labels are initially opened.
    map.on('load', () => handleLocationMarkerLabels(poiId));
    // if (!map.loaded()) return;
    handleLocationMarkerLabels(poiId);
  }, [props.type, getShowLabelsVal, globalMapSettings, locationMarkers, map, poiId, showInMapsLocations]);

  // Sets satellite view if activated
  useEffect(() => {
    if (!map) return;
    map.setStyle(satelliteViewActive ? 'mapbox://styles/mapbox/satellite-streets-v11' : 'mapbox://styles/mapbox/streets-v11');
  }, [map, satelliteViewActive]);

  const activeItem = () => {
    if (!globalMapSettings.pois[poiId]) {
      //if active POI id is undefined in globalMapSettings.pois, looking for the active POI id in the nested POIs
      const poisWithNestedPois = Object.values(globalMapSettings.pois).filter(x => x.pois?.length)
      let out
      for (let i = 0; i < poisWithNestedPois.length; i++) {
        for (let j = 0; j < poisWithNestedPois[i].pois.length; j++) {
          if (poisWithNestedPois[i].pois[j].id === poiId) out = poisWithNestedPois[i].pois[j]
        }
      }
      if (!out) out = globalMapSettings.connectedLocations[poiId];
      return out
    } else {
      return globalMapSettings.pois[poiId]
    }
  }

  //remove poiType layer if it remains after renderer
  const removeLayer = (typeId) => {
    if (map.getLayer('poi' + typeId)) {
      map.removeLayer('poi-outline-' + typeId);
      map.removeLayer('poi' + typeId);
      map.removeSource('poi-type-' + typeId);
    }
  }

  //exit Poi Carousel, go to the main map and hide the poi carousel
  const exitPoiCarousel = useCallback(() => {
    if (!poiId) return;
    let newPath = pathname.split('/').filter(x => x);
    newPath.pop();
    newPath = '/' + newPath.join('/');

    let backPath;
    let i = 0;
    let target;
    while (true) {
      target = customHistory[customHistory.length + i - 1];
      if (!target || !target.startsWith(newPath)) {
        backPath = newPath;
        break;
      }
      if (target.match(new RegExp('^' + newPath + '[/]?$', 'i'))) {
        backPath = i;
        break;
      }
      i--;
    }
    navigate(newPath);
    removeItemsFromCustomHistory(backPath);
  }, [poiId, pathname, navigate, removeItemsFromCustomHistory, customHistory]);

  useKeypress('Escape', [navigate, itinerary], () => exitPoiCarousel());

  useLayoutEffect(() => {
    if (viewMode === 'desktop') return;
    let backPath = null;
    const isDaily = props.type === 'daily';

      let newPath = pathname.split('/').filter(x => x !== '');
      const newPoiPath = '/' + newPath[0] + '/pois';
      newPath.pop();
      newPath = '/' + newPath.join('/');

      let i = 0;
      let target;
      while (true) {
        target = customHistory[customHistory.length + i - 1];
        if (!target) {
          backPath = `/${itinerary.localData?.urlKey}`;
          break;
        }
        if (!isDaily) {
          // if user navigate from POI list, set back path to POI list
          if (target.match(new RegExp(`/\\d+/pois[/]?$`, 'i'))) {
            backPath = (customHistory.lastIndexOf(newPoiPath) - (customHistory.length - 1));
            break;
          }
          // if user navigate from daily maps, set back path to daily section
          if (target.match(new RegExp(`/daily/\\d+/maps[/]?$`, 'i'))) {
            backPath = i - 1;
            break;
          }
          if (target.match(new RegExp('^' + newPath + '[/]?$', 'i'))) {
            backPath = i;
            break;
          }
        }

        if (isDaily && target.match(new RegExp('^' + newPath + '[/]?$', 'i'))) {
          break;
        }
        // If user navigates to maps from Accommodation menu item, set back path to daily section
        if (isDaily && target.match(new RegExp(`/daily/\\d+/?$`, 'i'))) {
          setBackPathOverride(i);
          break;
        }
        i--;
      }

    // To fix issue with navigation.
    // When user will toggle location labels then navigation to -number did not work properly
    if (typeof backPath === 'number') backPath = customHistory[customHistory?.length + (backPath - 1)];

    setClosePath(backPath);
  }, [setBackPathOverride, pathname, customHistory, props.type, itinerary.localData?.urlKey, viewMode]);

  //set All Poi Categories Not Active for mobile view
  // if user navigate from POI list to maps and all categories was not active and user navigate back to POI list without changing active poi categories
  const setAllPoiCategoriesNotActive = () => {
    if (!userCameFromPoiList) return;
    setUserCameFromPoiList(false);
    const { newPois, categories } = toggleAllPoiCategoryAndPoiType(globalMapSettings, false);
    setGlobalMapSettings({
      ...globalMapSettings,
      pois: newPois,
      poiCategories: categories
    });
  }

  const renderContent = () => {
    //sorting POIs by type
    const activeItems = Object.values(globalMapSettings.pois).reduce(
      (prev, curr) => {
        if (curr.type === 'track' || curr.type === 'route') {
          if (curr.active && curr.pois.length !== 0) {
            for (let i = 0; i < curr.pois.length; i++) {
              if (!prev['poi'].some(x => x.id === curr.pois[i].id)) prev['poi'].push(curr.pois[i]); //this is a check to avoid re-adding the poi in case the poi is available separately and added to tracks or routes
            }
          }
          if (curr.active) {
            prev[curr.type].push(curr);
          }
        } else if (curr.type === 'poi' && globalMapSettings.poiCategories[curr.iconId].active) {
          if (!prev['poi'].some(x => x.id === curr.id)) prev['poi'].push(curr); //this is a check to avoid re-adding the poi in case the poi is available separately and added to tracks or routes
        }
        return prev;
      },
      { poi: [], track: [], route: [] }
    );
    activeItems.locations = [...Object.values(globalMapSettings?.connectedLocations)];

    //Find the type of active POI. Used a separate method instead of a simple find, because we have nested POIs
    const foundSelectedItem = (obj, id) => {
      for (let type in obj) {
        const foundPoi = obj[type].find(item => item.id === id);
        if (foundPoi) {
          return { type, poi: foundPoi };
        }
      }
      return null;
    }

    return (
      <>
        <div
          ref={mapContainer}
          className="map-container"
          onClick={() => {
            exitPoiCarousel();
            getShowLabelsVal() && updateShowLabelsVal();
          }}
        />
        {poiId ?
          <PoiCarousel
            data={
              activeItems[foundSelectedItem(activeItems, poiId)?.type] ?? [activeItem()]
            }
            activeItem={activeItem()}
            setEmptyContainerHeight={setEmptyContainerHeight}
            type={props.type}
            slideNum={slideNum}
            disableSwipe={!!poiId}
          /> :
          <MapNav
            satelliteViewActive={satelliteViewActive}
            setSatelliteViewActive={setSatelliteViewActive}
            getShowLabelsVal={getShowLabelsVal}
            updateShowLabelsVal={updateShowLabelsVal}
            removeLayer={removeLayer}
            setIsShowAllPressed={setIsShowAllPressed}
          />
        }
        {/* { renderPoiCarousel() } */}
      </>
    );
  };

  if (props.type === 'daily') {
    return (
      <div className={`map daily-map`}>
        {renderContent()}
      </div>
    );
  }

  return (
    <Container
      className="container-map"
      mainElementClassName="map"
      closePath={closePath}
      backButtonClickFn={setAllPoiCategoriesNotActive}
    >
      {renderContent()}
    </Container>
  );
};

export default Map;
