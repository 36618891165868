import { useState, useEffect, useContext } from 'react';
import Context from '../../context';

const WeatherTemp = props => {
  const [temp, setTemp] = useState(props.temp);
  const { viewMode } = useContext(Context);

  useEffect(() => {
    let t = props.temp;
    if (props.units === 'F') {
      t = (props.temp * 1.8) + 32;
    }
    setTemp(t);
  }, [props.temp, props.units]);

  return (
    <div className="weather-temp">
      {Math.round(Number(temp))}&deg;{viewMode === 'desktop' ? props.units : ''}
    </div>
  );
};

export default WeatherTemp;
