import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";
import { SnackbarProvider } from 'notistack';
import App from './App';
import './index.scss';
import * as serviceWorker from "./serviceWorker";
import { generateTranslationOptions } from "./helpers";

const translationOptions = generateTranslationOptions();

i18next.init(translationOptions);

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <SnackbarProvider maxSnack={6}>
        <Router>
          <App deviceLanguage={translationOptions.fallbackLng[0]} allDeviceLanguages={translationOptions.languages} i18next={i18next} />
        </Router>
      </SnackbarProvider>
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

serviceWorker.register();
