import './Chevron.scss';

const Chevron = (props) => {
  const direction = props.direction === 'left' ? 'left' : 'right';

  return (
    <img
      className={`chevron chevron-${props.direction} chevron-${props.size}`}
      src={props?.color === 'dark'
          ? `/icons/arrow_${direction}_small_dark.svg`
          :`/icons/arrow_${direction}_small.svg`}
      alt=""
    />
  );
};

export default Chevron;