import { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  differenceInDays,
  addMinutes,
} from 'date-fns';
import Context from '../../context';
import './FlightListItem.scss';
import FlightIcon from '@mui/icons-material/Flight';

const FlightListItem = ({ data, isSelected, formatDate, formatTime }) => {
  const { viewMode, t, itin } = useContext(Context);
  const location = useLocation();

  const { departure, arrival, ...flight } = data.flights[0];

  const cancelled = data.cancelled;

  const dateNow = Math.floor(Date.now() / 1000);

  const departureTimeEstimation = departure.estimated !== departure.scheduled && departure.estimated > dateNow;
  const arrivalTimeEstimation = arrival.estimated !== arrival.scheduled && arrival.estimated > dateNow;

  const rawAirportScheduled = addMinutes(Number(`${departure.estimated}000`), (departure.airportTimeOffset * 60));
  const rawAirportArrival = addMinutes(Number(`${arrival.estimated}000`), (arrival.airportTimeOffset * 60));

  const scheduledDate = addMinutes(rawAirportScheduled, rawAirportScheduled.getTimezoneOffset());
  const arrivalDate = addMinutes(rawAirportArrival, rawAirportArrival.getTimezoneOffset());

  const timeDifferenceDeparture = differenceInDays(Number(`${departure.estimated}000`), new Date());
  const isActive = timeDifferenceDeparture > -1 && timeDifferenceDeparture < 1 && arrival.estimated > dateNow;

  const departureTimeTypeClassNames = `${!departureTimeEstimation && (departure.scheduled < dateNow ) ? 'time-active' : ''} ${departureTimeEstimation ? 'time-estimated' : ''} ${cancelled ? 'time-cancelled' : ''}`.trim();
  const arrivalTimeTypeClassNames = `${!departureTimeEstimation && (arrival.scheduled < dateNow ) ? 'time-active' : ''} ${arrivalTimeEstimation ? 'time-estimated' : ''} ${cancelled ? 'time-cancelled' : ''}`.trim();

  const generateTimeStr = (classNames, date) => {
    const prefixMap = [
      { className: "time-active", prefix: t('flights_actual_text', { lng: itin.language }) },
      { className: "time-estimated", prefix: t('flights_estimated_text', { lng: itin.language }) },
      { className: "time-cancelled", prefix: t('cancelled', { lng: itin.language }) },
    ];
    const prefix = prefixMap.find(x => classNames.endsWith(x.className))?.prefix || '';
    return `${prefix} ${!cancelled ? formatTime(date) : ''}`;
  };

  return (
    <li className={`flight-list-item flight-list-item-${viewMode} ${isActive && !cancelled ? 'active' : ''} ${isSelected ? 'selected' : ''}`} >
      <Link to={location.pathname.replace(new RegExp(`/flights.*`, 'i'), `/flights/${data.id}`)}>
        <div className="left">
          <div className="city-name">{departure.cityName}</div>
          <div className="date">{formatDate(scheduledDate)}</div>
          <div className={`time ${departureTimeTypeClassNames}`}>{generateTimeStr(departureTimeTypeClassNames, scheduledDate)}</div>
        </div>
        <div className="center">
          <div className="icon"><FlightIcon className="icon-styling" /></div>
          <div className="flight-number">{`${flight.airlineCode}${flight.flightNumber}`}</div>
        </div>
        <div className="right">
          <div className="city-name">{arrival.cityName}</div>
          <div className="date">{formatDate(arrivalDate)}</div>
          <div className={`time ${arrivalTimeTypeClassNames}`}>{generateTimeStr(arrivalTimeTypeClassNames, arrivalDate)}</div>
        </div>
      </Link>
    </li>
  );
};

export default FlightListItem;
