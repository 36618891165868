import { useContext, useEffect } from 'react';
import Context from '../../context';
import { getStaticComposerData } from "../../libraries/api";
import LoadingScreen from '../global/LoadingScreen';

const Branding = ({ children, i18next }) => {
  const { composerData, setComposerData, deviceLanguage, allDeviceLanguages } = useContext(Context);

  useEffect(() => {
    let isActive = true;
    getStaticComposerData(window.location.hostname).then(async composerData => {
      if (!composerData?.res?.ok || composerData?.error || composerData?.res?.headers?.get('content-type').includes('text/html')) {
        composerData = await getStaticComposerData('default');
      }
      const { data } = composerData;
      localStorage.setItem('bundleId', data.bundleId);

      // change WV language to custom app default language if no other supported language is preferred
      if (data.name !== "Vamoos") {

        if (!allDeviceLanguages.includes(data.defaultLanguage) && Object.keys(i18next.options.resources).includes(data.defaultLanguage)) {
          await i18next.changeLanguage(data.defaultLanguage);
        }
      }

      for (const key in data.menu.documents) {
        const entry = data.menu.documents[key];
        data.menu.documents[key] = {
          ...entry,
          ...(entry.languages?.[deviceLanguage] ?? entry.languages?.[i18next.options.fallbackLng] ?? entry.languages?.[data.defaultLanguage]),
        };
      }

      for (const key in data.strings) {
        const entry = data.strings[key];
        data.strings[key] = {
          ...entry,
          ...(entry.languages?.[deviceLanguage] ?? entry.languages?.[i18next.options.fallbackLng] ?? entry.languages?.[data.defaultLanguage] ?? {text: ""}),
        };
      }

      const authObj = {};
      data.auth?.forEach((authItem) => {
        const name = authItem.name;
        const label = authItem.label?.languages?.[deviceLanguage] || authItem.label?.languages?.[i18next.options.fallbackLng];
        let imageUrl;
        if (authItem.imageUrl) {
          imageUrl = authItem.imageUrl.languages?.[deviceLanguage] || authItem.imageUrl.languages?.[i18next.options.fallbackLng];
        }
        authObj[name] = {
          ...authItem,
          label: label,
        };
        if (imageUrl) authObj[name].imageUrl = imageUrl;
        if (!['google', 'apple'].includes(authItem.name)) data.customAuthName = authItem.name;
      });
      data.auth = authObj;

      if (isActive) {
        setComposerData(data);
      }
    });

    return () => {
      isActive = false;
    };
  }, [i18next, setComposerData, allDeviceLanguages, deviceLanguage]);

  useEffect(() => {
    const favicon = document.getElementById('favicon');
    if (!favicon) return;
    const icon = composerData?.images?.favicon?.filePath;
    if (icon) favicon.href = icon;
  }, [composerData]);

  useEffect(() => {
    if (!composerData) return;

    const snakeToKebabCase = str => str.toLowerCase().replace(/_/g, '-');
    // const camelToKebabCase = str => str.replace(/((?<=[a-z\d])[A-Z]|(?<=[A-Z\d])[A-Z](?=[a-z]))/g, '-$1').toLowerCase();
    const camelToKebabCase = str => {
      return str.split('').map((letter, idx) => {
        return letter.toUpperCase() === letter
          ? `${idx !== 0 ? '-' : ''}${letter.toLowerCase()}`
          : letter;
      }).join('');
    }

    const global = document.documentElement;
    // const globalStyle = getComputedStyle(global);

    for (const key in composerData.menu.documents) {
      const doc = composerData.menu.documents[key];
      if (!doc.styles) continue;
      for (const name in doc.styles) {
        const cssVarName = '--' + key + '-' + camelToKebabCase(name);
        global.style.setProperty(cssVarName, doc.styles[name]);
      }
    }

    if (!composerData?.colors || !composerData?.strings) return;

    for (const color in composerData.colors) {
      const cssVarName = '--' + snakeToKebabCase(color);

      if (['menu_divider_colour', 'menu_itinerary_background_colour'].includes(color)) {
        global.style.setProperty(cssVarName, composerData.colors[color] + '40');
        continue;
      }

      global.style.setProperty(cssVarName, composerData.colors[color]);
    }

    for (const stringName in composerData.strings) {
      const string = composerData.strings[stringName];
      if (typeof string !== 'object') continue;

      const cssVarName = '--' + snakeToKebabCase(stringName) + '-colour';
      global.style.setProperty(cssVarName, string.colour);
    }

  }, [composerData]);

  useEffect(() => {
    if (!composerData?.strings) return;
    const translationOverrides = {};
    for (const key in composerData.strings) {
      let val = composerData.strings[key]
      if (typeof val === 'object') {
        val = val.text;
      }
      translationOverrides[key] = val;
    }
    i18next.addResourceBundle(deviceLanguage, 'strings', translationOverrides);
  }, [composerData, i18next, deviceLanguage]);


  if (!composerData) return <LoadingScreen />;

  return children;
};

export default Branding;
