import { useContext, useEffect } from 'react';
import Context from '../../context';
import './Features.scss';
import FeaturesModal from './FeaturesModal';

const Features = ({ features, section }) => {
  const { viewMode, icons, setScreenNameOverride, t } = useContext(Context);

  useEffect(() => {
    setScreenNameOverride(t('property_amenities'));
    return () => setScreenNameOverride(null);
  }, [setScreenNameOverride, t]);

  const renderFeaturesHtml = () => {
    if (!features?.length) return null;

    const categorisedFeatures = features.reduce((out, { groupName, iconId, name }) => {
      if (!out[groupName]) out[groupName] = [];
      out[groupName].push({ iconId, name });
      return out;
    }, {});

    return (
      <div className="features">
        {
          Object.keys(categorisedFeatures).map((groupName, i) => (
            <div key={i} className="feature-group">
              <div className="feature-group-name">{groupName}</div>
              <div className="feature-group-items">
                {
                  categorisedFeatures[groupName].map(({ name, iconId }, i) => (
                    <div key={i} className="feature-item">
                      <img src={icons[iconId]} alt={`${groupName}: ${name}`} />
                      { name }
                    </div>
                  ))
                }
              </div>

            </div>
          ))
        }
      </div>
    );
  };

  const featuresHtml = renderFeaturesHtml();

  if (viewMode === 'mobile') return featuresHtml;

  return (
    <FeaturesModal section={section}>{featuresHtml}</FeaturesModal>
  );
};

export default Features;
