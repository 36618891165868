import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useContext, useEffect, useState } from "react";
import './Dropdown.scss';
import Context from "../../context";

const Dropdown = ({ postsPerSlide, postDay, postPosition, totalPages, moveTo, setMoveTo }) => {
  const [previousDay, setPreviousDay] = useState(true);
  const [nextDay, setNextDay] = useState(true);
  const [moveUp, setMoveUp] = useState([]);
  const [moveDown, setMoveDown] = useState([]);

  const { t, itin } = useContext(Context);

  useEffect(() => {
    if (postDay < 2) setPreviousDay(false);
    if (postDay === totalPages) setNextDay(false);

    const moveUpValues = [];
    for (let i = 1; i <= postPosition - 1; i++) {
      moveUpValues.push(i)
    }
    setMoveUp(moveUpValues.reverse());

    const moveDownValues = [];
    for (let i = 1; i <= postsPerSlide - postPosition; i++) {
      moveDownValues.push(-i)
    }
    setMoveDown(moveDownValues);

    return () => {
      setMoveTo(0)
    };

  }, [postDay, postPosition, postsPerSlide, setMoveTo, totalPages]);

  const handleChange = (event) => {
    setMoveTo(event.target.value);
  };

  return (
    <div>
      <FormControl>
        <Select
          id="move-note"
          value={moveTo}
          onChange={handleChange}
          displayEmpty
        >
          {previousDay ? <MenuItem value={'PrevDay'}>{t('move_post_previous_day', { lng: itin.language })}</MenuItem> : null}
          {moveUp.length
            ? moveUp.map(moveUpValue => {
              return (<MenuItem value={moveUpValue} key={moveUpValue}>{t('move_post_up', { lng: itin.language }).replace('%d', moveUpValue)}</MenuItem>)
            })
            : null
          }
          <MenuItem value={0}>{t('move_post_do_not_move', { lng: itin.language })}</MenuItem>
          {moveDown.length
            ? moveDown.map(moveDownValue => {
              return (<MenuItem value={moveDownValue} key={moveDownValue}>{t('move_post_down', { lng: itin.language }).replace('%d', -moveDownValue)}</MenuItem>)

            })
            : null
          }
          {nextDay ? <MenuItem value={'NextDay'}>{t('move_post_next_day', { lng: itin.language })}</MenuItem> : null}
        </Select>
      </FormControl>
    </div>
  );
}


export default Dropdown;
