import Dexie from 'dexie';

export const db = new Dexie('webvamoos');
db.version(3).stores({
  itineraries: '++id, lastLogin, [vamoosId+parentVamoosId], vamoosId, parentVamoosId',
});

db.version(4).stores({
  itineraries: '++id, lastLogin, [vamoosId+parentVamoosId], vamoosId, parentVamoosId',
}).upgrade(transaction => {
  return transaction.itineraries
    .toCollection()
    .modify(itinerary => {
      itinerary.parentVamoosId = itinerary.parentVamoosId === 'null' ? null : itinerary.parentVamoosId;
    });
});

db.version(5).stores({
  itineraries: '++id, lastLogin, [vamoosId+parentVamoosId], vamoosId, parentVamoosId',
  deviceInfo: '++id'
});

db.version(6).stores({
  itineraries: '++id, lastLogin, [vamoosId+parentVamoosId], vamoosId, parentVamoosId',
});

db.version(7).stores({
  itineraries: '++id, lastLogin, [vamoosId+parentVamoosId], vamoosId, parentVamoosId',
}).upgrade(transaction => {
  return transaction.itineraries
    .toCollection()
    .modify(itinerary => {
      if (!itinerary.departureDate && itinerary.date) {
        itinerary.departureDate = itinerary.date;
        itinerary.date = null;
      }
    });
});

db.version(8).stores({
  itineraries: '++id, lastLogin, [vamoosId+parentVamoosId], vamoosId, parentVamoosId',
  session: 'key, gId'
});

db.version(9).stores({
  itineraries: '++id, lastLogin, [vamoosId+parentVamoosId], vamoosId, parentVamoosId',
  session: null
});

db.version(10).stores({
  itineraries: '++id, lastLogin, [vamoosId+parentVamoosId], vamoosId, parentVamoosId',
  session: 'key, gId'
});
