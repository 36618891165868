import './Flights.scss';
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Context from "../../context";
import FlightsScreen from "../Flights";

const SEGUE = 'Flights';

const Flights = () => {
  const [data, setData] = useState(null);
  const [menuLabel, setMenuLabel] = useState(null);
  const { itin } = useContext(Context);
  const { slideNum } = useParams();

  useEffect(() => {
    const dayData = itin.brief[parseInt(slideNum)-1];
    setData(dayData?.flightIds);
    setMenuLabel(dayData?.menu.find(x => x.label === SEGUE)?.label);
  }, [slideNum, itin.brief]);

  if (!data) return <div>Loading</div>;

  return <FlightsScreen type="daily" flightIds={data} menuLabel={menuLabel} />
};

export default Flights;
