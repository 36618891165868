import { forwardRef, useContext } from 'react';
import ButtonBase from "@mui/material/ButtonBase";
import Chevron from '../Icons/Chevron';
import './ScrollButtons.scss';
import Context from "../../../context";

export const ScrollButton = forwardRef((props, ref) => {
  const { direction, size, className, hideScrollButtons, color, ...other } = props;
  const { viewMode } = useContext(Context);

  const isDisabled = other.disabled || hideScrollButtons;
  const buttonStyles = {
    opacity: isDisabled ? 0 : 1,
    pointerEvents: isDisabled ? 'none' : 'auto',
  };

  return (
    <ButtonBase
      component="div"
      className={`scroll-btn ${className}`}
      ref={ref}
      style={buttonStyles}
      sx={{ margin: size === 'small' ? '10px' : '20px' }}
      disableRipple={viewMode === 'mobile'}
      disableTouchRipple={viewMode === 'mobile'}
      focusRipple={!(viewMode === 'mobile')}
      {...other}
    >
      <Chevron direction={direction} size={size} color={color}/>
    </ButtonBase>
  );
});

export const ScrollButtonSmall = forwardRef((props, ref) => <ScrollButton size="small" {...props} ref={ref}/>);
// Compat
export const ScrollButtons = ScrollButton;
export const ScrollButtonsSmall = ScrollButtonSmall;
