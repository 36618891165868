import { useState, useEffect, useRef, useContext } from 'react';
import './index.scss';
import { ScrollButton } from "../Buttons/ScrollButtons";
import { useParams } from "react-router-dom";
import Context from "../../../context";

const Carousel = ({
                    slides,
                    prevBtnDisabled,
                    nextBtnDisabled,
                    handleChange,
                    style,
                    mobileScrollButtonsTop,
                    currentSlide,
                    type,
                    hideScrollButtons,
                    disableSwipe
                  }) => {
  const [touchValuesX, setTouchValuesX] = useState({ start: 0, end: 0 });
  const [allFocusableElements, setAllFocusableElements] = useState(null);
  const carouselContainer = useRef();
  const carousel = useRef();
  const { slideNum } = useParams();
  const { viewMode } = useContext(Context);

  // The next two useEffects avoid unwanted navigation and breakdowns of the carousel when pressing Tab key
  useEffect(() => {
    setAllFocusableElements(carousel.current?.querySelectorAll('button, [href], input, select, textarea, [tabindex], details, summary, .short-info'))
  }, [slides])

  useEffect(() => {
    if (type !== 'storyboard' || !allFocusableElements?.length || viewMode === 'mobile') return;

    const allFocusableElementsToArray = Array.apply(null, allFocusableElements);
    const allFocusableElementsBySlides = allFocusableElementsToArray?.reduce((prev, curr) => {
      if (curr.innerHTML.includes('chevron-left')) {
        prev.push([curr])
      } else {
        prev[prev.length - 1]?.push(curr)
      }
      return prev
    }, []);

    for (let i = 0; i < allFocusableElementsBySlides?.length; i++) {
      allFocusableElementsBySlides[i].forEach(el => el.setAttribute("tabindex", `${(parseInt(slideNum) === i + 1) ? 0 : -1}`));
    }
  }, [allFocusableElements, type, slideNum, viewMode]);

  useEffect(() => {
    if (disableSwipe) return;
    const container = document.querySelector('.carousel-container');
    if (container) {
      const handleTouch = (e) => {
        if (typeof e?.target?.className === 'string' && e.target.className.includes('MuiButtonBase-root')) return;

        e.type === 'touchstart'
          ? setTouchValuesX(vals => ({ ...vals, start: e.changedTouches[0].screenX }))
          : setTouchValuesX(vals => ({ ...vals, end: e.changedTouches[0].screenX }));
      }

      container.addEventListener('touchstart', handleTouch, { passive: true });
      container.addEventListener('touchend', handleTouch, { passive: true });

      return () => {
        container.removeEventListener("touchstart", handleTouch);
        container.removeEventListener("touchend", handleTouch);
      };
    }
  }, [disableSwipe]);

  // Set carousel offset based on currentSlide positioning
  useEffect(() => {
    if (!currentSlide) return;
    const page = slides.indexOf(currentSlide);
    if (page > -1) carouselContainer.current.scrollLeft = carouselContainer.current.clientWidth * page;
  }, [ currentSlide, slides ]);

  useEffect(() => {
    if (touchValuesX.end === 0) return;
    const threshold = 50;
    if (!nextBtnDisabled && (touchValuesX.start - touchValuesX.end >= threshold)) handleChange('next');
    else if (!prevBtnDisabled && (touchValuesX.end - touchValuesX.start >= threshold)) handleChange('prev');

    if (touchValuesX.start > 0 && touchValuesX.end > 0) {
      setTouchValuesX({ start: 0, end: 0 });
    }
  }, [handleChange, nextBtnDisabled, prevBtnDisabled, touchValuesX.end, touchValuesX.start]);

  return (
    <div className={`carousel-container ${mobileScrollButtonsTop ? 'mobile-scroll-buttons-top' : ''}`}
         ref={carouselContainer}>
      <ScrollButton
        size="large"
        onClick={() => handleChange('prev')}
        className="left"
        direction="left"
        disabled={prevBtnDisabled}
        hideScrollButtons={hideScrollButtons}
      />
      <div
        className="carousel"
        style={style}
        ref={carousel}
      >
        {slides}
      </div>
      <ScrollButton
        size="large"
        onClick={() => handleChange('next')}
        className="right"
        direction="right"
        disabled={nextBtnDisabled}
        hideScrollButtons={hideScrollButtons}
      />
    </div>
  );
};

export default Carousel;
