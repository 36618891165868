import React, { useContext } from 'react';
import Context from '../../../context';
import ModalDefault from "./ModalDefault";
import './ModalGlobal.scss'

const ModalGlobal = () => {
  const { globalModalContent, setGlobalModalContent } = useContext(Context);

  const onClose = () => globalModalContent.closable ? setGlobalModalContent(null) : null;
  return (
    <ModalDefault
      isOpen={!!globalModalContent}
      onBackdropClick={onClose}
      className="modal-global"
      header={globalModalContent?.header}
      message={globalModalContent?.message}
      buttons={globalModalContent?.buttons?.map((x, i) => (<button key={i} onClick={x.type === 'close' ? onClose : x.onClick}>{x.text}</button>))}
    >
      {globalModalContent?.children}
    </ModalDefault>
  );
};

export default ModalGlobal;