import { useContext, useEffect, useState, useLayoutEffect, useCallback } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import Context from '../../context';
import Container from "../global/Container";
import InspirationSlide from './InspirationSlide';
import Carousel from '../global/Carousel';
import LoadingScreen from '../global/LoadingScreen';
import InspirationBar from "./InspirationBar";
import './index.scss';
import ModalDefault from "../global/Modal/ModalDefault";
import Button from "../global/Buttons/Button";
import useKeypress from "../../hooks/useKeypress";

const Inspirations = () => {
  const [slideData, setSlideData] = useState(null);
  const [orderedOverlay, setOrderedOverlay] = useState(null);
  const [activeIdx, setActiveIdx] = useState(null);
  const [closePath, setClosePath] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const { itin, viewMode, customHistory, t } = useContext(Context);
  const { sectionNum, slideNum } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const navigateTo = useCallback( (newActiveIdx) => {
    if (newActiveIdx === null || slideData === null) return;

    const sectionIdx = parseInt(sectionNum) - 1;
    const slideIdx = parseInt(slideNum) - 1;
    const activeSlide = slideData[newActiveIdx];

    if (activeSlide?.sectionIdx !== sectionIdx || activeSlide?.slideIdx !== slideIdx) {
      navigate(`/${itin.localData.urlKey}/inspirations/${activeSlide.sectionIdx + 1}/${activeSlide.slideIdx + 1}`);
    }
  }, [itin.localData.urlKey, navigate, slideNum, slideData, sectionNum]);

  useEffect(() => {
    if (itin.overlay === null) return;
    const slides = [];
    let position = 0;
    const sortedOverlayRows = [...itin.overlay.overlayRows]
      .filter(row => row.overlayImages.length)
      .sort((a, b) => a.position - b.position)
      .map((row, i) => ({
        ...row,
        overlayImages: row.overlayImages
          .sort((a, b) => a.position - b.position)
          .map((img, j) => {
            const updatedImg = { ...img, title: row.title, sectionIdx: i, slideIdx: j, positionIdx: position++ };
            slides.push(updatedImg);
            return updatedImg;
          })
      }));

    setOrderedOverlay(sortedOverlayRows);
    setSlideData(slides);
  }, [itin.overlay]);

  useEffect(() => {
    if (!sectionNum) {
      setActiveIdx(0);
      navigateTo(0)
      return;
    }

    if (!orderedOverlay) return;

    const sectionIdx = parseInt(sectionNum)-1;
    const slideIdx = parseInt(slideNum)-1;
    let newActiveIdx;
    if (!orderedOverlay[sectionIdx]) {
      newActiveIdx = 0;
    } else {
      const sectionSlides = orderedOverlay[sectionIdx].overlayImages;
      newActiveIdx = sectionSlides[slideIdx]?.positionIdx;
      if (newActiveIdx === undefined) {
        // Invalid slide idx
        const lastSlideInSection = sectionSlides[sectionSlides.length-1];
        newActiveIdx = lastSlideInSection?.positionIdx || 0;
      }
    }
    setActiveIdx(newActiveIdx);
   if (activeIdx === newActiveIdx) return;
    navigateTo(newActiveIdx);

  }, [orderedOverlay, sectionNum, slideNum, activeIdx, navigateTo]);

  useLayoutEffect(() => {
    let newClosePath = null;
    let i = 0;
    let target;
    while (true) {
      target = customHistory[customHistory.length + i - 1];
      if (!target) {
        newClosePath = `/${itin.localData.urlKey}`;
        break;
      }
      if (target.startsWith(`/${itin.localData.urlKey}`) && !target.match(new RegExp(`^/${itin.localData.urlKey}/inspirations`, 'i'))) {

        newClosePath = i;
        break;
      }
      i--;
    }
    setClosePath(newClosePath);
  }, [pathname, customHistory, itin.localData.urlKey]);

  useKeypress('ArrowLeft', [navigate, slideNum, itin.localData?.urlKey, activeIdx], () => {
    if (activeIdx < 1) return
    handleChange('prev')
  })

  useKeypress('ArrowRight', [navigate, slideNum, itin.localData?.urlKey, activeIdx], () => {
    if (activeIdx >= slideData.length - 1) return
    handleChange('next')
  })

  const handleChange = (type) => {
    const newActiveIdx = activeIdx + (type === 'next' ? 1 : -1)
    navigateTo(newActiveIdx)
  }

  const closeModal = () => setOpenModal(false);

  const renderModal = () => {
    const phone = itin.overlay.contactPhoneNumber;
    const email = itin.overlay.contactEmail;

    let modalButtons = [];
    if (email) modalButtons.push(<button key={'email'} onClick={() => window.location = 'mailto:' + email}>{t('send_email', { lng: itin.language })}</button>);
    if (phone) modalButtons.push(<button key={'phone'} onClick={() => window.location = 'tel:' + phone}>{t('inspiration_contact_alert_call_button', { lng: itin.language })}</button>);

    return (
      <ModalDefault
        isOpen={openModal}
        onBackdropClick={closeModal}
        className="contact-us-modal"
        header={t('contact_us', { lng: itin.language })}
        buttons={modalButtons}
      />
    );
  };

  const renderCarousel = () => {
    const slides = slideData.map((data, i) =>
      <InspirationSlide
        data={data}
        key={i}
        logo={data.slideIdx === 0 ? itin.operatorLogoNode?.file?.httpsUrl || itin.operatorLogo : null}
      />
    );

    return (
      <Carousel
        slides={slides}
        nextBtnDisabled={activeIdx >= slides.length -1}
        prevBtnDisabled={activeIdx < 1}
        handleChange={handleChange}
        style={{ width: (slides.length * 100) + '%', marginLeft: (-100 * activeIdx) + '%' }}
      />
    );
  };

  if (!sectionNum || activeIdx === null || !slideData) return <LoadingScreen />;

  return (
    <Container
      className="container-inspirations"
      mainElementClassName={`inspirations inspirations-${viewMode}`}
      carouselMode={true}
      fullScreenDesktopCarousel={false}
      closePath={closePath}
    >
      { renderModal() }
      {renderCarousel()}
      {
        (itin.overlay.contactPhoneNumber || itin.overlay.contactEmail) &&
        <div className="contact-button-container">
          <Button
            className="contact-button"
            outline={true}
            onClick={() => setOpenModal(true)}
          >
            {t('contact_us', { lng: itin.language })}
          </Button>
        </div>
      }
      <InspirationBar
        items={orderedOverlay.map(({ title, overlayImages }) => ({ title, positionIdx: overlayImages[0].positionIdx }))}
        setActiveIdx={setActiveIdx}
        currentSectionIdx={parseInt(sectionNum)-1}
        navigateTo={navigateTo}
      />

    </Container>
  );
};

export default Inspirations;
