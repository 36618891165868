import { Link, useLocation } from "react-router-dom";
import { useContext, useEffect, useRef, useState } from "react";
import Button from "../global/Buttons/Button";
import { db } from "../../db";
import Context from "../../context";
import { ReactComponent as ViewMoreIcon } from '../HotelInfo/vamoos-more.svg';
import Features from "../HotelInfo/Features";
import Container from "../global/Container";
import Slider from "../global/Slider";

const PoiCarouselSlide = ({ slideData, numSlides, setEmptyContainerHeight, slideId, slideNum }) => {

  const { viewMode, setMobileViewGeneric, icons, t, itin } = useContext(Context);
  const { pathname } = useLocation();
  const [pdfLink, setPdfLink] = useState(null);
  const [imgLink, setImgLink] = useState(null);
  const [externalLink, setExternalLink] = useState(null);
  const [navigationLink, setNavigationLink] = useState(null);
  const [storedLocationData, setStoredLocationData] = useState(null);
  const [featuresScreenActive, setFeaturesScreenActive] = useState(false);
  const navigationLinkStart = 'https://www.google.com/maps/dir';
  const numberOfWaypoints = 23; //maximum allowed number of waypoints in Google Directions API in Standard free plan
  const emptyRef = useRef(null);
  const empty = document.querySelector('.empty');

  useEffect(() => setFeaturesScreenActive(pathname.endsWith(`/maps/${slideData.id}/features`)), [slideData.id, pathname]);

  useEffect(() => {
    setMobileViewGeneric(() => {
      if (!featuresScreenActive) return null;
      return 'light';
    });
  }, [featuresScreenActive, pathname, setMobileViewGeneric, viewMode]);

  useEffect(() => {
    const mimeType = slideData?.node?.file?.mimeType;
    const remoteUrl = slideData?.node?.remoteUrl;

    if (!mimeType && !remoteUrl) return;
    if (remoteUrl) setExternalLink(remoteUrl);

    if (mimeType === 'application/pdf') {
      setPdfLink(slideData?.node?.file?.httpsUrl)
    } else {
      setImgLink(slideData?.node?.file?.httpsUrl)
    }
  }, [slideData?.node?.file?.httpsUrl, slideData?.node?.file?.mimeType, slideData?.node?.remoteUrl]);

  useEffect(() => {
    if (!pathname.endsWith(slideId)) return;
    if (!emptyRef?.current) return;


    setEmptyContainerHeight(emptyRef.current.offsetHeight)
  }, [pathname, slideId, setEmptyContainerHeight, emptyRef, empty]);

  useEffect(() => {
    if (slideData.type === "route") {
      // If number of waypoints is more than maximum allowed, remove excess items
      // include the first 22 and the last 1 waypoint (to not miss out the final coordinate).
      if (slideData.waypoints.length > numberOfWaypoints) {
        const numToRemove = slideData.waypoints.length - (numberOfWaypoints - 1);
        slideData.waypoints.splice((numberOfWaypoints - 1), numToRemove, slideData.waypoints[slideData.waypoints.length - 1]);
      }
      const waypoints = slideData.waypoints.map(wp => `/${wp.latitude},${wp.longitude}`).join('');
      setNavigationLink(navigationLinkStart + waypoints)
    }
  }, [slideData.type, slideData.waypoints])

  useEffect(() => {
    if (!slideData.nesting) return;
    const getStoredItineraries = async () => {
      const storedItineraries = await db.itineraries.toArray();
      const foundItinerary = storedItineraries.find(
        itin =>
          itin.operatorCode.toLowerCase() === slideData.nesting.operatorCode.toLowerCase() &&
          itin.referenceCode.toLowerCase() === slideData.nesting.referenceCode.toLowerCase()
      );
      setStoredLocationData({
        vamoosId: foundItinerary.vamoosId,
        brief: foundItinerary.brief,
        features: foundItinerary.features,
      })
    }
    getStoredItineraries()
  }, [slideData.id, slideData.nesting])

  const renderButton = () => {
    if (!externalLink && !pdfLink && !navigationLink) return;
    return (
      <div className={!slideData.longDescription ? "placeholder" : ""}>
        {navigationLink &&
          <div className={(externalLink || pdfLink) ? "contact-button-container" : ""}>
            <Button
              className="contact-button"
              outline={true}
              coloredText={true}
              onClick={() => window.open(navigationLink)}
            >
              Start navigation
            </Button>{/* TODO translation */}
          </div>
        }
        {(externalLink || pdfLink) && (
          <>
            {externalLink && (
              <div className="external-link-description">
                {t('external_link_info_message', { lng: itin.language })}
              </div>
            )}
            <Button
              className="contact-button"
              onClick={() => window.open(externalLink ?? pdfLink)}
            >
              {t(externalLink ? 'open_external_link' : 'open_attached_document', { lng: itin.language })}
            </Button>
          </>
        )}
      </div>
    )
  }

  const renderFeatures = () => {
    return (
      <div className="featured-features">
        {
          storedLocationData?.features
            .filter(x => x.isFeatured)
            .map((x, i) => (<img key={i} src={icons[x.iconId]} alt={`${x.groupName}: ${x.name}`} />))
        }
        <Link className="view-more-button" to={pathname.includes('/daily/') ? `${slideNum}/maps/${slideData.id}/features` : `${slideData.id}/features`}>
          <ViewMoreIcon />
        </Link>
      </div>
    );
  };

  const renderText = () => {
    const longDescription = slideData.longDescription || slideData.nesting?.longDescription;
    return (
      <div className="description">
        <div className="short-description">
          {
            slideData.nesting?.shortDescription ?? null
          }
        </div>
        <div className="feature-description">
          {
            storedLocationData?.features?.length
              ? renderFeatures()
              : slideData.nesting?.longDescription &&  slideData.nesting?.shortDescription
                ? <hr />
                : null
          }
        </div>
        <div className="long-description">
          {
            longDescription && longDescription.split(/\n/).map((line, i) => {
              const urlRegex = /(https?:\/\/\S+)/g;

              // Split the line by the detected URLs and wrap them in anchor tags
              const parts = line.split(urlRegex).map((part, index) => {
                if (urlRegex.test(part)) {
                  return <a href={part} key={index} target="_blank" rel="noreferrer">{part}</a>;
                }
                return part;
              });

              return <p key={i}>{parts}</p>;
            })
          }
        </div>
      </div>
    );
  };

  useEffect(() => {
    const slide = document.getElementById('carousel-slide-' + slideId);
    const content = slide.querySelector('.content');

    const wheelMove = e => slide.scrollTop += e.deltaY;

    let initialY = null;
    const startTouch = e => initialY = e.touches[0].clientY;
    const moveTouch = e => {
      if (initialY === null) return;
      const currentY = e.touches[0].clientY;
      const diffY = initialY - currentY;
      slide.scrollTop += (diffY);
      initialY = currentY;
      e.preventDefault();
    };

    content.addEventListener('wheel', wheelMove, { passive: true });
    content.addEventListener('touchstart', startTouch, { passive: true });
    content.addEventListener('touchmove', moveTouch);

    return () => {
      content.removeEventListener('wheel', wheelMove);
      content.removeEventListener('touchstart', startTouch);
      content.removeEventListener('touchmove', moveTouch);
    };
  }, [slideId]);

  const renderContent = () => {
    if (featuresScreenActive) return <Container style={{ headerBackGroundColor: 'header-white' }}><Features features={storedLocationData?.features} section='PoiCarouselSlide'/></Container>;

    const sliderData = storedLocationData?.brief?.map(data => ({
        headline: data?.headline,
        link: data?.dailyPhotoNode?.file?.httpsUrl
    }));
    return (
      <>
        <div className="empty" ref={emptyRef} />
        <div className="content">
          {
            storedLocationData?.brief?.length
              ? <div className="slider">
                  <Slider data={sliderData} />
              </div>
              : imgLink &&
              <div className="image">
                <img src={slideData.node?.file.httpsUrl} alt="" />
              </div>
          }
          {renderText()}
          {renderButton()}
        </div>
      </>
    )
  }

  return (
    <div
      className={"poi-carousel-slide num-slides-" + numSlides}
      id={`carousel-slide-${slideId}`}
    >
      { renderContent() }
    </div>
  );
};

export default PoiCarouselSlide;
