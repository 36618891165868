import * as React from 'react';
import { Modal as MuiModal } from '@mui/material';
import './index.scss';

const Modal = ({ children, isOpen, ...props }) => {

  const onKeyPressed = (event) => {
    if (event.key === 'Escape') {
      props.onBackdropClick();
    }
  }

  return (
    <div>
      <MuiModal
        open={isOpen}
        {...props}
        onKeyDown={(event) => onKeyPressed(event)}
      >
        {children}
      </MuiModal>
    </div>
  );
}

export default Modal;
