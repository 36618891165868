import './index.scss';
import Button from "../../Buttons/Button";

const BackendUnavailable = ({ errorCode, browserOnline }) => {

  const refreshPage = () => {
    window.location.reload();
  }

  return (
    <div className="wrapper">
      <div className="substrate">
        <div className="headline">
          {!browserOnline
            ? `No Internet`
            : `We’re currently experiencing some technical issues.`
          }
          <br/>
          Please try again later
        </div>

        <div className="short-info">
          {errorCode
            ? `Error code: HTTP-${errorCode}`
            : `Error code: BACKEND-UNAVAILABLE`
          }
        </div>
        <Button
          className="contact-button"
          outline={false}
          onClick={refreshPage}
        >
          Refresh
        </Button>
      </div>
    </div>
  );
};

export default BackendUnavailable;
