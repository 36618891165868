import { useContext, useCallback, useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Container from '../global/Container';
import Carousel from '../global/Carousel';
import List from './List';
import Context from '../../context';
import useKeypress from "../../hooks/useKeypress";

const DailyActivities = ({ type, data, className, mainElementClassName, closePath }) => {
  const [itemsData, setItemsData] = useState([]);
  const { itin } = useContext(Context);
  const navigate = useNavigate();
  const params = useParams();
  const slideNum = parseInt(params.slideNum);

  const findClosestNonEmptyIndex = useCallback((startIndex, step) => {
    for (let i = startIndex + step; i >= 0 && i < itemsData.length; step < 0 ? i-- : i++) {
      if (itemsData[i]?.length > 0) {
        return i;
      }
    }
    return null;
  }, [itemsData]);
  const lastNonEmptyIndex = findClosestNonEmptyIndex(7, -1);
  const firstNonEmptyIndex = findClosestNonEmptyIndex(0, 0);

  // if user tries to navigate to an empty day, navigate to the first non-empty day
  useEffect(() => {
    if (!itemsData.length) return;
    if (!itemsData[slideNum - 1]?.length) navigate(`/${itin.localData.urlKey}/daily-activities/${slideNum + 1}`);
  }, [itemsData, itin.localData.urlKey, navigate, slideNum])

  const handleDataFromList = useCallback((index, data) => {
    if (itemsData.length >= 7) return;
    setItemsData((prevData) => {
      const newData = [...prevData];
      newData[index] = data;
      return newData;
    });
  }, [itemsData.length]);

  const renderSlides = () => {
    const out = [];
    for (let i = 0, n = 7; i < n; i++) {
      const date = new Date();
      date.setDate(date.getDate() + i);
      out.push(
        <List
          key={i}
          date={date}
          data={data}
          type={type}
          isCarouselSlide={true}
          slideNumIdx={i+1}
          slideNum={slideNum}
          passItemsDataToDailyActivities={(index, data) => handleDataFromList(index, data)}
        />
      );
    }
    return out;
  };

  const handleChange = useCallback((direction) => {
    const closestNonEmptyIndex = findClosestNonEmptyIndex(slideNum - 1, direction === 'next' ? 1 : -1);
    const requested = closestNonEmptyIndex === null ? firstNonEmptyIndex + 1 : closestNonEmptyIndex + 1;

    if (requested < 1 || requested > 7) return;
    navigate(`/${itin.localData.urlKey}/daily-activities/${requested}`);
  }, [findClosestNonEmptyIndex, firstNonEmptyIndex, itin.localData.urlKey, navigate, slideNum]);

  const generateStyle = () => {
    const out = { width: '700vw' };
    if (slideNum > 1 && slideNum <= 7) {
      // No point setting otherwise as it'll trigger the transition
      out.marginLeft = `${(slideNum-1) * -100}vw`;
    }
    return out;
  };

  useKeypress('ArrowLeft', handleChange, () => {
    handleChange('prev')
  });

  useKeypress('ArrowRight', handleChange, () => {
    handleChange("next")
  });

  return (
    <Container
      className={className}
      mainElementClassName={mainElementClassName}
      carouselMode={true}
      fullScreenDesktopCarousel={false}
      closePath={closePath}
    >
      <Carousel
        slides={renderSlides()}
        nextBtnDisabled={slideNum >= lastNonEmptyIndex + 1}
        prevBtnDisabled={slideNum <= firstNonEmptyIndex + 1}
        handleChange={handleChange}
        style={generateStyle()}
        mobileScrollButtonsTop={true}
      />
    </Container>
  );
};

export default DailyActivities;