import CircularProgress from "@mui/material/CircularProgress";
import './index.scss';

const Spinner = ({ type }) => {
  return <CircularProgress
    size={type === 'small' ? 40 : 65}
    thickness={type === 'small' ? 2.5 : 1.7}
  />
};

export default Spinner;

