import { useContext, useEffect, useState, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Context from '../../context';
import './List.scss';

const List = ({ date, type, data, isCarouselSlide, slideNumIdx, slideNum, passItemsDataToDailyActivities }) => {
  const [listContent, setListContent] = useState(null);
  const [doInterval, setDoInterval] = useState(false);
  const { viewMode, itin } = useContext(Context);
  const { pathname } = useLocation();

  const generateDateStrings = useCallback(() => {
    if (!date) return null;
    return {
      dayName: date.toLocaleDateString(['en-GB'], { weekday: 'long' }),
      dayNameLocalised: date.toLocaleDateString([itin.language || 'en-GB'], { weekday: 'long' }),
      dateStringLocalised: date.toLocaleDateString([itin.language || 'en-GB'], {
        day: 'numeric',
        month: 'long',
        year: 'numeric'
      }),
    };
  }, [date, itin?.language]);

  const renderDateInfo = () => {
    if (!isCarouselSlide || !date) return null;
    const { dayNameLocalised, dateStringLocalised } = generateDateStrings();
    return (
      <div className="date-info">
        <span className="day-name">{dayNameLocalised}</span>
        <span className="date-string">{dateStringLocalised}</span>
      </div>
    );
  };

  const renderTitle = () => {
    if (!data.title || viewMode !== 'desktop') return null;
    return (
      <div className="title">{data.title}</div>
    );
  };

  const renderDescription = () => {
    if (!data.description) return null;
    return (
      <div className="description">{data.description}</div>
    );
  };

  useEffect(() => {
    const renderList = (data, isDailyActivities) => {
      const dateInfo = generateDateStrings();
      const nowMs = new Date().getTime();
      let doRunInterval = false;
      const tmp = [];
      for (const item of data) {
        if (date && item.weekdaysRestriction && !item.weekdaysRestriction.includes(dateInfo.dayName.toLowerCase())) continue;

        const startMs = item.restrictionStartDate ? item.restrictionStartDate * 1000 : null;
        const endMs = item.restrictionEndDate ? item.restrictionEndDate * 1000 : null;

        if (startMs && nowMs < startMs) {
          doRunInterval = true;
          continue;
        }

        if (endMs) {
          if (nowMs < endMs) doRunInterval = true;
          if (nowMs >= endMs) continue;
        }

        tmp.push(item);
      }
      data = tmp;

      setDoInterval(doRunInterval);
      if (type === 'dailyActivities' && isDailyActivities && passItemsDataToDailyActivities) passItemsDataToDailyActivities(slideNumIdx - 1, tmp);
      setListContent(
        <div className={`list-items-container list-items-container-${type}`}>
          {data.length > 0 ? (
            data.map((x, i) => (
              <Link
                className={`list-item list-item-${type} tile-grid-tile`}
                key={i}
                to={`${pathname}${pathname.endsWith('/') ? '' : '/'}${x.id}`}
                style={{ backgroundImage: (type !== 'directories' && x.backgroundImage) ? `url("${x.backgroundImage}")` : null }}
                tabIndex={(slideNumIdx === slideNum)
                  ? "0"
                  : "-1"
                }
              >
                <div className="list-item-gradient">
                  <div className="title">{x.title}</div>
                  {/*<div className="title">{x.title} {x.title} {x.title}</div>*/}
                  {
                    type !== 'directories' &&
                    <div className="description">{x.description}</div>
                    // <div className="description">{x.description} {x.description}</div>
                  }
                  {
                    x.entryType === 'list' &&
                    <img className="chevron" src="/icons/chevron-down.png" alt="" />
                  }
                </div>
              </Link>
            ))
          ) : (
            <Link
              className={`list-item list-item-placeholder`}
              to={pathname}
              aria-disabled="true"
              tabIndex="-1"
            >
            </Link>
          )
          }
        </div>
      );
    }

    const listData = data?.entryType === 'list' ? data.children : data;
    renderList(listData, !!itin?.dailyActivities?.find(item => item?.id === data[0]?.id));
    let intervalId;
    if (doInterval) {
      intervalId = setInterval(renderList, 1000 * 60 * 30, listData);
    }
    //to move focus in multilevel lists to the first element
    document.querySelector(".list-item")?.focus();


    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [itin?.dailyActivities, passItemsDataToDailyActivities, data, date, doInterval, generateDateStrings, pathname, slideNum, slideNumIdx, type]);

  return (
    <div
      className={`list list-${type}`}
    >
      { renderDateInfo() }
      { renderTitle() }
      { renderDescription() }
      { listContent }
    </div>
  );
};

export default List;
