import { useEffect, useState, useContext, useMemo } from "react";
import { utcToZonedTime } from "date-fns-tz";
import { useWeather } from '../../hooks/api';
import Context from '../../context';
import WeatherTime from './WeatherTime';
import WeatherIcon from './WeatherIcon';
import WeatherTemp from './WeatherTemp';

import './WeatherItem.scss';

const WeatherItem = props => {
  const [weatherData, setWeatherData] = useState(null);
  const [activeDayIdx, setActiveDayIdx] = useState(0);
  const { latitude, longitude, name } = props.data;
  const { data } = useWeather(latitude, longitude);
  const { itin, viewMode, t, deviceLanguage } = useContext(Context);

  //Set what time will be displayed in the application
  const allowedDisplayTimes = useMemo(() => [7, 10, 13, 16, 19, 22, 24], []);

  useEffect(() => {
    setActiveDayIdx(0);
    return setActiveDayIdx(0);
  }, [props.active]);

  useEffect(() => {
    if (!data) return;

    const days = [];
    let day = [];
    let orderDay = t('today', { lng: itin.language });

    const firstDateLocalTime = new Date(data.list[0].dt * 1000);
    const firstDate = utcToZonedTime(firstDateLocalTime, props.data.timezoneName)
    const firstDateDay = firstDate.getUTCDate();
    firstDate.setDate(firstDateDay + 1);
    const secondDateDay = firstDate.getUTCDate();
    let previousMainDay = firstDateDay;

    data.list.forEach((hour, index) => {
      const mainDateLocalTime = new Date(hour.dt * 1000);
      const mainDate = utcToZonedTime(mainDateLocalTime, props.data.timezoneName)
      let mainHour = mainDate.getHours();
      if (mainHour === 0){
        mainHour = 24
      }
      const mainDateDay = mainDate.getDate();

      if ((mainHour >= allowedDisplayTimes[0] && mainHour <= allowedDisplayTimes[allowedDisplayTimes.length - 1])) {

        const setDisplayedTime = (mainHourZonedTime) => {
          for (const time of allowedDisplayTimes) {
            if (mainHourZonedTime <= time + 1) return time;
          }
          return null;
        }

        day.push({
          day: orderDay,
          temp: hour.main.temp,
          icon: hour.weather[0].icon,
          displayedTime: setDisplayedTime(mainHour),
          time: hour.dt
        });
      }
      if (previousMainDay !== mainDateDay || index === data.cnt - 1) {
        if (mainDateDay === secondDateDay) {
          orderDay = t('tomorrow', { lng: itin.language });
        } else {
          orderDay = mainDate.toLocaleDateString([itin.language || deviceLanguage], {
            weekday: 'short',
            day: 'numeric',
            month: 'long',
          });
          orderDay = orderDay.charAt(0).toUpperCase() + orderDay.slice(1);
        }
        if ((day.length > 5) || data.list[index + 1] !== undefined) {
          days.push(day);
        }
        day = [];
        previousMainDay = mainDateDay;
      }
    });

    setWeatherData({
      name: name,
      temp: data.list[0].main.temp,
      icon: data.list[0].weather[0].icon,
      days,
    });
  }, [allowedDisplayTimes, data, itin.language, name, props.data.timezoneName, t, deviceLanguage]);

  const onDaySelect = (e, idx) => {
    if (viewMode === 'mobile') e.stopPropagation();

    const collapsed = idx === activeDayIdx;
    setActiveDayIdx(collapsed ? null : idx);
  };

  const onSelfClick = (e) => {
    if (viewMode !== 'mobile' || !props.active) props.onClick(e);
  };

  if (!weatherData) {
    return (
      <></>
    );
  }

  return (
    <li
      onClick={(e) => onSelfClick(e)}
      onKeyDown={(event) => props.onKeyDown(event)}
      className={
        `weather-item ${props.active ? 'active' : props.hiddenIfNotActive ? 'hidden' : ''} weather-item-${viewMode}`
      }
      tabIndex="0"
    >
      <div className={`top${!props.onlyOneLocation ? ' pointer' : ''}`}>
        <div className="name">{weatherData.name}</div>
        <div className="main-weather-info">
          <WeatherTemp temp={weatherData.temp} units={props.units} />
          <WeatherIcon icon={weatherData.icon} />
          <img className="chevron" src="/icons/chevron-down.png" alt="" />
        </div>
      </div>
      <ul className="bottom">
        {
          weatherData.days.map((day, i) => {
            const isActive = i === activeDayIdx;
            let previewHourIdx = day.findIndex(x => new Date(x.time * 1000).getHours() === 12);
            if (previewHourIdx === -1) previewHourIdx = 0;

            return (
              <li
                className={`weather-day ${isActive ? 'active' : 'inactive'}`}
                onClick={(e) => onDaySelect(e, i)}
                key={i}
              >
                <div className="date-info">{day[0]?.day}</div>
                <ul className="weather-icons">
                  {
                    day.map((hour, ii) => {
                      return (
                        <li
                          key={hour.time}
                          className={ii === previewHourIdx ? 'show-in-preview' : 'hide-in-preview'}
                        >
                          <WeatherTime displayedTime={hour.displayedTime}/>
                          <WeatherIcon icon={hour.icon} />
                          <WeatherTemp temp={hour.temp} units={props.units} />
                        </li>
                      )
                    })
                  }
                </ul>
              </li>
            )
          })
        }
      </ul>
    </li>
  );
};

export default WeatherItem;
