import React, { useContext, useState } from 'react';
import SlideTemplate from "./SlideTemplate";
import Context from "../../context";
import LoadingScreen from "../global/LoadingScreen";
import { getUserInformation } from "../../libraries/api";

const LoginPromptScreen = ({ isActive, loginPromptData }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { userAuthInfo, setItineraryUpdate, setLoginPrompt, setGlobalModalContent, setUserAuthInfo, setLastUserAuthInfoRequestTime, t } = useContext(Context);
  const loginPrompts = {
    requireLogin : {
      text2: t('auth_message_require_login_line_one'),
      text3: t('auth_message_require_login_line_two'),
      text4: `${localStorage.getItem("userEmail") ? `${t('auth_message_require_login_line_three')} ${localStorage.getItem("userEmail")}` : ''}`
    },
    loadingFailed :{
      text2: t('auth_message_loading_failed_line_one'),
      text3: t('auth_message_loading_failed_line_two'),
      text4: `${localStorage.getItem("userEmail") ? `${t('auth_message_loading_failed_line_three')} ${localStorage.getItem("userEmail")}` : ''}`
    }
  }

  const retryButtonClicked = async () => {
    if (loginPromptData === "requireLogin") {
      setIsLoading(true);
      const { res, data, error } = await getUserInformation();
      if (!res.ok || error) {
        setGlobalModalContent(() => ({
          closable: true,
          message: error || data?.error[0]?.message || data?.error || t("auth_error_get_profile_info"),
          buttons: [{ text: t('ok'), type: 'close' }]
        }));
        throw new Error(`Sign in error: Res: ${res.status}, Error: ${error || data?.error[0]?.message || data?.error}, Data: ${JSON.stringify(data)}`);
      }

      if (data.logins.all.length === 0) {
        setIsLoading(false);
        return;
      } else {
        setUserAuthInfo(data);
        setLastUserAuthInfoRequestTime(Date.now());
        setItineraryUpdate(data?.logins?.default);
        setIsLoading(false);
        setLoginPrompt(null);
      }
    }

    if (loginPromptData === "loadingFailed") {
      setLoginPrompt(null);
      setItineraryUpdate(userAuthInfo?.logins?.default);
    }
  }

  return (
    <>
      {isLoading
          ? <LoadingScreen/>
          : <SlideTemplate
            className="login-prompt-screen"
            text2={loginPrompts[loginPromptData].text2}
            text3={loginPrompts[loginPromptData].text3}
            text4={loginPrompts[loginPromptData].text4}
            button={{
              text: t("try_again"),
            }}
            isActive={isActive}
            submitFn={() => retryButtonClicked()}
          />
      }
    </>
  );
};

export default LoginPromptScreen;
