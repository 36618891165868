import { useContext, useState, useEffect, useCallback, useRef } from 'react';
import Context from '../../context';
import { useParams, useLocation, Link } from 'react-router-dom';
import PostUploader from './PostUploader';
import OptionsModal from './OptionsModal';
import './PostSlide.scss';
import PhotobookIcon from "../global/Icons/Photobook";
import { getTzOffset } from "../../helpers";

const PostSlide = ({
                     data,
                     hideDays,
                     type,
                     dayNum,
                     setPreviewSrc,
                     slideNumIdx,
                     allSortedPostsData,
                     setDataInit,
                     dataInit,
                     placeholderImage
                   }) => {
  const [posts, setPosts] = useState(null); // posts per each day
  const [slideContent, setSlideContent] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [editPostData, setEditPostData] = useState(null);
  const [postEditedOrPreviewed, setPostEditedOrPreviewed] = useState(false);
  const [verticalScrollValue, setVerticalScrollValue] = useState(0);

  const { itin, viewMode, t, mobileViewGeneric, deviceLanguage } = useContext(Context);
  const { pathname } = useLocation();
  const { slideNum, id } = useParams();
  const contentContainer = useRef(null);

  //scroll to where options button was clicked
  useEffect(() => {
    if (!slideContent || openMenu || !postEditedOrPreviewed) return;
    contentContainer.current?.scrollTo({
      top: (verticalScrollValue),
      behavior: 'instant'
    });
  }, [slideContent, postEditedOrPreviewed, editPostData, slideNumIdx, openMenu, verticalScrollValue]);

  //remove condition if user navigate to another slide
  useEffect(() => {
    setPostEditedOrPreviewed(false)
  }, [slideNum])

  //scroll to the bottom
  useEffect(() => {
    if ((slideNumIdx !== parseInt(slideNum)) || !slideContent) return;
    if (openMenu || postEditedOrPreviewed) return;

    contentContainer.current?.scrollTo({
      top: (contentContainer.current?.scrollHeight),
      behavior: 'instant'
    });
  }, [postEditedOrPreviewed, openMenu, slideContent, slideNum, slideNumIdx])

  useEffect(() => {
    setPosts(data)
  }, [data])

  const renderDateContent = useCallback(() => {
    const tripDate = new Date(itin.departureDate * 1000);
    const tzOffset = getTzOffset(tripDate, itin.timeZone);
    const tripTimezone = tzOffset * 60 * 60 * 1000;

    const utc = tripDate.getTime() + (tripDate.getTimezoneOffset() * 60000);
    const tripDateUtc = new Date(utc + tripTimezone);
    const calculatedDate = new Date(tripDateUtc).setDate(tripDateUtc.getDate() + (dayNum - 1));
    const dayOfPosts = new Date(calculatedDate);
    const localDayOfPosts = dayOfPosts.toLocaleDateString([itin.language || deviceLanguage], { weekday: 'short', day: 'numeric', month: 'long', year: 'numeric' })

    return (
      <div className={`posts-header ${viewMode !== 'mobile' && itin.photobookLink ? "photobook-link" : ""}`}>
        <div className="date-info">
          <div className="day">{t('day_format', { lng: itin.language }).replace('%d', dayNum)}</div>
          <div className="date">
            {localDayOfPosts.charAt(0).toUpperCase() + localDayOfPosts.slice(1)}
          </div>
        </div>
        {
          viewMode !== 'mobile' &&
          <div className={itin.photobookLink ? "photobook-icon-container" : "hide"}>
            <PhotobookIcon
              icon={'/icons/photobook_link_icon_3x.png'}
              photobookLink={itin.photobookLink}
            />
          </div>
        }
      </div>
    );
  }, [dayNum, itin.departureDate, itin.language, itin.photobookLink, itin.timeZone, t, viewMode, deviceLanguage]);

  const renderPostInfo = useCallback((post) => {
    const todaySeconds = new Date() / 1000;
    const dayOfTrip = new Date(post.addedAt * 1000);
    const minsAgo = Math.floor((todaySeconds - post.addedAt) / 60);
    const minsInDay = 60 * 24;

    const translationKey = minsAgo < 1 ? 'just_now' :
      minsAgo === 1 ? 'minute_plural[one]' :
        minsAgo < 60 ? 'minute_plural[other]' :
          minsAgo === 60 ? 'hour_plural[one]' :
            minsAgo < minsInDay ? 'hour_plural[other]' :
              minsAgo === minsInDay ? 'day_plural[one]' :
                'day_plural[other]';

    let minsAgoInCorrectUnits = minsAgo < 60 ? minsAgo :
      minsAgo < minsInDay ? (minsAgo / 60) :
        (minsAgo / minsInDay);
    minsAgoInCorrectUnits = Math.floor(minsAgoInCorrectUnits);

    const timeSinceStr = t(translationKey, { lng: itin.language }).replace('%d', minsAgoInCorrectUnits);

    return (
      <div className="post-info">
        {dayOfTrip.toLocaleTimeString([itin.language || deviceLanguage], { hour: '2-digit', minute: '2-digit' })} - {timeSinceStr}{minsAgo < 1 ? '' : ' ' + t('ago', { lng: itin.language })} {post.username}:
      </div>
    );
  }, [deviceLanguage, itin.language, t]);

  const renderMainContent = useCallback(() => {
    const onOptionsClick = (post) => {
      setEditPostData(post);
      setOpenMenu(true);
      setPostEditedOrPreviewed(true)
      setVerticalScrollValue(contentContainer.current?.scrollTop)
    };

    return (
      <>
        <OptionsModal
          openMenu={openMenu}
          setOpenMenu={setOpenMenu}
          postUuid={editPostData?.uuid}
          id={editPostData?.id}
          posts={posts}
          setPosts={setPosts}
          postData={editPostData}
          allSortedPostsData={allSortedPostsData}
          setDataInit={setDataInit}
        />
        {type === 'daily'
          ? hideDays
            ? null
            : renderDateContent()
          : renderDateContent()
        }
        <div className="content"
             ref={contentContainer}>
          {posts?.length !== 0 && (
            <ul>
              {posts.map((x, i) => (
                <li className="post-item" key={i + 'posts-slide'}>
                  <div className="top">
                    {renderPostInfo(x)}
                    {
                      (
                        <div className="options" onClick={() => onOptionsClick(x)}>
                          <span className="circle" />
                          <span className="circle" />
                          <span className="circle" />
                        </div>
                      )
                    }
                  </div>
                  {
                    x.content && <div className="text-content">{x.content}</div>
                  }
                  {
                    x.photo && (
                      <Link
                        to={`/${itin.localData.urlKey}` + (type === 'daily' ? `/daily/${slideNum}/posts/` : `/posts/${slideNum}/preview/`) + x.id}
                        onClick={() => {
                          setPreviewSrc(x.photo)
                          setPostEditedOrPreviewed(true)
                          setVerticalScrollValue(contentContainer.current?.scrollTop)
                        }}
                      >
                        <img src={x.photo} alt="" />
                      </Link>
                    )
                  }
                </li>
              ))
              }
            </ul>
          )}
          <PostUploader posts={posts} setPosts={setPosts} dayNum={dayNum} slideNumIdx={slideNumIdx}
                        slideNum={parseInt(slideNum)} setDataInit={setDataInit}
                        dataInit={dataInit} />
        </div>
        {posts?.length === 0 && placeholderImage && (
          <div className="placeholder-image">{placeholderImage}</div>
        )}
      </>
    )
  }, [placeholderImage, setDataInit, dataInit, allSortedPostsData, editPostData, slideNum, dayNum, hideDays, openMenu, posts, renderDateContent, renderPostInfo, setPreviewSrc, type, itin.localData.urlKey, slideNumIdx]);

  const renderPreviewContent = useCallback(() => (
    <div className="mobile-preview">
      <img src={posts.find(x => x.id === parseInt(id))?.photo} alt="" />
    </div>
  ), [id, posts]);

  const renderEditContent = useCallback(() => {
    return (
      <PostUploader
        dayNum={dayNum}
        posts={posts}
        setPosts={setPosts}
        setDataInit={setDataInit}
      />
    );
  }, [dayNum, posts, setDataInit]);

  useEffect(() => {
    if (posts == null) return;
    setSlideContent(
      pathname.includes('edit') ? renderEditContent() :
        mobileViewGeneric ? renderPreviewContent() :
          renderMainContent()
    );
  }, [mobileViewGeneric, renderPreviewContent, posts, renderMainContent, pathname, renderEditContent]);

  return (
    <div className={`post-slide post-slide-${viewMode} ${mobileViewGeneric ? 'mobile-view-generic' : ''}`}>
      <div className="post-slide-content"
      >
        {
          slideContent
        }
      </div>
    </div>
  );
};

export default PostSlide;
