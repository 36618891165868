import { useEffect, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import Context from '../../context';
import WeatherScreen from '../Weather';
import './Weather.scss';

const Weather = () => {
  const [data, setData] = useState(null);
  const { itin } = useContext(Context);
  const { slideNum } = useParams();

  useEffect(() => {
    setData(itin.brief[parseInt(slideNum)-1]?.location);
  }, [itin.brief, slideNum]);

  if (!data) return <div>Loading</div>;

  return (
      <WeatherScreen type='daily' location={data} />
  );
};

export default Weather;
