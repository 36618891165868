import { useContext } from 'react';
import Context from '../../context';

const WeatherTime = props => {
  const { itin, viewMode, deviceLanguage } = useContext(Context);

  const date = new Date(props.displayedTime * 60 * 60 * 1000);

  return (
    <div className="weather-time">
      {
        date.toLocaleTimeString([itin.language || deviceLanguage], {
          hour: viewMode === 'mobile' ? 'numeric' : '2-digit',
          hour12: viewMode === 'mobile',
          timeZone: 'UTC'
        })
      }
    </div>
  );
};

export default WeatherTime;
