import { useContext, useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import Context from '../../context';
import Container from '../global/Container';
import Features from './Features';
import { ReactComponent as ViewMoreIcon } from './vamoos-more.svg';
import './index.scss';


const HotelInfo = () => {
  const [featuresScreenActive, setFeaturesScreenActive] = useState(false);
  const { viewMode, itin, setMobileViewGeneric, icons } = useContext(Context);
  const { pathname } = useLocation();

  useEffect(() => setFeaturesScreenActive(!!pathname.match(new RegExp(`^/${itin.localData?.urlKey}/hotel-info/features`, 'i'))), [itin.localData?.urlKey, pathname]);

  useEffect(() => {
    setMobileViewGeneric(() => {
      if (!featuresScreenActive) return null;
      return 'light';
    });
  }, [featuresScreenActive, pathname, setMobileViewGeneric, viewMode]);

  const renderFeatures = () => {
    if (!itin.features.length) return;

    return (
      <div className="featured-features">
        {/* TODO: The icons from the backend are black. We need white. */}
        {
          itin.features
            .filter(x => x.isFeatured)
            .map((x, i) => (<img key={i} src={icons[x.iconId]} alt={`${x.groupName}: ${x.name}`} />))
        }
        <Link className="view-more-button" to="features">
          <ViewMoreIcon />
        </Link>
      </div>
    );
  };

  const renderSocialButtons = () => {
    if (!itin.operatorInfo) return null;
    const { facebookUrl, instagramUrl, twitterUrl } = itin.operatorInfo;
    if (!facebookUrl && !instagramUrl && !twitterUrl) return null;

    return (
      <div className="social-buttons">
        { facebookUrl && <a href={facebookUrl} target="_blank" rel="noreferrer">{<img src="/icons/fb.png" alt="" />}</a> }
        { twitterUrl && <a href={twitterUrl} target="_blank" rel="noreferrer">{<img src="/icons/tw.png" alt="" />}</a> }
        { instagramUrl && <a href={instagramUrl} target="_blank" rel="noreferrer">{<img src="/icons/ig.png" alt="" />}</a> }
      </div>
    );
  };


  const renderContent = () => {
    if (featuresScreenActive) return <Features features={itin.features} section='HotelInfo'/>;

    return (
      <div className="hotel-info-default">
        { (viewMode === 'mobile' && itin.operatorLogoNode?.file?.httpsUrl) && <img className="logo" src={itin.operatorLogoNode.file.httpsUrl} alt="" /> }
        { itin.shortDescription && <div className="short-description">{itin.shortDescription}</div> }
        {/* TODO map link once maps done */}
        { itin.locations?.[0]?.location && <Link to={`/${itin.localData.urlKey}`} className="location">{itin.locations[0].location}</Link> }
        { itin.operatorInfo?.enquiryPhone && <a className="phone" href={`tel:${itin.operatorInfo.enquiryPhone}`}>{itin.operatorInfo.enquiryPhone}</a> }
        { itin.operatorInfo?.enquiryEmail && <a className="email" href={`mail:${itin.operatorInfo.enquiryEmail}`}>{itin.operatorInfo.enquiryEmail}</a> }
        { itin.operatorInfo?.website && <a className="website" href={itin.operatorInfo.website} target="_blank" rel="noreferrer">{itin.operatorInfo.website}</a> }
        { renderFeatures() }
        { renderSocialButtons() }
        { itin.longDescription && <div className="long-description">{itin.longDescription}</div> }
      </div>
    );
  };

  return (
    <Container
      className="container-hotel-info"
      mainElementClassName={`hotel-info hotel-info-${viewMode}`}
    >
      { renderContent() }
    </Container>
  );
};

export default HotelInfo;
