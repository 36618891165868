import { useContext, useEffect, useState, useLayoutEffect } from 'react';
import { useNavigate, useParams, useLocation, Link } from "react-router-dom";
import Container from '../global/Container';
import Context from '../../context';
import Header from '../global/Header';
import Slide from './Slide';
import LoadingScreen from '../global/LoadingScreen';
import Carousel from '../global/Carousel';
import './index.scss';
import IconButton from "@mui/material/IconButton";
import useKeypress from "../../hooks/useKeypress";

const SETTINGS = {
  storyboard: {
    pathSection: 'daily',
    hideHeaderLogo: false
  },
  gallery: {
    pathSection: 'gallery',
    hideHeaderLogo: true
  }
};

const Brief = ({ type }) => {
  const [totalPages, setTotalPages] = useState(null);
  const [slideData, setSlideData] = useState(null);
  const [requestedSlideNum, setRequestedSlideNum] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [closePath, setClosePath] = useState(null);
  const [hideScrollButtons, setHideScrollButtons] = useState(false);
  const [pathStart, setPathStart] = useState(null);

  const { viewMode, customHistory, itin, previewMode, mobileScreenWithLogo } = useContext(Context);

  const location = useLocation();
  const navigate = useNavigate();
  const { slideNum, previewLinkId } = useParams();

  useEffect(() => setPathStart(previewMode ? `/preview/${previewLinkId}` : `/${itin.localData.urlKey}/${SETTINGS[type].pathSection}`), [itin.localData?.urlKey, previewMode, previewLinkId, type]);

  useEffect(() => {
    if (!previewMode && (!itin.brief || !itin.brief.length)) navigate(`/${itin.localData.urlKey}`);

    const slides = itin.brief?.map((x, i) => (
      <Slide
        data={x}
        key={`brief-${x.id}-${i}`}
        slideNum={i+1}
        pathStart={pathStart}
        type={type}
        isActive={parseInt(slideNum) === i+1}
        previewWithLogo={previewMode && viewMode === 'mobile'}
      />
    ))
    if (previewMode) slides.unshift(<Slide homeComponent={true} slideNum={0} key={0} />);
    const numSlides = slides.length;

    let r = slideNum;
    if (!r || parseInt(r) < 1) r = previewMode ? 0 : 1;
    else if (parseInt(r) > numSlides) r = previewMode ? numSlides-1 : numSlides;
    setRequestedSlideNum(r);

    setSlideData(() => {
      return slides;
    });

    setTotalPages(numSlides);
    setIsLoading(false);
  }, [navigate, slideNum, itin?.brief, itin?.localData?.urlKey, pathStart, previewMode, type, viewMode]);

  useEffect(() => {
    if (!pathStart) return;
    if (typeof requestedSlideNum === 'number' && parseInt(slideNum) !== requestedSlideNum) {
      navigate(`${pathStart}/${requestedSlideNum}`);
    }
  }, [navigate, requestedSlideNum, pathStart, slideNum]);

  useLayoutEffect(() => {
    // No close path in prev mode b/c we don't want to allow going back
    if (previewMode) {
      setClosePath(null);
      return;
    }

    if (!pathStart) return;

    let newClosePathVal = null;
    const editPost = type === 'storyboard' && !!(location.pathname.match(new RegExp(`^${pathStart}/\\d+/posts/\\d+/edit[/]?`, 'i')));

    // Find closest non-daily path
    let i = 0;
    let target;
    while (true) {
      target = customHistory[customHistory.length + i - 1];
      if (!target) {
        newClosePathVal = `/${itin.localData.urlKey}`;
        break;
      }
      if (
        (editPost && !target.match(new RegExp(`^${pathStart}/\\d+/posts/\\d+`), 'i')) ||
        (!target.match(new RegExp(`^${pathStart}`, 'i')))
      ) {
        newClosePathVal = i;
        break;
      }
      i--;
    }
      setClosePath(() => {
        return (
          viewMode === 'desktop' ||
          location.pathname.match(new RegExp(`^${pathStart}/\\d+[/]?$`, 'i')) ||
          editPost
        )
          ? newClosePathVal
          : null
      });
  }, [location.pathname, customHistory, viewMode, itin.localData?.urlKey, previewMode, pathStart, type]);

  useEffect(() => {
    // Mobile view: only allow scroll if on daily summary section
    setHideScrollButtons(!!((viewMode === 'mobile') && !location.pathname.match(new RegExp(`^${pathStart}/\\d+[/]?$`, 'i'))));
  }, [location.pathname, pathStart, viewMode]);

  useKeypress('ArrowLeft', [navigate], () => {
    handleChange('prev')
  })

  useKeypress('ArrowRight', [navigate], () => {
    handleChange("next")
  })

  const generateStyle = () => {
    const out = { width: `${100 * totalPages}vw` };
    const b = parseInt(slideNum);
    if (b > (previewMode ? 0 : 1) && b <= (previewMode ? totalPages - 1 : totalPages)) {
      // No point setting otherwise as it'll trigger the transition
      out.marginLeft = `${(b-(previewMode ? 0 : 1)) * -100}vw`;
    }
    return out;
  };

  const handleChange = (direction) => {
    setRequestedSlideNum(requestedSlideNum => {
      let requested = parseInt(slideNum);
      if (direction === 'next') requested++;
      else if (direction === 'prev') requested--;

      if (requested < (previewMode ? 0 : 1) || requested > totalPages) return requestedSlideNum;

      return requested;
    });
  };

  if (!slideNum || isLoading) {
    return <LoadingScreen />;
  }

  const renderViewAllIcon = () => {
    if (type !== 'gallery') return null;
    return (
      <div className="gallery-view-all-icon-container">
        <IconButton
          className={`gallery-view-all-icon`}
          aria-label="delete"
          component={Link}
          to={`/${itin.localData.urlKey}/gallery/all`}
        >
          <img src="/icons/grid.svg" alt="" />
        </IconButton>
      </div>
    );
  };

  return (
    <Container
      className="container-brief"
      mainElementClassName={`brief brief-${viewMode}`}
      closePath={closePath}
      carouselMode={true}
      fullScreenDesktopCarousel={true}
      barebonesMode={SETTINGS[type].hideHeaderLogo}
      isGallery={type === 'gallery'}
    >
      <div className={`brief-header${(mobileScreenWithLogo) ? '-displaylogo' : ''}`}>
        <Header type="brief" closePath={closePath} barebonesMode={SETTINGS[type].hideHeaderLogo} />
      </div>
      { renderViewAllIcon() }
      <Carousel
        slides={slideData}
        nextBtnDisabled={totalPages < 2 || hideScrollButtons || parseInt(slideNum) >= (previewMode ? totalPages - 1 : totalPages)}
        prevBtnDisabled={totalPages < 2 || hideScrollButtons || parseInt(slideNum) < (previewMode ? 1 : 2)}
        handleChange={handleChange}
        style={generateStyle()}
        type={type}
      />
    </Container>
  );
};

export default Brief;
