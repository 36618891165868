import React, { useContext, useEffect, useState } from "react";
import Context from "../../context";
import './SignIn.scss';

const SignInCustom = ({ handleSignIn, setSpinner, state, nonce, customAuthName }) => {
  const {
    composerData,
    setGlobalModalContent,
    t,
    customAuthParams,
    isCustomAuth, setIsCustomAuth,
  } = useContext(Context);

  const [customAuthUrl, setCustomAuthUrl] = useState('');

  useEffect(() => {
    if (composerData?.auth?.[customAuthName]) {
      const url = composerData?.auth?.[customAuthName]?.authUrl
        .replace('{{state}}', state)
        .replace('{{nonce}}', nonce)
        .replace('{{redirect_uri}}', encodeURIComponent(`${window.location.origin}/auth/callback/${composerData?.auth?.[customAuthName]?.name}`))
      setCustomAuthUrl(url);
      setIsCustomAuth(true);
    }
  }, [composerData?.auth, customAuthName, nonce, setIsCustomAuth, state]);

  useEffect(() => {
    if (customAuthParams === null || Object.keys(customAuthParams).length === 0) return;
    if (customAuthParams.state !== state) {
      setGlobalModalContent(() => ({
        closable: true,
        message: t("auth_error_state_mismatch"),
        buttons: [{ text: t('ok'), type: 'close' }]
      }));
    }
    setSpinner(true);
    const logInUserBody = {
      method: composerData?.auth?.[customAuthName]?.name,
      clientId: composerData?.auth?.[customAuthName]?.systemClientId,
      code: customAuthParams.code,
      redirectUri: `${window.location.origin}/auth/callback/${composerData?.auth?.[customAuthName]?.name}`,
      ...(customAuthParams.nonce && { nonce: customAuthParams.nonce }),
    }

    async function runHandleSignIn() {
      await handleSignIn('custom', logInUserBody, null);
    }

    runHandleSignIn();
  }, [setGlobalModalContent, state, t, composerData?.auth, customAuthName, customAuthParams, handleSignIn, setSpinner]);

  return (
    <>
      {
        isCustomAuth
          ? <div className="custom-sign-in-button" onClick={() => {
            window.location.href = customAuthUrl;
            setSpinner(true);
          }}>
            <img className="custom-btn" src={composerData?.auth?.[customAuthName]?.imageUrl} alt="" />
          </div>
          : null
      }
    </>
  )
}

export default SignInCustom;
