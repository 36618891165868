import { useEffect, useContext, useState, useLayoutEffect } from "react";
import { useLocation } from 'react-router-dom';
import Context from '../../context';
import './index.scss';
import Container from "../global/Container";

const FaqTermsAbout = ({ type }) => {
  const {
    viewMode,
    setMobileViewGeneric,
    composerData,
    itin,
    customHistory,
    navMenuItems
  } = useContext(Context);

  const [closePath, setClosePath] = useState(null);

  const { pathname } = useLocation();

  useEffect(() => {
    setMobileViewGeneric('default');

    return () => {
      setMobileViewGeneric(null);
    };
  }, [setMobileViewGeneric]);

  useLayoutEffect(() => {
    let newClosePath = null;

    let i = 0;
    let target;
    while (true) {
      target = customHistory[customHistory.length + i - 1];
      if (!target) {
        if (parseInt(pathname.split('/')[1]) === itin?.localData.urlKey) {
          newClosePath = `/${itin?.localData.urlKey}`;
          break;
        } else {
          newClosePath = `/login`;
          break;
        }
      }
      if (!target.match(`^${pathname}`, 'i')) {
        newClosePath = i;
        break;
      }
      i--;
    }

    setClosePath(newClosePath);
  }, [customHistory, itin, pathname, viewMode]);

  return (
    <Container
      className={`container-faq-terms-about container-faq-terms-about-${type}`}
      mainElementClassName={`faq-terms-about faq-terms-about-${viewMode} faq-terms-about-${type}`}
      barebonesMode={!navMenuItems}
      closePath={closePath}
    >
      <div
        className="content"
        dangerouslySetInnerHTML={{ __html: composerData.menu.documents[type].html_content?.replace(/\\n/g, '<br />') }}
      />
    </Container>
  );
};

export default FaqTermsAbout;
