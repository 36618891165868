import DirectionsIcon from "@mui/icons-material/Directions";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import React from "react";
import './Popup.scss';

const Popup = ({ name, hideInfo }) => {
  return (
    <>
      <button className="leftButton" type="button">
        <DirectionsIcon
          tabIndex="0"/>
      </button>
      <div className="caption">
        {name}
      </div>
      {
        !hideInfo && (
          <button className="rightButton" type="button">
            <InfoOutlinedIcon
              tabIndex="0"/>
          </button>
        )
      }
    </>
  );
};

export default Popup;
