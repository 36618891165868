import { useContext } from 'react';
import Context from '../../context';
import { Link, useNavigate } from 'react-router-dom';
import Modal from '../global/Modal';
import './PreviewModal.scss';

const PreviewModal = ({ src }) => {
  const { removeItemsFromCustomHistory } = useContext(Context);
  const navigate = useNavigate();
  const goBack = () => {
    removeItemsFromCustomHistory(-1);
    navigate(-1);
  };

  return (
    <Modal isOpen={true} onBackdropClick={goBack}>
      <div className="preview-modal">
          <Link className="close" to={-1} onClick={() => removeItemsFromCustomHistory(-1) }>
            <img src="/icons/exit.svg" alt="" />
          </Link>
        <img className="photo" src={src} alt="" />
      </div>
    </Modal>
  );
};

export default PreviewModal;