import { useContext } from 'react';
import Context from '../../../context';
import './index.scss';
import appConfig from "../../../config/app";

const Footer = () => {
  const { t, itin } = useContext(Context);
  const link = `${appConfig.apiHost}/api/itineraries/${itin?.operatorCode}-${itin?.passcode}/poweredby`

  return (
    <footer>
      <div className="powered-by">
        <a href={link} target="_blank" rel="noreferrer">
          {t('powered_by', { lng: itin?.language })}
          <img src="/images/powered_by_logo_444x66.png" alt=""/>
        </a>
      </div>
    </footer>
  );
};

export default Footer;
