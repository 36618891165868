import { useContext, useEffect, useState, useCallback } from "react";
import Context from '../../context';
import { differenceInCalendarDays } from 'date-fns';
import Container from "../global/Container";
import './index.scss';
// import { getTzOffset } from "../../helpers";
import Button from '../global/Buttons/Button';
import TextInput from '../global/TextInput';



const Home = () => {
  const { itin, t, previewMode, setLoginDetails, setMobileScreenWithLogo, viewMode } = useContext(Context);
  const [depDateMs, setDepDateMs] = useState(null);
  const [retDate, setRetDate] = useState(null);
  const [currDay, setCurrDay] = useState(null);
  const [futureDate, setFutureDate] = useState(null);
  const [newPasscode, setNewPasscode] = useState('');
  const [countdown, setCountdown] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  });

  useEffect(() => {
    if (previewMode && viewMode === 'mobile') setMobileScreenWithLogo(true)
    return () => {
      setMobileScreenWithLogo(false);
    }
  }, [previewMode, setMobileScreenWithLogo, viewMode]);

  useEffect(() => {
    // Can't use localData for trips b/c instantView itineraries don't save anything to localData
    setDepDateMs(itin.type === 'stay' ? itin.localData?.arrivalDate : itin.departureDate * 1000);
    setRetDate(new Date(itin.type === 'stay' ? itin.localData?.departureDate : itin.returnDate * 1000).setHours(23, 59, 59));
  }, [itin]);

  const setCurrentDay = useCallback(() => {
    if (!depDateMs) return;

    // const itineraryTimezoneOffset = getTzOffset(itin.timeZone, depDateMs);
    //
    // let startDate = new Date(depDateMs + itineraryTimezoneOffset);
    // const localDeviceTimeZoneOffset = startDate.getTimezoneOffset() * 60 * 1000;
    // startDate = new Date((depDateMs + itineraryTimezoneOffset) + localDeviceTimeZoneOffset);
    // const currentDate = new Date(Date.now());
    //
    // const currentDay = differenceInCalendarDays(currentDate, startDate) + 1;
    // setCurrDay(currentDay);

    setCurrDay(() => differenceInCalendarDays(new Date(), new Date(depDateMs)) + 1);
  }, [depDateMs]);

  const setTimer = useCallback(() => {
    const today = new Date();

    // Parse date to seconds
    const sub = Math.floor((depDateMs - today) / 1000);
    setFutureDate(sub > 0);

    // Calculate differences
    const days = Math.floor(sub / 60 / 60 / 24);
    const hours = Math.floor(sub / 60 / 60) - days * 24;
    const minutes = Math.floor(sub / 60) - (hours * 60 + days * 24 * 60);
    const seconds = sub - (minutes * 60 + hours * 60 * 60 + days * 24 * 60 * 60);

    setCountdown({ days, hours, minutes, seconds });
  }, [depDateMs]);

  useEffect(() => {
    setCurrentDay();
    const interval = setInterval(() => {
      setTimer();
    }, 1000);

    return () => {
      clearInterval(interval);
      setFutureDate(null);
    };
  }, [setCurrentDay, setTimer, itin.id]);

  const generateDateContent = () => {
    if ((retDate - new Date()) <= 0) return null; // Ended

    if (futureDate === null) return null;

    if (futureDate) {
      const { days, hours, minutes, seconds } = countdown;
      const generateValueStr = value => value < 10 ? `0${value}` : value;

      return (
        <div className="countdown date-info">
          <div className="countdown-item days">
            <div className="value">{generateValueStr(days)}</div>
            <div className="definition">{t('days', { lng: itin.language })}</div>
          </div>
          <div className="separator">:</div>
          <div className="countdown-item hours">
            <div className="value">{generateValueStr(hours)}</div>
            <div className="definition">{t('hours', { lng: itin.language })}</div>
          </div>
          <div className="separator">:</div>
          <div className="countdown-item minutes">
            <div className="value">{generateValueStr(minutes)}</div>
            <div className="definition">{t('minutes', { lng: itin.language })}</div>
          </div>
          <div className="separator">:</div>
          <div className="countdown-item seconds">
            <div className="value">{generateValueStr(seconds)}</div>
            <div className="definition">{t('seconds', { lng: itin.language })}</div>
          </div>
        </div>
      );
    }

    if (currDay === null) return null; // No dates typically with nested stays

    return (
      <div className="day-number date-info">
        {t('day_format', { lng: itin.language }).replace('%d', currDay)}
      </div>
    );
  };

  const renderEnterPasscode = () => {
    if (!itin.localData?.showPasscodeForm) return null;

    const submit = async () => {
      setLoginDetails({
        operatorCode: itin.localData.operatorCode,
        passcode: newPasscode,
        redirect: true,
        type: 'changePasscode',
        phase: 'initial', // This feature isn't used much so it's overkill to make API request to get itinerary data JUST to check if we have the vamoosId stored.
        clientId: null, // clientId not required for the Stays
      })
    };

    return (
      <div className="passcode-form">
        <TextInput
          placeholder={t('main_passcode_hint', { lng: itin.language })}
          value={newPasscode}
          onChange={(e) => setNewPasscode(e.target.value.replace(/^\s+/g, ''))}
          translucentBackground={true}
          onKeyUp={e => {
            if (!newPasscode) return;
            if (e.code === 'Enter') submit();
          }}
        />
        <Button
          disabled={!newPasscode}
          onClick={submit}
        >{t('enter', { lng: itin.language })}</Button>
      </div>
    );
  };

  const renderTitle = () => {
    if (itin.type === 'stay') return null;

    return (
      <div className="title">
        {itin.destination} {itin.holidayType} <br />
        {itin.localData?.client?.fullname || itin.leadName}
        {/*{itin.leadSurname}*/}
      </div>
    );
  };

  const renderContent = () => (
    <div className={`home-centered home-centered-${itin.type}`}>
      { renderTitle() }
      { generateDateContent() }
      { renderEnterPasscode() }
    </div>
  );


  const content = renderContent();
  if (previewMode) return content;

  return (
    <Container className="container-home" mainElementClassName="home">
      <div className="full-screen-container">
        {content}
      </div>
    </Container>
  )
};

export default Home;
