import Header from '../Header';
import MobileHeader from '../MobileHeader';
import Footer from '../Footer';
import './index.scss';
import { useContext, useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import Context from "../../../context";
import Navbar from "../Navbar";
import UserSelect from "./UserSelect";

const Container = ({
  className,
  mainElementClassName,
  children,
  closePath,
  carouselMode,
  fullScreenDesktopCarousel,
  barebonesMode,
  mobileHeaderButtonRight,
  style = {},
  backButtonClickFn,
  isGallery
}) => {
  const {
    itin,
    viewMode,
    mobileViewGeneric,
    navMenuItems,
    navActiveIdx,
    previewMode,
    headerColorOverrideClass,
  } = useContext(Context);

  const [headerButtonColor, setHeaderButtonColor] = useState('');
  const [isHomeScreen, setIsHomeScreen] = useState(null);
  const [hideMainNavbar, setHideMainNavbar] = useState(false);

  const { pathname } = useLocation();
  useEffect(() => setIsHomeScreen(!!pathname.match(new RegExp(`^/${previewMode ? 'preview/[0-9a-fA-F]{8}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{12}/0' : itin?.localData?.urlKey}[/]?$`, 'i'))), [itin?.localData?.urlKey, pathname, previewMode]);
  useEffect(() => setHideMainNavbar(pathname.includes('/daily/')), [pathname]);

  useEffect(() => {
    setHeaderButtonColor(() => {
      if (headerColorOverrideClass) return headerColorOverrideClass;

      // always use navigation color for login screen
      if (pathname.match('^/login')) return 'btn-color-login-nav';

      // non-itinerary routes, desktop, no itinerary loaded, use accent color
      if (viewMode === 'desktop' && !itin && pathname.match(/^\/[A-Za-z]/)) return 'btn-color-accent';

      if (viewMode === 'mobile' && mobileViewGeneric) return 'btn-color-accent';

      return 'btn-color-white';
    });
  }, [pathname, itin, viewMode, mobileViewGeneric, headerColorOverrideClass]);

  useEffect(() => {
    const handleBlur = type => {
      const bodyClassList = document.getElementById('body').classList;
      if (type === 'remove') bodyClassList.remove('blur');
      else bodyClassList.add('blur');
    };

    const handleGradient = type => {
      const style = type === 'remove' ? 'display:none' :
        type === 'fadein' ? 'animation: fadein .3s  forwards' :
          'animation: fadeout .3s  forwards';

      document.getElementById('gradient').setAttribute('style', style);
    };

    if (!itin) {
      handleGradient('remove');
      handleBlur('remove');
    } else if (isHomeScreen) {
      handleGradient('fadeout');
      handleBlur('remove');
    } else {
      handleGradient('fadein');
      handleBlur('add');
    }
  }, [isHomeScreen, itin]);

  const checkOnMobileViewGeneric = (mobileViewGenericValue) => {
    let className = '';
    if (mobileViewGenericValue === true) {
      className = 'mobile-view-generic'
    } else if (mobileViewGenericValue === 'light') {
      className = 'mobile-view-generic-light'
    }
    return className
  }

  return (
    <div
      className={`container container-${(itin && isHomeScreen) || pathname.match(/^\/login/i)? 'desktop' : viewMode} ${viewMode === 'mobile' && (itin ? !isHomeScreen : true) && !pathname.match(/^\/login/i) ? checkOnMobileViewGeneric(mobileViewGeneric) : ''} ${className ? className : ''}`}
      style={style}
    >
      {
        viewMode === 'mobile' && !pathname.match(/^\/login/i) && (itin ? !isHomeScreen : true) ?
          <>
            <MobileHeader
              backButtonPath={closePath}
              itineraryType={itin?.type}
              mobileHeaderButtonRight={mobileHeaderButtonRight}
              buttonColor={headerButtonColor}
              backButtonClickFn={backButtonClickFn}
              textColorOverride={headerColorOverrideClass}
              headerBackGroundColor={style?.headerBackGroundColor}
            />
            <main className={`${carouselMode ? 'carousel-mode' : ''} ${mainElementClassName}`}>
              {children}
            </main>
          </>
          :
          <>
            {isGallery
              ? null
              : <Header
                closePath={closePath}
                barebonesMode={barebonesMode}
                buttonColor={headerButtonColor}
                backButtonClickFn={backButtonClickFn}
              />}
            { !barebonesMode && !previewMode && <Navbar className={`main-nav${hideMainNavbar ? ' main-nav-hidden' : ''}`} items={navMenuItems} activeIdx={navActiveIdx} /> }
            <main
              className={`${carouselMode ? fullScreenDesktopCarousel ? 'carousel-mode full-screen-desktop-carousel' : 'carousel-mode' : ''} ${mainElementClassName ?? ''}`}
            >
              {children}
            </main>
            { !barebonesMode && !fullScreenDesktopCarousel && <Footer /> }
          </>
      }
      {
        (!previewMode && itin && itin.type === 'trip') && <UserSelect />
      }
    </div>
  );
};

export default Container;
