import { useContext, useLayoutEffect, useState } from "react";
import Context from '../../context';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Modal from '../global/Modal';
import './FeaturesModal.scss';

const FeaturesModal = ({ children, section }) => {
  const [closeButtonPath, setCloseButtonPath] = useState(-1);
  const { removeItemsFromCustomHistory, customHistory, itin } = useContext(Context);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useLayoutEffect(() => {
    if (!itin) return;
    if (section === 'PoiCarouselSlide') return;

    let backPath;
    const newPath = `/${itin.localData.urlKey}/hotel-info`;

    let i = 0;
    let target;
    while(true) {
      target = customHistory[customHistory.length + i - 1];
      if (!target) {
        backPath = newPath;
        break;
      }
      if (target.match(new RegExp('^' + newPath + '[/]?$', 'i'))) {
        backPath = i;
        break;
      }
      i--;
    }
    setCloseButtonPath(backPath);
  }, [customHistory, itin, pathname, section]);

  const goBack = () => {
    removeItemsFromCustomHistory(closeButtonPath);
    navigate(closeButtonPath);
  };

  return (
    <Modal isOpen={true} onBackdropClick={goBack}>
      <div className="features-modal">
        <Link className="close" to={closeButtonPath} onClick={() => removeItemsFromCustomHistory(closeButtonPath) }>
          <img src="/icons/exit.svg" alt="" />
        </Link>
        { children }
      </div>
    </Modal>
  );
};

export default FeaturesModal;
