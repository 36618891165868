import { useContext, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from "uuid";
import { generateDeviceTimezoneStr } from "../../helpers";
import { setDeviceTimezone } from "../../libraries/api";
import Context from '../../context';

const DeviceSetup = ({ children }) => {
  const { uuid, setUuid, timezone, setTimezone } = useContext(Context);
  const { pathname } = useLocation();

  useEffect(() => {
    let browserUuid = localStorage.getItem('uuid');
    if (!browserUuid) {
      browserUuid = uuidv4();
      localStorage.setItem('uuid', browserUuid);
    }
    setUuid(browserUuid);
  }, [setUuid]);

  useEffect(() => {
    if (!uuid) return;
    const tz = generateDeviceTimezoneStr();
    const lclTz = localStorage.getItem('timezone');

    const updateTimezone = async () => {
      await setDeviceTimezone({ platform: 'vamoos_web', timezone: tz }, uuid);
    };

    if (!lclTz || lclTz !== tz) {
      localStorage.setItem('timezone', tz);
      updateTimezone();
    }

    if (tz !== timezone) setTimezone(tz);
  }, [pathname, setTimezone, timezone, uuid]);

  return children;
};

export default DeviceSetup;
