import { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { Link } from "react-router-dom";
import './index.scss';
import { ScrollButtonsSmall } from "../Buttons/ScrollButtons";
import Context from "../../../context";

const Navbar = ({ items, className, activeIdx }) => {
  const [showArrows, setShowArrows] = useState(true);
  const [value, setValue] = useState(null);

  const { viewMode, isPortalPreview } = useContext(Context);

  const handleChange = (e, newValue) => {
    // Don't underline items with external links
    if (items[newValue].type !== 'external') setValue(newValue || 0);
  };

  useEffect(() => {
    setValue(value =>
      activeIdx === value ? value :
        activeIdx === -1 ? 0 :
          activeIdx
    );
  }, [activeIdx]);

  // Show arrows when the view changes
  useEffect(() => setShowArrows(true), [viewMode]);

  useEffect(() => {
    if (className.includes('main-nav-hidden')) return // to hide main navbar in daily/brief section on mozilla browser
    if (viewMode === 'desktop' || isPortalPreview) return // to display scroll arrows on the main navbar on desktop view and for the portal preview

    const scroller = document.querySelector(`.${className}.MuiBox-root .MuiTabs-root .MuiTabs-scroller`);
    if (!scroller) return;
    const removeArrows = () => {
      setShowArrows(false);
    };
    scroller.addEventListener('scroll', removeArrows);

    return () => scroller.removeEventListener('scroll', removeArrows);
  });

  //avoids the ability to navigate with arrow button when selected any item of Navbar with the Tab button
  const onKeyPressed = (event) => {
    if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
      event.stopPropagation();
    }
  }

  const onTouchEndCapture = (event) => {
    event.stopPropagation();
  }

  // Don't want to load if value hasn't been set
  // Otherwise it will default to underlining the first item (home) then highlight the active one
  if (typeof value !== 'number') return null;

  return (
    <Box
      className={className + ` ${viewMode === 'desktop' ? 'desktop-nav' : 'mobile-nav'} ${activeIdx === -1 ? 'indicator-hidden' : ''} ${isPortalPreview ? 'show-arrows' : ''}`}>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="icon tabs example"
        aria-labelledby="navbar"
        variant="scrollable"
        scrollButtons={showArrows}
        ScrollButtonComponent={ScrollButtonsSmall}
        allowScrollButtonsMobile={true}
        onKeyDown={onKeyPressed}
        onTouchEndCapture={onTouchEndCapture}
      >
        {
          items?.map((x, i) => {
            const args = {
              key: `navitem-${i}-${x.title}`,
              icon: x.icon,
              label: x.title,
              disableRipple: viewMode === 'mobile',
              disableFocusRipple: viewMode === 'mobile',
              focusRipple: !(viewMode === 'mobile'),
              iconPosition: viewMode === 'desktop' ? 'start' : 'top',
            };

            if (x.type === 'external') {
              args.component = 'a';
              args.href = x.path;
              args.target = '_blank';
              args.rel = "noreferrer";
            } else {
              args.component = Link;
              args.to = x.path;
            }
            return <Tab {...args} />
          })
        }
      </Tabs>
    </Box>
  );
};

export default Navbar;
