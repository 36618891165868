import { useState, useEffect, useContext, useLayoutEffect } from "react";
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import Context from '../../context';
import Container from "../global/Container";
import Carousel from "../global/Carousel";
import PostSlide from './PostSlide';
import { useJournal } from '../../hooks/api';
import PreviewModal from "./PreviewModal";
import './index.scss';
import useKeypress from "../../hooks/useKeypress";
import { differenceInCalendarDays } from 'date-fns';

const Posts = ({ type, dayData, menuLabel }) => {
  const [requestedSlideNum, setRequestedSlideNum] = useState(null);
  const [totalPages, setTotalPages] = useState(null);
  const [postSlides, setPostSlides] = useState(null);
  const [hideScrollButtons, setHideScrollButtons] = useState(false);
  const [closePath, setClosePath] = useState(null);
  const [previewSrc, setPreviewSrc] = useState('');
  const [allItineraryDays, setAllItineraryDays] = useState(null);
  const [allSortedPostsData, setAllSortedPostsData] = useState(null);
  const [dataInit, setDataInit] = useState(null);
  const [editOrPreviewMode, setEditOrPreviewMode] = useState(false);

  const { itin, viewMode, customHistory, t, setScreenNameOverride, setMobileViewGeneric } = useContext(Context);

  const location = useLocation();
  const navigate = useNavigate();
  const { slideNum, id } = useParams();
  const { response, data } = useJournal(itin.operatorCode, itin.referenceCode);
  const postDisplayLimit = 200; //maximum number of posts to display

  useEffect(() => {
    if (response === null) return;
    setDataInit(data);
  }, [data, response])

  let startDay = 1;

  const numberOfDaysInItinerary = differenceInCalendarDays(itin.returnDate * 1000, itin.departureDate * 1000) + 1;

  const isNumberOfDaysInItineraryMoreThenInStoryboard = itin.hideDays
    ? itin.hideDays
    : numberOfDaysInItinerary > (itin.brief[itin.brief.length - 1]?.day || 0); //Zero if there is no elements in itin.brief

  const endDay = isNumberOfDaysInItineraryMoreThenInStoryboard
    ? numberOfDaysInItinerary
    : itin.brief[itin.brief.length - 1]?.day;

  useEffect(() => {
    if (itin.brief) {
      let out = []

      // if hideDays is turned on in the storyboard counts only duration of the itinerary
      if (itin.hideDays) {
        for (let i = 1; i <= numberOfDaysInItinerary; i++) {
          out.push({ day: i })
        }
      } else {
        // Remove duplicate days
        const filteredBriefWithoutDuplicateDays = itin.brief.filter((x, i, arr) => x.day !== arr[i - 1]?.day)

        //Adding days that are not in the storyboard and difference between the days is more than one
        filteredBriefWithoutDuplicateDays.forEach((x, i) => {
          if (i === 0 && x.day !== 1 ) {
            for (let j = 1; j < x.day; j++) {
              out.push({day: j})
            }
            out.push(x)
          } else if (x.day === i+1) {
            out.push(x)
          } else {
            for (let j = out[out.length-1].day+1; j < x.day; j++) {
              out.push({day: j})
            }
            out.push(x)
          }
        })

        //adding days if the duration of the itinerary is greater than the number of storyboard days
        const dayCount = out[out.length - 1]?.day || 0 //Zero if there is no elements in itin.brief
        if (isNumberOfDaysInItineraryMoreThenInStoryboard) {
          const difference = numberOfDaysInItinerary - (itin.brief[itin.brief.length - 1]?.day || 0);
          for (let i = 1; i <= difference; i++) {
            out.push({ day: dayCount + i })
          }
        }
      }
      setAllItineraryDays(out)
    }
  }, [isNumberOfDaysInItineraryMoreThenInStoryboard, type, itin.brief, numberOfDaysInItinerary, itin.hideDays]);

  useEffect(() => {
    setEditOrPreviewMode(location.pathname.includes('edit') || location.pathname.includes('preview'));
  }, [location.pathname]);

  useEffect(() => {
    let newScreenNameOverride;
    if (location.pathname.includes('edit')) {
      newScreenNameOverride = t('edit_note', { lng: itin.language });
    } else {
      const prevMode = !!(viewMode === 'mobile' && !location.pathname.includes('edit') && id);
      setMobileViewGeneric(prevMode);

      newScreenNameOverride = prevMode ? t('image_preview', { lng: itin.language }) :
        type === 'daily' ? menuLabel :
          null;
    }
    setScreenNameOverride(newScreenNameOverride);

    return () => {
      setScreenNameOverride(null);
    };
  }, [type, menuLabel, setScreenNameOverride, viewMode, id, setMobileViewGeneric, location.pathname, t, itin?.language]);

  useEffect(() => {
    if (type === 'daily') return;

    let reqSlideNum = slideNum;
    if (!slideNum || parseInt(slideNum) < 1 || location.pathname.match(new RegExp(`^/${itin.localData.urlKey}/posts[/]?$`, 'i'))) reqSlideNum = 1;
    else if (slideNum > endDay) reqSlideNum = endDay;

    setRequestedSlideNum(reqSlideNum);
  }, [navigate, type, slideNum, itin, location.pathname, itin.localData.urlKey, endDay]);

  useEffect(() => {
    if (typeof requestedSlideNum === 'number' && requestedSlideNum !== parseInt(slideNum)) {
      navigate(`/${itin.localData.urlKey}/posts/${requestedSlideNum}`);
    }
  }, [requestedSlideNum, itin.localData.urlKey, navigate, slideNum]);

  useEffect(() => {
    if (!dataInit) return;
    // The post day adjustment.
    // If the post has a day earlier than the first day of itinerary, then the first day (startDay) is assigned;
    // if later than the last day, the last day (endDay) is assigned
    const notes = dataInit.notes.reduce((out, current) => {
      let adjustedDay = current.day < startDay
        ? startDay
        : current.day > endDay
          ? endDay
          : current.day

      if (!out[adjustedDay]) out[adjustedDay] = []
      out[adjustedDay].push(current);
      return out;
    }, {});

    for (const key in notes) {
      notes[key].sort((a, b) => {
        return a.position - b.position
      })
    }

    //TODO check if we need this "found"
    setAllSortedPostsData(allItineraryDays.map((x) => {
      const found = Object.values(notes).find(p => {
        let out
        if (x.day === p[0].day) out = p
        if (x.day === startDay && p[0].day <= startDay) out = p
        if (x.day === endDay && p[0].day >= endDay) out = p
        return out
      });
      return found || [];
    }))
  }, [dataInit, dayData, allItineraryDays, itin, type, slideNum, endDay, startDay]);

  useEffect(() => {
    if (!allSortedPostsData) return;

    const { hideDays } = itin;
    let postSlideData;
    const placeholderImages = [
      <img src="/images/placeholder_1.png" alt="" />,
      <img src="/images/placeholder_2.png" alt="" />,
      <img src="/images/placeholder_3.png" alt="" />,
      <img src="/images/placeholder_4.png" alt="" />
    ];
    const showPlaceholderImages = itin.language === 'en' && itin.photobookLink;

    if (type === 'daily') {
      postSlideData = [
        <PostSlide
          key={0}
          data={allSortedPostsData.find(p => p[0]?.day === dayData?.day) || []}
          dayNum={dayData?.day}
          hideDays={hideDays}
          type={type}
          setPreviewSrc={setPreviewSrc}
          slideNumIdx={parseInt(slideNum)}
          allSortedPostsData={allSortedPostsData}
          setDataInit={setDataInit}
          dataInit={dataInit}
          placeholderImage={showPlaceholderImages ? placeholderImages[(dayData?.day - 1) % placeholderImages.length] : null}
        />
      ]
    } else {
      postSlideData = allSortedPostsData.slice(0, postDisplayLimit).map((x, i) => (
        <PostSlide
          key={i}
          data={x}
          dayNum={i + 1}
          hideDays={hideDays}
          type={type}
          setPreviewSrc={setPreviewSrc}
          slideNumIdx={i + 1}
          allSortedPostsData={allSortedPostsData}
          setDataInit={setDataInit}
          dataInit={dataInit}
          placeholderImage={showPlaceholderImages ? placeholderImages[i % placeholderImages.length] : null}
        />
      ));
    }
    setPostSlides(postSlideData);
    setTotalPages(postSlideData.length);
  }, [dataInit, allItineraryDays, allSortedPostsData, dayData?.day, endDay, itin, slideNum, startDay, type])

  useEffect(() => {
    setHideScrollButtons((!!id || totalPages < 2));
  }, [totalPages, id]);

  useLayoutEffect(() => {
    let newClosePathVal = null;
    if (type !== 'daily') {
      // Find closest non-posts path
      let i = 0;
      let target;
      while (true) {
        target = customHistory[customHistory.length + i - 1];
        if (!target) {
          newClosePathVal = `/${itin.localData.urlKey}`;
          break;
        }
        if ((id && !target.match(new RegExp('^' + location.pathname, 'i'))) || !target.match(new RegExp(`^/${itin.localData.urlKey}/posts`, 'i'))) {
          newClosePathVal = i;
          break;
        }
        i--;
      }
    }
    setClosePath(newClosePathVal);
  }, [id, location.pathname, customHistory, type, itin.localData.urlKey]);

  const generateStyle = () => {
    const out = { width: `${100 * totalPages}vw` };
    if (slideNum > 1 && slideNum <= totalPages) {
      // No point setting otherwise as it'll trigger the transition
      out.marginLeft = `${(slideNum - 1) * -100}vw`;
    }
    return out;
  };

  const handleChange = (direction) => {
    let requested = slideNum;
    if (direction === 'next') requested++;
    else if (direction === 'prev') requested--;

    if (requested < 1 || requested > totalPages) return;

    setRequestedSlideNum(requested);
  };

  useKeypress('ArrowLeft', [slideNum], () => {
    if (id && location.pathname.includes("edit")) return;
    handleChange('prev');
  })

  useKeypress('ArrowRight', [slideNum], () => {
    if (id && location.pathname.includes("edit")) return;
    handleChange("next");
  })

  const renderPreviewModal = () => {
    if (viewMode !== 'desktop' || !id || location.pathname.includes('edit')) return null;
    return <PreviewModal src={previewSrc || dataInit.notes.find(x => x.id === parseInt(id))?.photo} />
  };

  const loading = (!dataInit || !postSlides);

  if (type === 'daily') {
    return (
      <div className={`posts daily-posts daily-posts-${viewMode}`}>
        {
          loading ? null : (
            <>
              {
                renderPreviewModal()
              }
              {/* Note: only one slide will exist here */}
              {postSlides}
            </>
          )
        }
      </div>
    );
  }

  return (
    <Container
      className="container-posts"
      mainElementClassName={`posts posts-${viewMode}`}
      closePath={closePath}
      carouselMode={true}
      fullScreenDesktopCarousel={false}
    >
      {
        loading ? null : (
          <>
            {
              renderPreviewModal()
            }
            <Carousel
              slides={postSlides}
              nextBtnDisabled={slideNum >= totalPages}
              prevBtnDisabled={slideNum < 2}
              handleChange={handleChange}
              style={generateStyle()}
              mobileScrollButtonsTop={true}
              hideScrollButtons={hideScrollButtons}
              disableSwipe={editOrPreviewMode}
            />
          </>
        )
      }
    </Container>
  );
};

export default Posts;
