import { useState, useEffect, useContext } from "react";
import Context from '../../../context';
import { db } from '../../../db';
import ModalDefault from '../Modal/ModalDefault';
import './UserSelect.scss';
import { updateTravellerName } from "../../../libraries/api";

const UserSelect = () => {
  const [openUserSelectModal, setOpenUserSelectModal] = useState(false);
  const [openFriendlyNameSetModal, setOpenFriendlyNameSetModal] = useState(false);
  const [selectedFriendlyName, setSelectedFriendlyName] = useState('');
  const [selectedUserIdx, setSelectedUserIdx] = useState(null);
  const [newClientInfo, setNewClientInfo] = useState({ id: null, fullname: null, friendlyName: null });

  const { itin, setItin, t, isUserAuthenticated, userAuthInfo, isLoggingOut, loginDetails, isLoginRoute, itineraryLoaded, isLoggingIn, setGlobalModalContent, setLoginDetails, isPortalPreview } = useContext(Context);

  useEffect(() => {
    if (!isUserAuthenticated || !userAuthInfo || isLoggingOut || isLoginRoute || isLoggingIn || itin?.clients?.length === 0) return;

    // do not show any modals if client id is already stored in itin
    // this return also avoids re-rendering infinite loop
    if (newClientInfo?.id === null && itin?.localData?.client && itin?.localData?.client?.id !== null && (itin?.localData?.client?.friendlyName || itin?.localData?.client?.friendlyName === null)) return;

    let isActive = true;
    const isCurrent = itin.clients.find(client => client.isCurrent);

    if (itin?.clients.length > 1 && !isCurrent) {
      setOpenUserSelectModal(true);
      return;
    }

    if (itin?.localData?.client?.friendlyName === null) {
      return;
    } else {
      if (isActive && itineraryLoaded && !isCurrent?.friendlyName) setOpenFriendlyNameSetModal(true);
    }

    return () => {
      isActive = false
    };
  }, [newClientInfo, itin?.clients, isLoggingIn, itineraryLoaded, isLoginRoute, isLoggingOut, isUserAuthenticated, itin?.id, itin?.localData?.client, loginDetails, userAuthInfo]);

  useEffect(() => {
    if (isLoginRoute) return;
    let isActive = true;
    const handleUserSelect = async () => {
      if (newClientInfo.friendlyName === '') {
        if (isActive) {
          setOpenUserSelectModal(false);
          setOpenFriendlyNameSetModal(true);
        }
      } else {
        await db.itineraries.update(itin.localData.id, { client: newClientInfo });
        if (isActive) {
          setItin({ ...itin, localData: { ...itin.localData, client: newClientInfo } });
          setOpenUserSelectModal(false);
          setOpenFriendlyNameSetModal(false);
          setSelectedFriendlyName(null);
          setSelectedUserIdx(null);
          setNewClientInfo({ id: null, fullname: null, friendlyName: null });

          const isCurrent = !!itin.clients.find(client => client.isCurrent);
          if (!isCurrent) {
            const storedItineraries = await db.itineraries
              .orderBy('lastLogin')
              .reverse()
              .toArray();

            setLoginDetails({
              operatorCode: storedItineraries[0].operatorCode,
              passcode: storedItineraries[0].passcode || storedItineraries[0].referenceCode,
              redirect: false,
              type: 'loading',
              clientId: newClientInfo.id,
            })
          }
        }
      }
    };

    if (newClientInfo.fullname && newClientInfo.id ) handleUserSelect();
    return () => {
      isActive = false;
    };
  }, [isLoginRoute, itin, newClientInfo, setItin, setLoginDetails, isUserAuthenticated]);

  useEffect(() => {
    if (isUserAuthenticated || isLoginRoute) return;
    let isActive = true;

    const checkDb = async () => {
      const { clients, localData } = itin;
      const { client, id } = localData;
      const numClients = clients.length;

      // If itinerary has no clients, make sure local doesn't have client info
      if (!numClients) {
        if (client !== null) {
          const newClientData = { client: null };
          await db.itineraries.update(id, newClientData);
          if (isActive) setItin({ ...itin, localData: { ...localData, ...newClientData } });
        }
        return;
      }

      const found = clients.find(x => x.fullname === client?.fullname);

      // If itinerary clients list has the client which is stored locally, we don't need to update the local client
      // If this client in the itinerary has a different friendlyName to the one saved locally, update local
      if (client?.fullname && found) {
        if (isActive && found.friendlyName && (found.friendlyName !== client.friendlyName)) {
          setNewClientInfo({ id: client.id,  fullname: client.fullname, friendlyName: found.friendlyName });
        }
        return;
      }

      // If just one client in itinerary clients list, use that
      if (numClients === 1) {
        // await handleUserSelect(clients[0].fullname, clients[0].friendlyName, isActive);
        if (isActive) setNewClientInfo({ ...clients[0], friendlyName: clients[0].friendlyName || '' });
      } else {
        if (isActive && !isLoggingOut) setOpenUserSelectModal(true); //used isLoggingOut to hide userSelectModal if user sign out
      }
    };

    checkDb();

    return () => {
      isActive = false
    };
  }, [isLoginRoute, loginDetails, isUserAuthenticated, itin, setItin, isLoggingOut]);

  const updateTravellerNameRequest = async (fullName, friendlyName, clientInfoUpdateId) => {
    try {
      const { res,  error } = await updateTravellerName({ client: { fullname: fullName, friendlyName: friendlyName }}, itin.operatorCode, itin.referenceCode, clientInfoUpdateId);
      if (!res.ok || error) {
        // eslint-disable-next-line no-throw-literal
        throw { modalMessage: t(res.status === 404 ? "error_itinerary_not_recognised" : "error_name_update") };
      }
    } catch (e) {
      console.error(e);
      setGlobalModalContent(() => ({
        closable: true,
        message: e.modalMessage || t("error_name_update"),
        buttons: [{ text: t('ok'), type: 'close' }]
      }));
    }
  }

  const onFormSubmit = (e, friendlyName = selectedFriendlyName) => {
    e.preventDefault();
    const clientInfoUpdate = { ...newClientInfo };
    let authUserFullName;
    if (selectedUserIdx || selectedUserIdx === 0) {
      clientInfoUpdate.fullname = itin.clients[selectedUserIdx].fullname;
      clientInfoUpdate.id = itin.clients[selectedUserIdx].id;
      friendlyName = itin.clients[selectedUserIdx].friendlyName || friendlyName;
    }
    if (isUserAuthenticated) {
      const isCurrent = itin?.clients?.find(client => client.isCurrent);
      const foundItinerary = userAuthInfo?.logins?.all.find(login => login.id === itin.id);
      authUserFullName = itin?.localData?.client?.fullname || clientInfoUpdate.fullname || isCurrent?.fullname || foundItinerary?.name;
      clientInfoUpdate.fullname = authUserFullName;
      if (selectedUserIdx === null) {
        clientInfoUpdate.id = newClientInfo?.id ?? (isCurrent?.id || null);
      }
      friendlyName = foundItinerary?.friendlyName || friendlyName;
    }
    if (selectedFriendlyName !== null && selectedFriendlyName !== "") updateTravellerNameRequest((isUserAuthenticated ? authUserFullName : clientInfoUpdate.fullname), friendlyName, clientInfoUpdate.id);

    clientInfoUpdate.friendlyName = friendlyName;
    setNewClientInfo(clientInfoUpdate);
  };

  const userSelectForm = (
    <div className="user-select">
      <div className="header">{t('select_client_dialog_title', { lng: itin.language })}</div>
      <form
        id="user-select-form"
        onSubmit={(e) => onFormSubmit(e, isPortalPreview ? null : undefined)}
      >
        {
          itin.clients.map((x, i) => (
            <div className="user-option" key={i}>

                <input
                  id={`user-select-${i}`}
                  type="radio"
                  value={i}
                  checked={i === selectedUserIdx}
                  onChange={(e) => setSelectedUserIdx(parseInt(e.target.value))}
                />
              <label htmlFor={`user-select-${i}`}>
                {x.fullname}
              </label>
            </div>
          ))
        }

      </form>
      <div className="buttons">
        <button disabled={selectedUserIdx === null} type="submit" form="user-select-form">{t('ok', { lng: itin.language })}</button>
      </div>
    </div>
  );

  const friendlyNameSetForm = (
    <div className="friendly-name-set">
      <div className="header">{t('select_friendly_name_dialog_title', { lng: itin.language })}</div>
      <div className="message">{t('select_friendly_name_message', { lng: itin.language })}</div>
      <form
        id="friendly-name-set-form"
        onSubmit={(e) => onFormSubmit(e)}
      >
        <input
          type="text"
          placeholder={t('friendly_username', { lng: itin.language })}
          value={selectedFriendlyName}
          autoFocus={true}
          onChange={(e) => setSelectedFriendlyName(e.target.value)}
        />
      </form>
      <div className="buttons">
        <button onClick={(e) => onFormSubmit(e, null)}>{t('skip', { lng: itin.language })}</button>
        <button disabled={!selectedFriendlyName} type="submit" form="friendly-name-set-form">{t('OK', { lng: itin.language })}</button>
      </div>
    </div>
  );

  if (!openUserSelectModal && !openFriendlyNameSetModal) return null;
  return (
    <ModalDefault
      isOpen={openUserSelectModal || openFriendlyNameSetModal}
      className="user-select-modal"
    >
        { openUserSelectModal ? userSelectForm : friendlyNameSetForm }
    </ModalDefault>
  );
};

export default UserSelect;
