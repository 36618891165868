import { useContext, useLayoutEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Context from '../../context';
import Container from "../global/Container";
import './index.scss';

const Summary = ({ type }) => {
  const [closePath, setClosePath] = useState(null);
  const { itin, viewMode, customHistory, t } = useContext(Context);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useLayoutEffect(() => {
    if (type !== 'gallery') return;

    let newClosePathVal = null;

    // Find closest non-daily path
    let i = 0;
    let target;
    while (true) {
      target = customHistory[customHistory.length + i - 1];
      if (!target) {
        newClosePathVal = `/${itin.localData.urlKey}`;
        break;
      }
      if (
        (!target.match(new RegExp(`^/${itin.localData.urlKey}/gallery`, 'i')))
      ) {
        newClosePathVal = i;
        break;
      }
      i--;
    }
    setClosePath(() => newClosePathVal);
  }, [pathname, customHistory, viewMode, itin.localData?.urlKey, type]);

  useLayoutEffect(()=>{
    //to allow the user scroll through the /summary section using the arrows or the Page Up and Page Down buttons
    if (viewMode === 'desktop') {
      document.querySelector(".summary-tile")?.focus();
    }
  }, [viewMode])

  const generateTiles = () => {
    let currDay = 0;
    return itin.brief.map((x, i) => {
      let showDayNum = false;
      if (!itin.hideDays && x.day !== currDay && !x.hideDayInfo) {
        showDayNum = true;
        currDay = x.day;
      }

      const style = {};
      if (!x.dailyPhotoNode?.file?.httpsUrl && !itin.photoNode?.file?.httpsUrl) style.backgroundColor = '#2E2F2E';
      else style.backgroundImage = `url("${x.dailyPhotoNode?.file?.httpsUrl || itin.photoNode?.file?.httpsUrl}")`;

      const onKeyPressed = (event) => {
        if (event.key === 'Enter') {
          navigate(`/${itin.localData.urlKey}/${type === 'gallery' ? 'gallery' : 'daily'}/${i+1}`);
        }
      }

      return (
        <div
          key={`summary-tile-${x.id}-${i}`}
          className="summary-tile tile-grid-tile"
          style={style}
          onClick={() => navigate(`/${itin.localData.urlKey}/${type === 'gallery' ? 'gallery' : 'daily'}/${i+1}`)}
          tabIndex="0"
          onKeyDown={(event) => onKeyPressed(event)}
        >
          {
            type !== 'gallery' && (
              <div className="summary-tile-gradient">
                <div className="summary-tile-name">{x.headline}</div>
                <div className="summary-tile-day">{showDayNum ? t('day_format', { lng: itin.language}).replace('%d', x.day) : ''}</div>
              </div>
            )
          }
        </div>
      );
    });
  };

  return (
    <Container
      className="container-summary"
      mainElementClassName={`summary summary-${viewMode} summary-${type}`}
      closePath={closePath}
    >
      {
        generateTiles()
      }
    </Container>
  )
};

export default Summary;
