import { useContext } from 'react';
import Context from '../../../context';
import './Button.scss';

const Button = ({ className, children, outline, coloredText, isPortalPreview, ...props}) => {
  const { composerData } = useContext(Context);


  return (
    <button
      className={`button ${className ? className : ''} ${outline ? 'outline' : ''} ${coloredText ? 'color' : ''} ${composerData?.settings?.button_square_corners ? 'button-square-corners' : ''} ${isPortalPreview ? 'not-allowed' : ''}`}
      {...props}
    >
      {children}
    </button>
  );
};

export default Button;
