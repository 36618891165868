import { useContext, useEffect } from 'react';
import Context from '../../context';
import './StaysDatesScreen.scss';
import SlideTemplate from './SlideTemplate';

const StaysDatesScreen = ({ staysDates, setStaysDates, isActive, skippable, logoOverride, id, submitFn, skipFn }) => {
    const { t, composerData } = useContext(Context);

    //The date format on the frontend should be yyyy-mm-dd but for the backend we need to send reversed format dd-mm-yyyy
    //The format changes in the file \src\components\Login\index.js before sending to the backend
    const buildDateString = date => {
        const month = date.getMonth() + 1;
        const day = date.getDate();
        return `${date.getFullYear()}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;
    };

    useEffect(() => {
        setStaysDates(() => {
            if (!isActive) return null;
            const today = new Date();
            const tomorrow = new Date(today);
            tomorrow.setDate(tomorrow.getDate() + 1);
            return { arrival: buildDateString(today), departure: buildDateString(tomorrow) };
        });
    }, [isActive, setStaysDates]);

    if (!staysDates) return null;

    return (
        <SlideTemplate
            className="stays-personal-info-screen"
            text3={t('login_form_dates_header_text')}
            inputList={[
                {
                    className: "name-input",
                    value: staysDates.arrival,
                    type: 'date',
                    onChange: val => setStaysDates(staysDates => ({ ...staysDates, arrival: val })),
                    placeholder: t('login_form_dates_start_hint'),
                },
                {
                    className: "email-input",
                    value: staysDates.departure,
                    type: 'date',
                    onChange: val => setStaysDates(staysDates => ({ ...staysDates, departure: val })),
                    placeholder: t('login_form_dates_end_hint')
                }
            ]}
            button={{
                disabled: !staysDates.arrival || !staysDates.departure,
                text: composerData?.strings?.login_next_button?.text || t('login_next_button'),
            }}
            skipFn={skippable ? skipFn : false}
            text5={t('login_form_dates_footer_text')}
            isActive={isActive}
            submitFn={submitFn}
            logoOverride={logoOverride}
            id={id}
        />
    );
};

export default StaysDatesScreen;
