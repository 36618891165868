import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Context from "../../context";
import PostsScreen from '../Posts';
import './Posts.scss';

const SEGUE = 'Posts';

const Posts = () => {
  const [data, setData] = useState(null);
  const [menuLabel, setMenuLabel] = useState(null);
  const { itin } = useContext(Context);
  const { slideNum } = useParams();

  useEffect(() => {
    const dayData = itin.brief[parseInt(slideNum)-1];
    setData(dayData);
    setMenuLabel(dayData.menu.find(x => x.label === SEGUE)?.label);
  }, [slideNum, itin.brief]);

  if (!data) return <div>Loading</div>;

  return <PostsScreen type="daily" dayData={data} menuLabel={menuLabel} />
};

export default Posts;