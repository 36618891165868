import React, { useContext } from 'react';
import Context from '../../context';
import './UserIdScreen.scss';
import SlideTemplate from "./SlideTemplate";

const UserIdScreen = ({ operatorCode, setOperatorCode, isActive, hideContent, id, submitFn }) => {

  const { t, composerData } = useContext(Context);

  return (
    <SlideTemplate
      className="user-id-screen"
      text1={composerData?.strings?.login_text_1?.text ?? t('login_text_1')}
      text2={composerData?.strings?.login_text_2?.text ?? t('login_text_2')}
      text3={composerData?.strings?.login_text_3?.text ?? t('login_text_3')}
      text4={composerData?.strings?.login_text_4?.text ?? t('login_text_4')}
      text5={composerData?.strings?.login_text_5?.text ?? t('login_text_5')}
      text6={localStorage.getItem("userEmail") ? `${t('auth_logged_email_desc').replace('%s', localStorage.getItem("userEmail"))}` : ''}
      text7={t('auth_message_empty_login_list_line_two')}
      inputList={[{
        key: 0,
        value: operatorCode,
        onChange: val => setOperatorCode(val),
        placeholder: composerData?.strings?.login_user_id_hint?.text || t('login_user_id_hint'),
        className: 'user-id-input',
        autoFocus: true,
      }]}
      button={{
        disabled: !operatorCode,
        text: composerData?.strings?.login_next_button?.text || t('login_next_button'),
        // onClick: onOperatorSubmit
      }}
      isActive={isActive}
      submitFn={() => submitFn(operatorCode)}
      hideContent={hideContent}
      id={id}
    />
  );
};

export default UserIdScreen;