import { useContext } from 'react';
import './index.scss';
import DocumentList from '../global/DocumentList';
import Container from "../global/Container";
import Context from '../../context';

const Documents = ({ type }) => {
    const { itin, viewMode } = useContext(Context);

    return (
      <Container className="container-documents" mainElementClassName={`documents documents-${viewMode}`}>
          <DocumentList section={type} data={itin[`${type}Documents`]} />
      </Container>
    )
};

export default Documents;
