import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import './MapNavigation.scss';

const MapNavigationButton = ({ active, variant, children, imgSrc, label, hideTick, ...props }) => {

  return (
    <div
      className={`map-navigation-button map-navigation-button-${variant} ${active ? 'active' : '' }`}
      {...props}
    >
      {children && children}
      <div className="img-container">
        <img src={imgSrc} alt="" />
        { !hideTick && <DoneOutlinedIcon /> }
      </div>
      <div className="label">{label}</div>
    </div>
  );
};

export default MapNavigationButton;