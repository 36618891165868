import { useState, useEffect, useCallback } from "react";
import appConfig from '../config/app';
import { request } from '../libraries/api';

const useApiRequest = (url, { method = 'GET', transform = null, headers = null, useVamoosHeaders = false } = {}) => {
    const [ response, setResponse ] = useState(null);
    const [ data, setData ] = useState(null);
    const [ error, setError ] = useState(null);
    const [ isLoading, setIsLoading ] = useState(true);

    useEffect(() => {
        const params = {
            method,
            headers: headers || {}
        };

        request(url, params, useVamoosHeaders)
          .then(({res, data, error}) => {
            if (res) setResponse(res);
            if (data) setData(transform ? transform(data) : data);
            const e = error ? error :
              !res.ok ? res : null;
            if (e) setError(e);
            setIsLoading(false);
          })
    }, [url, method, useVamoosHeaders, transform, headers]);
    return { data, response, error, isLoading };
}

const useVamoosApi = (path, args = {}) => {
    args.useVamoosHeaders = true;
    return useApiRequest(appConfig.apiHost + path, args);
}

export const useItinerary = (operatorCode, referenceCode = 'DEFAULT') =>
    useVamoosApi('/api/itineraries/' + operatorCode + '-' + referenceCode);

export const useJournal = (operatorCode, referenceCode = 'DEFAULT') =>
  useVamoosApi(`/api/itineraries/${operatorCode}-${referenceCode}/journal`);

export const useIcons = () => {
    const transform = useCallback(data =>
      data.items.reduce((out, { id, url }) => {
            out[id] = url;
            return out;
        }, {}
      ), []);

    return useVamoosApi('/icon/public?count=500', { transform });
};

export const useWeather = (lat, lon) =>
    useApiRequest(`${appConfig.openWeatherMapHost}/forecast?appid=${appConfig.openWeatherMapApiKey}&units=metric&lat=${lat}&lon=${lon}`);


