import { useContext, useEffect, useRef } from "react";
import Context from '../../context';
import './PoiTypeOptionsModal.scss';
import DoneIcon from "@mui/icons-material/Done";

const PoiTypeOptionsModal = ({ poiTypeItems, togglePoiType, anyPoiTypeActive, allPoiTypeActive, togglePoiCategory, id, modalPosition, setTrackListWidth }) => {
  const { t, itin } = useContext(Context);
  const modalRef = useRef(null);

  useEffect(()=>{
    if (!modalRef) return;
    setTrackListWidth(modalRef.current.offsetWidth);
  }, [setTrackListWidth, modalRef])

  const renderModalInner = () => {
    return (
      <ul className="track-list"
          ref={modalRef}
          style={modalPosition()}>
        {
          poiTypeItems.map((t, ii) => {
            return (
              <li key={ii} onClick={() => togglePoiType(!t.active, t)}>
                {anyPoiTypeActive && <span className={`tick ${t.active ? 'active' : ''}`}><DoneIcon /></span>}
                <span className="track-name">{t.infoLabel}</span>
              </li>
            )
          })
        }
        {poiTypeItems.length > 1 && (
          <li
            className="toggle-all"
            key="toggle-all"
            onClick={() => togglePoiType(!allPoiTypeActive, null, poiTypeItems[0].iconId)}
          >
            {/* TODO translation */}
            {t( allPoiTypeActive ? 'maps_hide_all_tracks' : 'maps_show_all_icon_title', { lng: itin.language })}
          </li>
        )}
      </ul>
    );
  };

  return (
    <>
      <div
        className="track-list-modal-background"
        onClick={event => togglePoiCategory(event, id)}
      />
      {renderModalInner()}
    </>
  );
};

export default PoiTypeOptionsModal;
