import { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import Context from '../../../context';
import './index.scss';

const DocumentList = props => {
  const [activeIdx, setActiveIdx] = useState(null);
  const { viewMode } = useContext(Context);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => () => setActiveIdx(null), [props.section]);

  const onKeyPressed = (event, doc, index) => {
    if (event.key === 'Enter') {
      setActiveIdx(index)
      if (doc.attachment.fileUrl) {
        if (viewMode === "mobile") {
          navigate(`${location.pathname}/view/${doc.id}`);
        } else {
          window.open(doc.attachment.fileUrl, '_blank');
        }
      } else if (doc.attachment.webpageUrl) window.open(doc.attachment.webpageUrl, '_blank');
    }
  }

  const handleSpanClick = (doc, event) => {
    event.preventDefault();
    navigate(`${location.pathname}/view/${doc.id}`);
  };

  return (
    <div className={`document-list document-list-${viewMode}`}>
      <ul>
        {
          props.data.map((x, i) => (
            <li
              className={i === activeIdx ? 'active' : ''}
              key={`${props.section}-document-${i}`}
              tabIndex="0"
              onKeyDown={(event) => onKeyPressed(event, x, i)}
            >
              {
                (viewMode === "mobile" && x.attachment?.fileUrl) ? (
                  <>
                    <span
                      onClick={(e) => handleSpanClick(x, e)}
                      style={{ cursor: 'pointer' }}
                    >
                      {x.name}
                    </span>
                  </>
                ) : (
                  <a
                    onClick={() => setActiveIdx(i)}
                    href={x.attachment.fileUrl || x.attachment.webpageUrl}
                    target="_blank"
                    rel="noreferrer"
                    tabIndex="-1"
                  >
                    <span>{x.name}</span>
                    <img src="/icons/chevron-down.png" alt="" />
                  </a>
                )
              }
            </li>
          ))
        }
      </ul>
    </div>
  );
};

export default DocumentList;