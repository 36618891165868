import { useContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Context from '../../context';
import './Slide.scss';
import Navbar from "../global/Navbar";
import Home from '../Home';
import DailyFlights from "./Flights";
import DailyPosts from "./Posts";
import DailyMap from "./Map";
import DailyWeather from "./Weather";
import DailySummary from "./Summary";

const Slide = ({ pathStart, homeComponent, type, isActive, ...props}) => {
  const [activeSection, setActiveSection] = useState('summary');
  const [style, setStyle] = useState({ backgroundColor: '#2E2F2E' });
  const { data, slideNum } = props;
  const { itin, icons, viewMode, t, mobileViewGeneric, previewMode } = useContext(Context);

  const location = useLocation();
  useEffect(() => {
    let isActive = true;
    if (mobileViewGeneric) {
      setStyle({ backgroundColor: 'white' });
    } else {
      if (!data?.dailyPhoto && !itin?.photoNode?.file?.httpsUrl) return;
      const img = new Image();
      img.onload = () => isActive && setStyle({ backgroundImage: `url("${img.src}")` });
      img.src = data?.dailyPhoto || itin?.photoNode?.file?.httpsUrl;
    }

    return () => {
      isActive = false;
    }
  }, [data?.dailyPhoto, homeComponent, itin?.photoNode?.file?.httpsUrl, mobileViewGeneric, previewMode]);

  useEffect(() => {
    setActiveSection(() => {
      if (type === 'gallery') return 'summary';
      if (previewMode && homeComponent) return 'home';
      const [,,,,section] = location.pathname.split('/');
      return section ? section.toLowerCase() : 'summary'
    });
  }, [type, homeComponent, location.pathname, previewMode]);

  const menuItemOptions = {
    Accommodation: { type: 'external' },
    Flights: {
      path: `${pathStart}/${slideNum}/flights`,
      sectionName: 'flights',
      element: <DailyFlights />
    },
    Details: { type: 'external' },
    Posts: {
      path: `${pathStart}/${slideNum}/posts`,
      sectionName: 'posts',
      isOffField: 'hideJournal',
      enabled: !previewMode,
      element: <DailyPosts />
    },
    Maps: {
      path: `${pathStart}/${slideNum}/maps`,
      sectionName: 'maps',
      dataField: 'location',
      dataFieldValidationField: 'showInMaps',
      element: <DailyMap />
    },
    Weather: {
      path: `${pathStart}/${slideNum}/weather`,
      sectionName: 'weather',
      dataField: 'location',
      dataFieldValidationField: 'showInWeather',
      element: <DailyWeather />
    }
  };

  const navMenuItems = [{
    path: `${pathStart}/${slideNum}`,
    title: t('summary', { lng: itin.language }),
    sectionName: 'summary',
    icon: <img className="nav-item-icon" id="nav-item-summary-icon" src="/icons/summary.svg" alt=""/>,
    element: <DailySummary type={location.pathname.includes('gallery') ? 'gallery' : 'storyboard'}
                           slideData={data}/>
  }];

  const renderNavbar = () => {
    if (!Array.isArray(data?.menu) || type === 'gallery') return null;
    if (previewMode && homeComponent) return null;

    const generateNavbar = () => {
      for (const { label, segue, iconId, segueId } of data.menu) {
        if (!menuItemOptions[segue]) throw new Error('Menu Item Not Found: ' + segue);

        const {
          enabled,
          path,
          sectionName,
          type,
          isOffField,
          dataField,
          dataFieldValidationField,
          element
        } = menuItemOptions[segue];
        if (enabled === false) continue;
        if (isOffField && itin[isOffField]) continue;
        if (dataField && dataFieldValidationField && !data[dataField][dataFieldValidationField]) continue;

        const icon = <img className="nav-item-icon" alt="" src={icons[iconId]} />
        const item = {
          title: label,
          icon,
          sectionName,
          type,
          path,
          element
        };

        if (segue === 'Details') {
          const doc = data.documents.find(x => x.id === segueId);

          let forceExternal = false;

          if (doc?.attachment?.fileUrl) {
            const fileUrl = new URL(doc.attachment.fileUrl);
            const isPdf = fileUrl.pathname.endsWith(".pdf");
            
            if (isPdf) {
              const isSafari = navigator?.userAgent?.match(/safari/i) && navigator?.vendor?.match(/apple/i) && navigator?.maxTouchPoints;
              forceExternal = isSafari;
            }
          }
          
          if (viewMode === "mobile" && doc?.attachment?.fileUrl && !forceExternal) {
            delete item.type;
            item.path = `${pathStart}/${slideNum}/view/${doc?.id}`
          } else {
            item.type = 'external';
            item.path = doc?.attachment?.fileUrl || doc?.attachment?.webpageUrl;
          }
        } else if (segue === 'Accommodation') {

          const generateAccomodationPath = () => {
            if (!data.location.nesting) {
              if (!data.location.pdfNode?.file) return data.location.pdfNode?.remoteUrl;
              if (viewMode === 'desktop') return data.location.pdfNode?.file?.httpsUrl;

              item.type = undefined;
              return `${pathStart}/${slideNum}/view/${data.location.id}`;
            }

            if (!previewMode) {
              if (viewMode !== 'desktop') item.type = undefined;
              return viewMode === 'desktop' ? `${window.location.origin}/${itin.id}-${data.location.nesting.vamoosId}` : `${pathStart}/${slideNum}/maps/${data.location.id}`;
            }

            let path = window.location.origin + '/preview/';
            if (data.location.nesting.previewLinkId) path += data.location.nesting.previewLinkId;
            else path += btoa(`${data.location.nesting.operatorCode}-${data.location.nesting.referenceCode}`);
            path += '?branding=' + itin.operatorInfo.code;

            return path;
          };
          item.path = generateAccomodationPath();
          if (!item.path) continue;
        }

        navMenuItems.push(item);
      }
      if (viewMode !== 'desktop' && activeSection !== 'summary') return;

      const activeIdx = navMenuItems.findIndex(x => x.sectionName === activeSection);

      return (
        <Navbar
          className="daily-navbar"
          items={navMenuItems}
          activeIdx={activeIdx === -1 ? 0 : activeIdx}
        />
      )
    }

    if (viewMode !== 'desktop' && activeSection !== 'summary') return generateNavbar();

    return (
      <div className="daily-navbar-container">
        {data?.menu?.length ? generateNavbar() : null}
      </div>
    );
  };

  const renderContent = () => {
    if (!isActive && (activeSection !== 'summary' && activeSection !== 'home')) return null;
    const activeContent = navMenuItems.find(x => x.sectionName === activeSection);
    return previewMode && homeComponent ? <Home /> : activeContent?.element;
  };

  return (
    <div
      className={`slide slide-${viewMode} ${slideNum !== 0 && activeSection !== 'summary' ? 'darkened' : ''} slide-${type}`}>
      { renderNavbar() }
      <div className="slide-background" style={style} />
      <div className={`slide-content slide-content-${activeSection} ${props.previewWithLogo ? 'slide-content-preview-withlogo' : ''} ${previewMode ? '' : `slide-content-${type}`}`}>
        <div className={`slide-content-centered slide-content-centered-${activeSection} ${previewMode ? 'preview-mode' : ''}`}>
          {
             renderContent()
          }
        </div>
      </div>
    </div>
  );
};

export default Slide;
