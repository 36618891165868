import React from 'react';
import { useItinerary } from '../hooks/api';
// import {Menu} from 'semantic-ui-react';
// import { createBrowserHistory } from 'history';

import {
    // BrowserRouter as Router,
    // Switch,
    // Route,
    Link
} from "react-router-dom";

export function Header() {
    return (
        <header>
            <h1>Header</h1>
            <ul id="menu">
                <li><Link to="/">Home</Link></li>
                <li><Link to="/about">About</Link></li>
            </ul>
        </header>
    );
}


export function Footer() {
    return (
        <footer>
            <hr/>
            Footer
        </footer>
    );
}

export function LoadingScreen() {
    let { data, error, isLoading } = useItinerary('NEDREGAARD', 'DEBUG');

    React.useEffect(() => {
        console.log('useEffect', { data, error, isLoading });
    }, [data, error, isLoading]);

    // console.log('temp', { data, error, isLoading });

    if (isLoading) {
        return ( <div>Loading...</div>);
    }
    if (error) {
        return ( <div>Failed</div> );
    }

    return (
        <div>
            {data.operatorCode}-{data.referenceCode}
        </div>
    );
}

export function NotFoundScreen() {
    return (
        <div>
            Page not found
        </div>
    );
}