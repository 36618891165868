import { useState, useEffect, useRef, useContext, useCallback } from 'react';
import Context from '../../context';
import PoiCarouselSlide from './PoiCarouselSlide';
import Carousel from '../global/CarouselNG'
import './PoiCarousel.scss';
import { useLocation, useParams } from "react-router-dom";
import Button from "../global/Buttons/Button";

const PoiCarousel = ({ data, activeItem, setEmptyContainerHeight, type, slideNum, disableSwipe }) => {
  const [slides, setSlides] = useState([]);
  const nameLabel = useRef();
  const arrowLeft = useRef();
  const arrowRight = useRef();

  const { itin: itinerary, t } = useContext(Context);

  const { pathname } = useLocation();
  const { previewLinkId } = useParams();

  const generateSlideLinkStart = useCallback(() => {
    let link = (!previewLinkId && type === 'daily')
      ? `/${itinerary.localData?.urlKey}/daily/${slideNum}/maps`
      : `/${itinerary.localData?.urlKey}/maps`
    if (previewLinkId) {
      const linkStart = `/${pathname.match(new RegExp(`^/(preview.*)/${previewLinkId}`))[1]}`;
      if (linkStart === '/preview-maps') {
        link = `${linkStart}/${previewLinkId}`;
      } else {
        link = `${linkStart}/${previewLinkId}/${slideNum}/maps`;
      }
    }
    return link
  }, [itinerary.localData?.urlKey, pathname, previewLinkId, slideNum, type])

  useEffect(() => {
    setSlides(data.map((x, i, arr) => (
      <PoiCarouselSlide key={i}
                        numSlides={arr.length}
                        slideData={x}
                        link={`${generateSlideLinkStart()}/${x.id}`}
                        setEmptyContainerHeight={setEmptyContainerHeight}
                        slideId={arr[i].id}
                        slideNum={slideNum}/>)));
  }, [slideNum, data, setEmptyContainerHeight, generateSlideLinkStart]);

  const updateInfo = (index) => {
    const item = data[index];
    if (!item) return;
    if (nameLabel?.current) nameLabel.current.innerText = item.infoLabel;
  };

  return (
    <div className="poi-carousel">
      <Carousel
        slides={slides}
        currentSlide={slides.find(x => x.props.slideData.id === activeItem.id)}
        onChange={updateInfo}
        arrowLeftDelegate={arrowLeft.current}
        arrowRightDelegate={arrowRight.current}
        disableSwipe={disableSwipe}
      />
      <div className="carousel-navigation-container">
        <div className="carousel-navigation">
          <span id='arrow-left' ref={arrowLeft}>
            <img src="/icons/arrow_left_small_dark.svg" width="40" height="40" alt="LEFT"/>
          </span>
          {activeItem?.infoLabel
            ? <span className="poi-name" ref={nameLabel}>{activeItem.infoLabel}</span>
            : <Button
              className="contact-button"
              onClick={() => window.open(`${window.location.origin}/${itinerary.id}-${activeItem.nesting.vamoosId}`)}
            >
              {t('more_details', { lng: itinerary.language })}
            </Button>
          }
          <span id='arrow-right' ref={arrowRight}>
            <img src="/icons/arrow_right_small_dark.svg" width="40" height="40" alt="RIGHT"/>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PoiCarousel;
