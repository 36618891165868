import { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import Context from '../../context';
import './index.scss';
import Container from "../global/Container";
import FlightListItem from "./FlightListItem";
import FlightDetails from "./FlightDetails";
import LoadingScreen from '../global/LoadingScreen';

const Flights = ({ flightIds, ...props }) => {
  const [flightData, setFlightData] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const [flightDetailsData, setFlightDetailsData] = useState(null);
  const [mobileDetailsMode, setMobileDetailsMode] = useState(null);

  const { itin, viewMode, setScreenNameOverride, deviceLanguage } = useContext(Context);
  const params = useParams();

  useEffect(() => {
    let fltdta = itin.flightAlerts;
    if (flightIds) fltdta = fltdta.filter(f => flightIds.includes(f.id));
    fltdta = fltdta.sort((a, b) => a.flights[0].departure.scheduled - b.flights[0].departure.scheduled);
    setFlightData(fltdta);
  }, [flightIds, itin.flightAlerts]);

  useEffect(() => {
    if (!flightData) return;
    const selectedFlightId = params.id !== undefined ? parseInt(params.id)
      : viewMode === 'desktop' ? flightData[0].id
        : null;
    setSelectedId(selectedFlightId);
    setFlightDetailsData(selectedFlightId ? flightData.find(f => f.id === selectedFlightId) : flightData[0]);
  }, [flightData, params.id, viewMode]);

  useEffect(() => {
    if (!flightDetailsData) return;
    let mobDetailsMode = false;
    let mobListMode = false;
    if (viewMode === 'mobile') {
      if (flightData?.length === 1 || selectedId) mobDetailsMode = true;
      else mobListMode = true;
    }
    setMobileDetailsMode(mobDetailsMode);

    setScreenNameOverride(
      mobDetailsMode
        ? flightDetailsData.flights[0].departure.cityName + ' - ' + flightDetailsData.flights[0].arrival.cityName
          : !!(mobListMode && props.menuLabel)
            ? props.menuLabel
              : null
    );

    return () => setScreenNameOverride(null);
  }, [flightData?.length, flightDetailsData, props.menuLabel, selectedId, setScreenNameOverride, viewMode]);

  const formatDate = date => date.toLocaleDateString([itin.language || deviceLanguage], { day: 'numeric', month: 'short', year: 'numeric'});
  const formatTime = time => time.toLocaleTimeString([itin.language || deviceLanguage], { timeStyle: 'short' });

  const renderContent = () => {
    return (
      <>
        <ul className="flight-list-items-container">
           {
             flightData.map(f => (
               <FlightListItem
                 data={f}
                 key={f.id}
                 isSelected={flightData.length === 1 ? true : f.id === selectedId}
                 formatDate={formatDate}
                 formatTime={formatTime}
               />
             ))
           }
        </ul>
        <div className="flight-details-container">
          <FlightDetails
            data={selectedId ? flightData.find(f => f.id === selectedId) : flightData[0]}
            formatDate={formatDate}
            formatTime={formatTime}
          />
        </div>
      </>
    );
  };

  if (!flightData || !flightDetailsData || mobileDetailsMode === null) return <LoadingScreen />

  if (props.type === 'daily') {
    return (
      <div className={`flights daily-flights flights-${viewMode} ${mobileDetailsMode ? 'mobile-details-mode' : '' }`}>
        {renderContent()}
      </div>
    );
  }

  return (
    <Container
      className="container-flights"
      mainElementClassName={`flights flights-${viewMode} ${mobileDetailsMode ? 'mobile-details-mode' : '' }`}
    >
      {renderContent()}
    </Container>
  );
};

export default Flights;
