import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";
import './index.scss';
import { ScrollButton } from "../Buttons/ScrollButtons";
import useKeypress from "../../../hooks/useKeypress";

const Carousel = ({
  slides,
  // prevBtnDisabled,
  // nextBtnDisabled,
  // handleChange,
  // mobileScrollButtonsTop,
  currentSlide,
  // embeddedCarouselMode,
  onChange,
  arrowLeftDelegate, arrowRightDelegate,
  disableSwipe,
}) => {
  const [touchValuesX, setTouchValuesX] = useState({ start: 0, end: 0 });
  const [activeSlide, setActiveSlide] = useState(currentSlide);
  const [displayArrowLeft, setDisplayArrowLeft] = useState(false);
  const [displayArrowRight, setDisplayArrowRight] = useState(false);
  const carousel = useRef();
  const carouselContainer = useRef();
  const arrowLeft = useRef();
  const arrowRight = useRef();
  const navigate = useNavigate();
  const useNavigation = () => {
    const navigateTo = (destination) => {
      navigate(destination);
    };
    return { navigateTo };
  };
  const navigation = useRef(useNavigation());

  // Temp
  const prevBtnDisabled = false, nextBtnDisabled = false, handleChange = undefined,
    mobileScrollButtonsTop = undefined, embeddedCarouselMode = undefined;

  const navigateSlides = useCallback((direction) => {
    if (handleChange) {
      handleChange(direction === -1 ? 'prev' : 'next');
      return;
    }
    if (!activeSlide) return;

    const currIdx = slides.indexOf(activeSlide);

    const newSlide = slides[currIdx+direction];
    if (slides?.[currIdx+direction]) setActiveSlide(newSlide);
    if (newSlide?.props?.link) {
      navigation.current.navigateTo(newSlide.props.link);
    }

  }, [activeSlide, handleChange, slides]);


  useEffect(() => {
    if (carouselContainer.current) {
      carousel.current.addEventListener('scroll', e => {
        const me = e.target;
        const pageSize = me.clientWidth;
        me.scrollLeft = Math.floor((me.scrollLeft + pageSize / 2) / pageSize) * pageSize;

        e.stopPropagation();
        e.preventDefault();
        return false;
      });
      carouselContainer.current.addEventListener('touchstart', e => setTouchValuesX(vals => ({ ...vals, start: e.changedTouches[0].screenX })), {passive: true});
      carouselContainer.current.addEventListener('touchend', e => setTouchValuesX(vals => ({ ...vals, end: e.changedTouches[0].screenX })), {passive: true});
    }
  }, [carouselContainer]);

  useEffect(() => {
    setActiveSlide(currentSlide);
  }, [currentSlide]);

  // Set carousel offset based on activeSlide positioning
  useEffect(() => {
    if (!activeSlide) return;
    const page = slides.indexOf(activeSlide);

    if (page > -1) carousel.current.scrollLeft = carousel.current.clientWidth * page;
    setDisplayArrowLeft(page > 0);
    setDisplayArrowRight(page < slides.length - 1);
    onChange?.(page);
  }, [activeSlide, onChange, slides]);

  useEffect(() => {
    if (disableSwipe) return;
    const threshold = 50;
    if (!nextBtnDisabled && (touchValuesX.start - touchValuesX.end >= threshold)) navigateSlides(+1);
    else if (!prevBtnDisabled && (touchValuesX.end - touchValuesX.start >= threshold)) navigateSlides(-1);
    setTouchValuesX({ start: 0, end: 0 });
  }, [disableSwipe, navigateSlides, nextBtnDisabled, prevBtnDisabled, touchValuesX.end, touchValuesX.start]);

  useEffect(() => {
    if (!arrowLeft.current || !arrowLeftDelegate) return;
    arrowLeftDelegate.addEventListener('click', () => arrowLeft.current.click());
  }, [arrowLeftDelegate, arrowLeft]);

  useEffect(() => {
    if (!arrowLeft.current || !arrowRightDelegate) return;
    arrowRightDelegate.addEventListener('click', () => arrowRight.current.click());
  }, [arrowRightDelegate, arrowLeft]);

  useEffect(() => {
    if (!arrowLeftDelegate) return;
    arrowLeftDelegate.style.visibility = displayArrowLeft ? 'visible' : 'hidden';
  }, [arrowLeftDelegate, displayArrowLeft]);

  useEffect(() => {
    if (!arrowRightDelegate) return;
    arrowRightDelegate.style.visibility = displayArrowRight ? 'visible' : 'hidden';
  }, [arrowRightDelegate, displayArrowRight]);

  useKeypress('ArrowLeft', [arrowLeftDelegate], () => {
    if (!arrowLeftDelegate) return;
    navigateSlides(-1);
  })

  useKeypress('ArrowRight', [arrowRightDelegate], () => {
    if (!arrowRightDelegate) return;
    navigateSlides(+1);
  })

  return (
    <div
      className={`carousel-container-ng ${mobileScrollButtonsTop ? 'mobile-scroll-buttons-top' : ''} ${embeddedCarouselMode ? 'embedded-carousel-mode' : ''}`}
      ref={carouselContainer}
    >
      <ScrollButton
        size="large"
        onClick={() => navigateSlides(-1)}
        className="left"
        direction="left"
        disabled={!!(arrowLeftDelegate || !displayArrowLeft)}
        ref={arrowLeft}
      />
      <div
        className="carousel"
        style={{ gridTemplateColumns: 'repeat(' + slides.length + ', 100%)' }}
        ref={carousel}
      >
        {slides}
      </div>
      <ScrollButton
        size="large"
        onClick={() => navigateSlides(+1)}
        className="right"
        direction="right"
        disabled={!!(arrowRightDelegate || !displayArrowRight)}
        ref={arrowRight}
      />
    </div>
  );
};

export default Carousel;
