import { useContext, useRef, useState } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import './index.scss';

import { Pagination, Navigation } from 'swiper/modules';
import { ScrollButton } from "../Buttons/ScrollButtons";
import Context from "../../../context";

const Slider = ({ data }) => {
  const { viewMode } = useContext(Context);

  const [disableArrowLeft, setDisableArrowLeft] = useState(true);
  const [disableArrowRight, setDisableArrowRight] = useState(false);

  const swiperArrowLeftRef = useRef(null);
  const swiperArrowRightRef = useRef(null);

  return (
    <>
      <Swiper
        slidesPerView={viewMode === "mobile" ? 1 : 3}
        centeredSlides={true}
        slideToClickedSlide={true}
        className="my-swiper"
        lazy='true'
        onInit={ (swiper) => {
          swiper.params.navigation.prevEl = swiperArrowLeftRef.current;
          swiper.params.navigation.nextEl = swiperArrowRightRef.current;
          swiper.navigation.init();
          swiper.navigation.update();
        } }
        grabCursor={ true }
        modules={ [Pagination, Navigation] }
        onSlideChange={ (swiper) => {
          const isAtFirstSlide = swiper.activeIndex === 0;
          const isAtLastSlide = swiper.activeIndex === data.length - 1;
          setDisableArrowLeft(isAtFirstSlide);
          setDisableArrowRight(isAtLastSlide);
        } }
      >
        { data?.map((item, index) => (
          <SwiperSlide key={ index }>
            <img src={ item.link } loading="lazy" alt={item.headline}/>
            <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
          </SwiperSlide>
        )) }
        <div className='scroll-button left'>
          <ScrollButton
            size={ viewMode === "mobile" ? "large" : "small" }
            color={ "dark" }
            className="left-btn"
            direction="left"
            ref={ swiperArrowLeftRef }
            hideScrollButtons={ disableArrowLeft }
          />
        </div>
        <div className='scroll-button right'>
          <ScrollButton
            size={ viewMode === "mobile" ? "large" : "small" }
            color={ "dark" }
            className="right-btn"
            direction="right"
            ref={ swiperArrowRightRef }
            hideScrollButtons={ disableArrowRight }
          />
        </div>
      </Swiper>
    </>
  );
};

export default Slider;