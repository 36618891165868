import { useEffect, useState, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Context from '../../context';
import './index.scss';
import Container from "../global/Container";
import Button from '../global/Buttons/Button';
import ModalDefault from '../global/Modal/ModalDefault';
import AddIcon from '@mui/icons-material/Add';
import { db } from "../../db";
import { removeItineraryFromLoginList } from "../../libraries/api";
import Spinner from "../global/Spinner";
import { getNewCurrentItinerary, setMenuTitle } from "../../helpers";

const ViewAll = () => {
  const [showedItineraries, setShowedItineraries] = useState([]);
  const [deleteItem, setDeleteItem] = useState({ vamoosId: null, id: null });
  const [closePath, setClosePath] = useState(null);
  const [spinner, setSpinner] = useState(false);
  const [storedItins, setStoredItins] = useState([]);

  const {
    itin,
    viewMode,
    t,
    deviceLanguage,
    customHistory,
    setLoginDetails,
    navMenuItems,
    setGlobalModalContent,
    isUserAuthenticated,
    userAuthInfo, setUserAuthInfo,
    userJustSignIn, setUserJustSignIn,
    showAppSpinner, isUpdatingUserAuthInfo
  } = useContext(Context);

  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (isUpdatingUserAuthInfo) return;
    const getStoredItineraries = async () => {
      const storedItineraries = await db.itineraries
        .orderBy('lastLogin')
        .reverse()
        .toArray();
      setStoredItins(storedItineraries);
    };

    getStoredItineraries();
  }, [isUpdatingUserAuthInfo]);

  useEffect(() => {
    return () => {
      setSpinner(false);
      setUserJustSignIn(false);
    };
  }, [setUserJustSignIn]);

  //for synchronization, to avoid unwanted requests for a new itinerary when the current itinerary was deleted
  useEffect(() => setLoginDetails(null), [setLoginDetails]);

  useEffect(() => {
    if ((isUserAuthenticated && !userAuthInfo) || isUpdatingUserAuthInfo) return;
    let isActive = true;
    let allItins;

    if (isUserAuthenticated) {
      allItins = [...userAuthInfo?.logins?.all];
    } else {
      const storedNonNestedItineraries= storedItins.filter(x => x.parentVamoosId === null || !x.parentVamoosId);
      allItins = [...storedNonNestedItineraries];
    }
    if (isActive) setShowedItineraries(allItins);

    return () => {
      isActive = false;
    };
  }, [isUserAuthenticated, storedItins, userAuthInfo, isUpdatingUserAuthInfo]);

  useEffect(() => {
    let newClosePath = null;

    let i = 0;
    let target;
    while(true) {
      target = customHistory[customHistory.length + i - 1];
      if (!itin) {
        newClosePath = `/login`;
        break;
      }
      if (!target) {
        newClosePath = `/${itin.localData.urlKey}`;
        break;
      }
      if (!target.match(new RegExp(`^/${itin.localData.urlKey}/view-all`, 'i'))) {
        newClosePath = i;
        break;
      }
      i--;
    }

    setClosePath(newClosePath);
  }, [customHistory, itin, pathname, viewMode]);

  //determine and set the most current itinerary depending on indexdedDB and login list
  const reorderItineraries = (itineraries) => {
    let reorderedItineraries = [...itineraries];
    if (isUserAuthenticated) {
      const storedNonNestedItineraries = storedItins.filter(x => x.parentVamoosId === null || !x.parentVamoosId);
      const targetIndex = itineraries.findIndex(item => userJustSignIn
        ? storedNonNestedItineraries.length === 0 ? item.id === userAuthInfo?.logins?.default?.id : item.id === storedNonNestedItineraries[0]?.vamoosId
        : item.id === storedNonNestedItineraries[0]?.vamoosId);
      if (targetIndex > -1) {
        const targetItem = reorderedItineraries.splice(targetIndex, 1)[0];
        reorderedItineraries.unshift(targetItem);
      }
    }
    return reorderedItineraries;
  }

  const renderDeleteModal = () => {
    const onClose = () => setDeleteItem({ vamoosId: null, id: null });
    const deleteItemFromLoginList = async (vamoosId) => {
      const { operatorCode, referenceCode } = userAuthInfo?.logins?.all.find(login => login.id === vamoosId);

      const { res, data, error } = await removeItineraryFromLoginList({
        operatorCode,
        referenceCode,
      });

      if (!res.ok || error) {
        setGlobalModalContent(() => ({
          closable: true,
          message: error || data?.error[0]?.message || data?.error || t("auth_error_delete_from_login_list"),
          buttons: [{ text: t('ok'), type: 'close' }]
        }));
        throw new Error(`Delete error: Res: ${res.status}, Error: ${error || data?.error[0]?.message || data?.error}, Data: ${JSON.stringify(data)}`);
      }
    }

    const onDelete = async item => {
      setSpinner(true);
      let deleteIds;
      let remaining;

      if (item.id !== null) { // item.id can be null if isUserAuthenticated === true
        const target = storedItins.find(x => x.id === item.id);

        // add all nested ids
        deleteIds = storedItins
          .filter(x => x.parentVamoosId === target?.vamoosId)
          .map(x => x.id);
        deleteIds.push(item.id);
        await db.itineraries.bulkDelete(deleteIds);
        const updatedStoredItins = storedItins.filter(itin => !deleteIds.includes(itin.id));
        setStoredItins(updatedStoredItins);
      }

      //remove itineraries from the user's list if user is auth
      if (isUserAuthenticated) {
        await deleteItemFromLoginList(item.vamoosId)
      }

      remaining = showedItineraries.filter(x => {
        if (x.vamoosId) {
          return !deleteIds?.includes(x.id);
        } else {
          return x.id !== deleteItem.vamoosId;
        }
      });
      //in case if user delete all itin
      if (remaining.length === 0) {
        navigate(`/login/1`, { replace: true });
      }

      //if current itinerary was deleted from login list switch to new most current itinerary
      const reorderedItineraries = reorderItineraries(showedItineraries);
      if (item.vamoosId === (reorderedItineraries[0].vamoosId || reorderedItineraries[0].id)) {
        //check if the deleted itinerary not the default in the login list and not the firs one in the logins.all array
        let { operatorCode, passcode, vamoosId } = getNewCurrentItinerary(item.vamoosId, userAuthInfo?.logins);

        if (!isUserAuthenticated) {
          operatorCode = remaining[0]?.operatorCode;
          passcode =  remaining[0]?.passcode || remaining[0]?.referenceCode;
        }
        if (operatorCode && passcode) {
          const authUserClientId = storedItins.find(itin => itin.vamoosId === vamoosId)?.client?.id;
          setLoginDetails({
            operatorCode,
            passcode,
            redirect: true,
            type: 'replace',
            clientId: isUserAuthenticated ? authUserClientId : remaining[0].client?.id,
            phase: isUserAuthenticated
              ? !!storedItins.find(itin => itin.vamoosId === vamoosId) ? 'refresh' : 'initial'
              : 'refresh',
          });
        }
      }

      //setUserAuthInfo to display correctly login items in hamburger menu
      if (isUserAuthenticated) {
        const remainingLogins = userAuthInfo?.logins?.all.filter(
          (login) => login.id !== item.vamoosId
        );
        const remainingUserAuthInfo = {
          ...userAuthInfo,
          logins: {
            ...userAuthInfo.logins,
            all: remainingLogins,
          },
        };
        setUserAuthInfo(remainingUserAuthInfo);
      }
      onClose();
      setShowedItineraries(remaining);
      setSpinner(false);
    }

    return (
      <ModalDefault
        isOpen={!!deleteItem.vamoosId || !!deleteItem.id}
        onBackdropClick={onClose}
        className="modal-global"
        header={t('delete_itinerary_title')}
        message={t('delete_itinerary_message')}
        buttons={[
          <button key={0} onClick={onClose}>{t('no')}</button>,
          <button key={1} onClick={() => onDelete(deleteItem)}>{t('yes')}</button>
        ]}
      />
    );
  };

  const generateDateStr = date => new Date(date).toLocaleDateString([deviceLanguage || 'en-GB'], {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    timeZone: 'UTC'
  });

  const generateTiles = () => {
    if (isUserAuthenticated && !userAuthInfo) {
      setGlobalModalContent(() => ({
        closable: true,
        message: t("auth_error_generate_tiles"),
        buttons: [{ text: t('ok'), type: 'close' }]
      }));
      return;
    }

    const deleteItem = async (item) => {
      if (item.hasOwnProperty("vamoosId")) {
        setDeleteItem({ vamoosId: item.vamoosId, id: item.id })
      } else {
        const itemInIndexedDb = await db.itineraries.where({ vamoosId: item.id }).toArray();
        setDeleteItem(itemInIndexedDb.length !== 0 ? {
          vamoosId: item.id,
          id: itemInIndexedDb[0].id
        } : { vamoosId: item.id, id: null })
      }
    }

    const reorderedItineraries = reorderItineraries(showedItineraries);
    return reorderedItineraries
      .map((x, i) => (
          <div
            className={`view-all-tile ${i === 0 ? 'active' : ''} tile-grid-tile`}
            key={i}
          >
            <div className="text-content">
              <div className="date">
                {
                  x.type === 'stay' ? (
                    !x.meta
                      ? x.arrivalDate ? generateDateStr(x.arrivalDate) : String.fromCharCode(160) //code 160 === &nbsp
                      : x.meta.arrivalDate ? generateDateStr(x.meta.arrivalDate) : String.fromCharCode(160)
                  ) : x.type === 'trip' ? (
                    x.departureDate ? generateDateStr(x.departureDate) : String.fromCharCode(160)
                  ) : String.fromCharCode(160)
                }
              </div>
              <div className="title">{x.menuTitle ?? setMenuTitle(x)}</div>
            </div>
            <div className="buttons">
              <Button
                onClick={() => setLoginDetails({
                  operatorCode: x.operatorCode,
                  passcode: x.passcode || x.referenceCode,
                  redirect: true,
                  type: 'switch',
                  clientId: isUserAuthenticated
                    ? storedItins.find(itin => itin.vamoosId === (x.vamoosId || x.id))?.client?.id
                    : x.client?.id,
                  phase: isUserAuthenticated
                    ? !!storedItins.find(itin => itin.vamoosId === x.id) ? 'refresh' : 'initial'
                    : 'refresh',
                })}
              >
                {t(i === 0 ? 'current' : 'open')}
              </Button>
              <Button
                onClick={() => deleteItem(x)}
              >
                {t('delete')}
              </Button>
            </div>
          </div>
        )
      );
  };

  return (
    <Container
      className={`container-view-all container-view-all-${viewMode}`}
      mainElementClassName={`view-all view-all-${viewMode}`}
      barebonesMode={!navMenuItems}
      closePath={closePath}
      mobileHeaderButtonRight={{
        className: "load-new-link",
        component: Link,
        to: '/login',
        icon: <AddIcon className="load-new-link-icon" />
      }}
    >
      {
        (spinner || showAppSpinner) &&
        <div className="center-spinner">
          <Spinner />
        </div>
      }
      {isUpdatingUserAuthInfo ? null : generateTiles()}
      {renderDeleteModal()}
    </Container>
  );

};

export default ViewAll;


