import { useContext } from 'react';
import Context from '../../context';
import './FlightDetails.scss';
import {
  differenceInCalendarDays,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  addMinutes,
} from 'date-fns';
import FlightIcon from '@mui/icons-material/Flight';

const FlightDetails = ({ data, formatDate, formatTime }) => {
  const { viewMode, t, itin } = useContext(Context);

  const { departure, arrival, ...flight } = data.flights[0];

  const cancelled = data.cancelled;

  const dateNow = Math.floor(Date.now() / 1000);

  const rawDeparture = addMinutes(Number(`${departure.scheduled}000`), (departure.airportTimeOffset * 60));
  const rawArrival = addMinutes(Number(`${arrival.scheduled}000`), (arrival.airportTimeOffset * 60));

  const departureDate = addMinutes(rawDeparture, rawDeparture.getTimezoneOffset());
  const arrivalDate = addMinutes(rawArrival, rawArrival.getTimezoneOffset());

  const estimatedDepartureDate = addMinutes(addMinutes(Number(`${departure.estimated}000`), (departure.airportTimeOffset * 60)), rawDeparture.getTimezoneOffset());
  const estimatedArrivalDate = addMinutes(addMinutes(Number(`${arrival.estimated}000`), (arrival.airportTimeOffset * 60)), rawArrival.getTimezoneOffset());

  const finalDeparture = Number(`${departure.estimated}000`);
  const timeDifference = differenceInDays(finalDeparture, new Date());
  const isActive = timeDifference > -1 && timeDifference < 1 && arrival.estimated > dateNow;

  const afterArrival = arrival.estimated < dateNow;
  const afterDeparture = departure.estimated < dateNow;

  const beforeArrival = arrival.estimated > dateNow;
  const beforeDeparture = departure.estimated > dateNow;

  const isTimeEstimated = (context) => {
    switch (context) {
      case 'arrival':
        return arrival.scheduled !== arrival.estimated;
      case 'departure':
        return departure.scheduled !== departure.estimated;
      default:
        return false;
    }
  };

  const timeDistance = (leftDate, rightDate) => {
    const days = differenceInDays(leftDate, rightDate);
    const hours = differenceInHours(leftDate, rightDate) - (days * 24);
    const minutes = differenceInMinutes(leftDate, rightDate) - (differenceInHours(leftDate, rightDate) * 60);

    return `${days ? `${days}${t('days_abbreviation_one_character', { lng: itin.language })} ` : ''}${hours ? `${hours}${t('hours_abbreviation_one_character', { lng: itin.language })} ` : ''}${minutes}${t('minutes_abbreviation_one_character', { lng: itin.language })}`;
  };

  const daysDifference = differenceInCalendarDays(arrivalDate, departureDate);

  const flightTime = timeDistance(
    Number(`${arrival.estimated}000`),
    Number(`${departure.estimated}000`),
  );

  //Check if the live/estimate time differs from the scheduled. If it exceeds acceptableDifference, applied class 'alert'
  const differenceFromSchedule = (context, acceptableDifference = 15) => {
    return context.estimated - context.scheduled > acceptableDifference
  }

  // return <div>Loading</div>;

  if (!data) {
    return <div>Loading</div>;
  }

  return (
    <div className={`flight-details flight-details-${viewMode} ${isActive && !cancelled ? 'active' : ''}`}>
      <div className="header">
        <div className="row top">
          <div className="left">{departure.airportName}</div>
          <div className="right">{arrival.airportName}</div>
        </div>
        <div className="row middle">
          <div className="left huge-text">{departure.airportCode}</div>
          <div className="center huge-text icon"><FlightIcon className="icon-styling" /></div>
          <div className="right huge-text">{arrival.airportCode}</div>
        </div>
        <div className="row bottom">
          <div className="left">{formatDate(departureDate)}</div>
          <div className="center">
            <span className="flight-duration">{flightTime}</span>
          </div>
          <div className="right">{formatDate(arrivalDate)}</div>
        </div>
      </div>
      <div className="body flight-times">
        <div className="row day-difference">
          {/*<div className="s6 offset-s6">*/}
            <div className="empty-space" />
            <span>
              {daysDifference > 0 ? `+${t(daysDifference > 1 ? 'day_plural[other]' : 'day_plural[one]', { lng: itin.language }).replace('%d', daysDifference)}` : ''}
            </span>
          {/*</div>*/}
        </div>
        <div className="row default-times">
          <div className="center huge-text">
            <span className="text-cage">{formatTime(departureDate)}</span>
          </div>
          <div className="center huge-text">
            <span className="text-cage">{formatTime(arrivalDate)}</span>
          </div>
        </div>

        <div className="row alerts">
          <div
            className={`center big-text ${differenceFromSchedule(departure) ? 'alert' : ''}`}
          >{ (afterDeparture || (beforeDeparture && isTimeEstimated('departure'))) &&
          (<>
            <sup>{t(beforeDeparture ? 'flights_estimated_text' : 'flights_actual_text', { lng: itin.language })} </sup>
            {formatTime(estimatedDepartureDate)}
          </>)
          }
          </div>
          <div
            className={`center big-text ${differenceFromSchedule(arrival) ? 'alert' : ''}`}
          >{ (afterArrival || (beforeArrival && isTimeEstimated('arrival'))) &&
            (<>
              <sup>{t(beforeArrival ? 'flights_estimated_text' : 'flights_actual_text', { lng: itin.language })} </sup>
              {formatTime(estimatedArrivalDate)}
            </>)
          }
          </div>
        </div>
        {(cancelled &&
          <div className="row cancelled">
            <div className="center alert">
              <p dangerouslySetInnerHTML={{ __html: t('flight_canceled_text', { lng: itin.language }).replace(/\n/, '<br />') }} />
            </div>
          </div>
        )}
      </div>
      <div className="separator">
        <div className="line" />
      </div>
      <div className="body flight-airport-info">
        <div className="row headers flight-num-airline">
          <div className="left grayed">{t('flight_details_flight', { lng: itin.language })}</div>
          <div className="left grayed">{t('flight_details_airline', { lng: itin.language })}</div>
        </div>
        <div className="row flight-num-airline">
          <div className="left"><strong>{flight.airlineCode}{flight.flightNumber}</strong></div>
          <div className="left"><strong>{flight.airlineName}</strong></div>
        </div>
        <div className="row headers">
          <div className="left grayed">{t('flight_details_departure', { lng: itin.language })}</div>
          <div className="left grayed">{t('flight_details_terminal', { lng: itin.language })}</div>
          <div className="left grayed">{t('flight_details_gate', { lng: itin.language })}</div>
        </div>
        <div className="row">
          <div className="left"><strong>{departure.airportCode || '--'}</strong></div>
          <div className="left"><strong>{departure.terminal || '--'}</strong></div>
          <div className="left"><strong>{departure.gate || '--'}</strong></div>
        </div>
        <div className="row headers">
          <div className="left grayed">{t('flight_details_arrival', { lng: itin.language })}</div>
          <div className="left grayed">{t('flight_details_terminal', { lng: itin.language })}</div>
          <div className="left grayed">{t('flight_details_gate', { lng: itin.language })}</div>
        </div>
        <div className="row">
          <div className="left"><strong>{arrival.airportCode || '--'}</strong></div>
          <div className="left"><strong>{arrival.terminal || '--'}</strong></div>
          <div className="left"><strong>{arrival.gate || '--'}</strong></div>
        </div>
        {/*<div className="row headers last-updated-at">*/}
        {/*  <div className="left grayed">*/}
        {/*    {t('last_updated_prefix', { lng: itin.language })}*/}
        {/*    /!*{ this.state.distanceInWordsToNow } {this.state.distanceInWordsToNow !== 'Just now' ? 'ago' : ''}*!/*/}
        {/*    Just now*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>
      <div className="map">
        {
          flight.map.url &&
          <img
            src={flight.map.url}
            width="100%"
            alt="map"
          />
        }
      </div>
    </div>
  );
};

export default FlightDetails;
