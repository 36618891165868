import * as React from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import Login from "../Login";
import Context from "../../context";
import { db } from "../../db";

//for direct login used link like:
// http://localhost:3333/quickstart?operatorCode=VMD&passcode=VL1234
//
// if Stays does not have any passcode, the link is used without the passcode, like:
// http://localhost:3333/quickstart?operatorCode=rebali
const DirectLogin = () => {
  const [withCredentials, setWithCredentials] = useState(false);
  const navigate = useNavigate();

  const { setIsDirectLogin } = useContext(Context);

  const [searchParams, setSearchParams] = useSearchParams();
  const credentialsFromUrl = Object.fromEntries([...searchParams]);

  useEffect(() => {
    if (!credentialsFromUrl.operatorCode) return;
    searchParams.delete('operatorCode');
    searchParams.delete('passcode');
    searchParams.delete('vamoosId');
    setSearchParams(searchParams);
    setWithCredentials(true);
  }, [credentialsFromUrl.operatorCode, searchParams, setSearchParams]);

  // if direct link does not contain operatorCode navigate to login page
  useEffect(() => {
    if (Object.keys(credentialsFromUrl)[0] === 'operatorCode' || withCredentials) return;
    navigate(`/login/1`, { replace: true });
    setIsDirectLogin(false);
  }, [credentialsFromUrl, navigate, setIsDirectLogin, withCredentials]);

  useEffect(() => {
    if (Object.entries(credentialsFromUrl).length === 0 || !credentialsFromUrl.vamoosId) return;
    const removeClientInfo = async () => {
      const storedItins = await db.itineraries.toArray();
      const foundItin = storedItins.find(itin => itin.vamoosId === parseInt(credentialsFromUrl.vamoosId));

      if (foundItin) await db.itineraries.update(foundItin.id, { client: null });
    };
    removeClientInfo();
  }, [credentialsFromUrl])

  return (
    <Login
      directLoginOperatorCode={credentialsFromUrl.operatorCode}
      directLoginPasscode={credentialsFromUrl.passcode}
    />
  )
}

export default DirectLogin;
