import { useContext, useState, useRef } from "react";
import Context from '../../context';
import './MapNav.scss';
import MapNavigationButton from "../global/Buttons/MapNavigation";
import React from "react";
import PoiTypeOptionsModal from "./PoiTypeOptionsModal";
import { sortCategories } from "../../helpers";

const MapNav = ({
                  satelliteViewActive, setSatelliteViewActive,
                  getShowLabelsVal, updateShowLabelsVal, removeLayer, setIsShowAllPressed
                }) => {
  const { globalMapSettings, setGlobalMapSettings, icons, t, setUserCameFromPoiList, viewMode, itin } = useContext(Context);
  const mapNav = useRef();

  const trackIconId = itin.tracks[0]?.iconId;
  const routeIconId = itin.routes[0]?.iconId;
  const [activeButtonId, setActiveButtonId] = useState('');
  const [trackListWidth, setTrackListWidth] = useState(0);
  const [poiTypeIdsModalActive, setPoiTypeIdsModalActive] = useState({
    [trackIconId]: false,
    [routeIconId]: false,
  });

  const poiTypeIdModalItems = Object.keys(poiTypeIdsModalActive);

  const togglePoiCategory = (event, iconId) => {
    if (event.key === 'Escape' || event.key === 'Tab') setPoiTypeIdsModalActive({ [trackIconId]: false, [routeIconId]: false });
    if (event.key === 'Enter' || event.type === 'click') {
      setActiveButtonId(iconId.toString());
      const categories = { ...globalMapSettings.poiCategories };
      const newPoiTypeIdsModalActive = { ...poiTypeIdsModalActive };   //this variable will allow re-render component when object in poiTypeIdsModalActive will change
      let categoryMatched = false;    //this variable will stop the execution of the togglePoiCategory function after the forEach loop has been executed

      Object.keys(newPoiTypeIdsModalActive).forEach(typeId => {
        if (iconId.toString() === typeId ) {
          newPoiTypeIdsModalActive[typeId] = !newPoiTypeIdsModalActive[typeId];
          setPoiTypeIdsModalActive(newPoiTypeIdsModalActive);
          categoryMatched = true;
        }
      });
      if (categoryMatched) return;

      categories[iconId].active = !categories[iconId].active;
      setGlobalMapSettings({ ...globalMapSettings, poiCategories: categories });
      setUserCameFromPoiList(false);
    }
  };

  const togglePoiType = (isActive, poiType = null, type = null) => {
    if (poiType !== null ) setIsShowAllPressed(false);
    if (isActive && poiType === null && type !== null ) setIsShowAllPressed(true);
    if (poiType === null && type === null) poiType = Object.values(globalMapSettings.pois).filter(x => poiTypeIdModalItems.includes(x.iconId.toString()));
    if (type) poiType = Object.values(globalMapSettings.pois).filter(x => x.iconId === type);

    const { pois } = globalMapSettings;
    if (!Array.isArray(poiType)) poiType = [poiType];
    if (!poiType?.length) return;
    const newPois = { ...pois };
    for (const type of poiType) {
      newPois[type.id].active = isActive;
      for (const poi of newPois[type.id].pois) {
        poi.active = isActive;
      }
      if (!isActive) {
        removeLayer(type.id)
      }
    }
    setGlobalMapSettings(globalMapSettings => {
      const { poiCategories } = globalMapSettings;
      const updatedPoiCategories = {};
      const newPoiItems = Object.values(newPois);
      const poiCategoryIds = Object.keys(poiCategories);

      poiTypeIdModalItems.forEach(type => {
        const poiTypeIconId = poiCategoryIds.find(x => x === type)

        if (poiTypeIconId) {
          const anyActive = newPoiItems.some(x => x.iconId.toString() === type && x.active);
          updatedPoiCategories[poiTypeIconId] = {
            ...poiCategories[poiTypeIconId],
            active: anyActive,
          };
        }
      });

      return {
        ...globalMapSettings,
        pois: newPois,
        poiCategories: {
          ...poiCategories,
          ...updatedPoiCategories
        }
      };
    });
    setPoiTypeIdsModalActive(poiTypeIdsModalActive => {
      for (const typeId in poiTypeIdsModalActive) {
        poiTypeIdsModalActive[typeId] = false;
      }
      return poiTypeIdsModalActive;
    });
    setUserCameFromPoiList(false);
  };

  const renderShowAll = () => {
    const { poiCategories } = globalMapSettings;
    if (!poiCategories) return null;
    const vals = Object.values(poiCategories);
    if (vals?.length < 3) return null;

    const isActive = vals.some(x => x.active);
    const toggleShowAll = event => {
      if (event.key === 'Enter' || event.type === 'click') {
        const categories = { ...poiCategories };
        for (const category in categories) {
          if (category.match(new RegExp(poiTypeIdModalItems.join('|'), 'i'))) continue;
          categories[category].active = !isActive;
        }
        setGlobalMapSettings({ ...globalMapSettings, poiCategories: categories });
        togglePoiType(!isActive);
        setIsShowAllPressed(!isActive);
        setUserCameFromPoiList(false);
      }
    };

    return (
      <MapNavigationButton
        onClick={toggleShowAll}
        imgSrc={`/icons/ic_maps_${isActive ? 'clear' : 'show_all'}.svg`}
        label={t( isActive ? 'clear' : 'maps_show_all_icon_title', { lng: itin.language })}
        variant="maps"
        hideTick={true}
        tabIndex="0"
        onKeyDown={toggleShowAll}
      />
    );
  };

  //calculate the position of the modal box so that it is positioned exactly above the pressed button
  const modalPosition = () => {
    if (activeButtonId && viewMode !== 'mobile') {
      const button = document.getElementById(activeButtonId);
      if (button) {
        const buttonRect = button.getBoundingClientRect();
        return {
          left: buttonRect.left + buttonRect.width/2 - trackListWidth/2
        };
      }
    }
    return null;
  };

  const renderPoiCategories = (type) => {
    const sortedPoiCategories = sortCategories(globalMapSettings.poiCategories, itin);

    return sortedPoiCategories.map((cat, i) => {
      const poiTypeId = poiTypeIdModalItems.find(x => x === cat.id.toString());

      const poiTypeItems = poiTypeId ? Object.values(globalMapSettings.pois).filter(x => x.iconId?.toString() === poiTypeId) : null;
      const anyModalItemsActive = poiTypeItems?.some(x => x.active);
      const isCategoryActive = poiTypeId ? anyModalItemsActive : cat.active;

      if (type === 'poi') {
        return (
          <MapNavigationButton
            key={i}
            onClick={event => togglePoiCategory(event, cat.id)}
            imgSrc={icons[cat.id]}
            label={cat.label}
            variant="maps"
            active={isCategoryActive}
            tabIndex="0"
            onKeyDown={event => togglePoiCategory(event, cat.id)}
            id={cat.id}
          >
          </MapNavigationButton>
        );
      } else {
        return (
          (poiTypeId && poiTypeIdsModalActive[poiTypeId]) && (
            <PoiTypeOptionsModal
              key={i}
              poiTypeItems={poiTypeItems}
              togglePoiType={togglePoiType}
              anyPoiTypeActive={isCategoryActive}
              allPoiTypeActive={anyModalItemsActive ? poiTypeItems?.every(x => x.active) : false}
              togglePoiCategory={togglePoiCategory}
              id={cat.id}
              modalPosition={modalPosition}
              setTrackListWidth={setTrackListWidth}
            />
          )
        );
      }
    })
  };

  // For navbar horizontal scroll
  mapNav.current?.addEventListener("wheel", (event) => {
    //event.preventDefault();
    mapNav.current.scrollLeft += (event.deltaY / (Object.values(globalMapSettings.poiCategories).length * 2));
  }, { passive: true });

  return (
    <>    <div id="map-nav"
               ref={mapNav}
    >
      <div className="scrollable-nav">
        <MapNavigationButton
          onClick={updateShowLabelsVal}
          imgSrc={icons[1]}
          label={t('maps_show_all_labels_icon_title', { lng: itin.language })}
          variant="maps"
          active={getShowLabelsVal()}
          tabIndex="0"
          onKeyDown={event => event.key === 'Enter' ? event.stopPropagation() & updateShowLabelsVal() : null}
        />
        <MapNavigationButton
          onClick={() => setSatelliteViewActive(!satelliteViewActive)}
          imgSrc="/icons/ic_maps_satellite.svg"
          label={t('maps_satellite_icon_title', { lng: itin.language })}
          variant="maps"
          active={satelliteViewActive}
          tabIndex="0"
          onKeyDown={event => event.key === 'Enter' ? setSatelliteViewActive(!satelliteViewActive) : null}
        />
        { renderPoiCategories('poi') }
        { renderShowAll() }
      </div>
    </div>
      <div>
        { renderPoiCategories('type') }
      </div>
    </>
  );
};

export default MapNav;
