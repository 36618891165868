import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Context from "../../context";
import DocumentViewerScreen from "../global/DocumentViewer";

const DocumentViewer = () => {
  const [data, setData] = useState(null);

  const { itin } = useContext(Context);
  const { slideNum } = useParams();

  useEffect(() => {
    const dayData = itin.brief[parseInt(slideNum)-1];
    const dayDocsData = dayData?.location ? [...dayData?.documents, dayData.location] : dayData?.documents;
    setData(dayDocsData);
  }, [slideNum, itin.brief]);

  if (!data) return <div>Loading</div>;

  return <DocumentViewerScreen type="dailyDocuments" dailyData={data} />
};

export default DocumentViewer;