import Container from '../global/Container';
import { useState, useContext, useEffect, useRef } from 'react';
import { getDistance } from 'geolib';
import { useNavigate } from "react-router-dom";
import Context from '../../context';
import './index.scss';
import MapNavigationButton from '../global/Buttons/MapNavigation';
import { ToggleButtonGroup, ToggleButton } from '@mui/material';
import { sortCategories, toggleAllPoiCategoryAndPoiType } from "../../helpers";

export function PoiList() {
    const { itin: itinerary, icons, preferredDistanceUnit, setPreferredDistanceUnit, globalMapSettings, setGlobalMapSettings, setUserCameFromPoiList, setScreenNameOverride, t } = useContext(Context);
    const [ homeLocation, setHomeLocation ] = useState(null);
    const pois = itinerary.pois.concat(itinerary.tracks, itinerary.routes);
    const navigate = useNavigate();
    const poiCategories = useRef();
    const poiTypeIds = [itinerary.tracks[0]?.iconId, itinerary.routes[0]?.iconId]

// For horizontal scroll poi-categories
    poiCategories.current?.addEventListener("wheel", (event) => {
       // event.preventDefault();
        poiCategories.current.scrollLeft += (event.deltaY/(sortedPoiCategories.length*2));
    }, {passive: true});

    useEffect(() => {
        // Set to first location
        if (itinerary.locations.length) {
            setHomeLocation({
                latitude: itinerary.locations[0].latitude,
                longitude: itinerary.locations[0].longitude,
            });
        }
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(position => {
                setHomeLocation({
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude,
                });
            })
        }
    }, [itinerary.locations]);

    useEffect(() => setUserCameFromPoiList(false), [setUserCameFromPoiList]);

    useEffect(() => {
        const poiMenu = itinerary.menu.find(x => x.segue === 'POI list');
        setScreenNameOverride(poiMenu?.label || t('poi_list'));
        return () => setScreenNameOverride(null);
    }, [t, itinerary.menu, setScreenNameOverride]);

    const distanceUnitMultiplier =
        (preferredDistanceUnit === 'km') ? 0.001 /* km per m */ : 0.000621371 /* miles per meter */;

    const sortedPoiCategories = sortCategories(globalMapSettings.poiCategories, itinerary);

    // Only show POIs from categories which have been selected by the user. If no selected categories, show all POIs.
    const anyPoisActive = sortedPoiCategories.some(x => x.active);
    const displayPois = pois.filter(x => {
        if (!anyPoisActive) return true;
        return globalMapSettings.poiCategories[x.iconId].active;
    });

    const handleChangeActivePoiCategories = (event, id) => {
        if (event.key === 'Enter' || event.type === 'click') {
            setGlobalMapSettings(globalMapSettings => {
                const out = { ...globalMapSettings };
                out.poiCategories[id].active = !out.poiCategories[id].active;
                if (poiTypeIds.includes(id)) {
                    const poisFromChangeableCategory = Object.values(out.pois).filter(poi => poi.iconId === id);
                    poisFromChangeableCategory.forEach(poi => {
                        poi.active = out.poiCategories[id].active;
                    })
                }
                return out;
            })
        }
    }

    const navigateToMap = (event, id) => {
        if (event.key === 'Enter' || event.type === 'click') {
            //if no category was activated when user navigate to the map section, activate all categories
            if (!Object.values(globalMapSettings.poiCategories).find(x => x.active)) {
                const { newPois, categories } = toggleAllPoiCategoryAndPoiType(globalMapSettings, true);
                setUserCameFromPoiList(true);
                setGlobalMapSettings({
                    ...globalMapSettings,
                    pois: newPois,
                    poiCategories: categories
                });
            }
            navigate(`/${itinerary.localData.urlKey}/maps/${id}`);
        }
    }

    return (
        <Container className="container-poi-list">
            <ul className="poi-list">
                {displayPois.map((poi, i) => (
                  <li key={i}
                      onClick={event => navigateToMap(event, poi.id)}
                      onKeyDown={event => navigateToMap(event, poi.id)}
                      tabIndex="0"
                  >
                      <div className="name">
                          {poi.infoLabel}
                      </div>
                      <div className="description">
                          {poi.longDescription}
                      </div>
                      <div className="icon">
                          <img src={icons[poi.iconId]} alt={poi.iconLabel}/>
                          <span className="distance">
                            {
                                (homeLocation?.latitude) ? (
                                  <>
                                      {Math.round(getDistance(homeLocation, poi) * distanceUnitMultiplier)}
                                      &nbsp;
                                      <span className="unit">{preferredDistanceUnit}</span>
                                  </>
                                ) : (<> </>)
                            }
                            </span>
                      </div>
                  </li>
                ))}
            </ul>
            <div className="bottom">
                <div className="toggle-poi-buttons">
                    <ToggleButtonGroup
                      value={preferredDistanceUnit}
                      onChange={(e,value) => setPreferredDistanceUnit(value)}
                      exclusive={true}
                      className="right"
                    >
                        <ToggleButton value="km">km</ToggleButton>
                        <ToggleButton value="mi">miles</ToggleButton>
                    </ToggleButtonGroup>
                </div>
                <ul className="poi-categories"
                    ref={poiCategories}>
                    {
                        sortedPoiCategories.map((cat, i) => (
                          <li key={`${i}li`}>
                              <MapNavigationButton
                                key={i}
                                imgSrc={icons[cat.id]}
                                label={cat.label}
                                variant="pois"
                                onClick={(event) => handleChangeActivePoiCategories(event, cat.id)}
                                onKeyDown={(event) => handleChangeActivePoiCategories(event, cat.id)}
                                active={globalMapSettings.poiCategories[cat.id].active}
                                tabIndex="0"
                              />
                          </li>
                        ))
                    }
                </ul>
            </div>
        </Container>
    );
}

export default PoiList;
