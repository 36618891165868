import './Map.scss';
import { useContext, useEffect, useState } from "react";
import Context from "../../context";
import { useParams } from "react-router-dom";
import MapScreen from '../Map';

const Map = () => {
  const [data, setData] = useState(null);
  const { itin } = useContext(Context);
  const { slideNum } = useParams();

  useEffect(() => {
    setData(itin.brief[parseInt(slideNum)-1]?.location);
  }, [itin.brief, slideNum]);

  if (!data) return <div>Loading</div>;

  return <MapScreen type="daily" locationId={data.id} />
};

export default Map;
