import { useContext } from 'react';
import Context from '../../context';
import './PasscodeScreen.scss';
import SlideTemplate from "./SlideTemplate";

const PasscodeScreen = ({ skippable, defaultReferenceCode, isActive, passcode, setPasscode, skipUserId, submitFn, id }) => {
  const { t, composerData } = useContext(Context);

  const renderTexts = () => {
    if (!skipUserId) {
      return { text3: t(skippable ? 'login_passcode_header_text_hotel' : 'login_passcode_header_text_trip') }
    }
    return {
      text1: composerData?.strings?.login_text_1?.text ?? t('login_text_1'),
      text2: composerData?.strings?.login_text_2?.text ?? t('login_text_2'),
      text3: composerData?.strings?.login_text_3?.text ?? t('login_text_3'),
      text4: composerData?.strings?.login_text_4?.text ?? t('login_text_4'),
      text5: composerData?.strings?.login_text_5?.text ?? t('login_text_5'),
    };
  };

  return (
    <SlideTemplate
      className="passcode-screen"
      {...renderTexts()}
      inputList={[{
        value: passcode,
        onChange: val => setPasscode(val),
        placeholder: t('login_passcode_hint'),
        className: 'passcode-input',
        type: '',
        autoFocus: true,
      }]}
      button={{
        disabled: !passcode,
        text: composerData?.strings?.login_next_button?.text || t('login_next_button'),
      }}
      skipFn={skippable ? () => submitFn(defaultReferenceCode) : null}
      isActive={isActive}
      submitFn={() => submitFn(passcode)}
      id={id}
    />
  );
};

export default PasscodeScreen;