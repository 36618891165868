import React, { useContext, useRef, useEffect, useState } from 'react';
import Context from '../../context';
import './SlideTemplate.scss';
import Button from '../global/Buttons/Button';
import TextInput from "../global/TextInput";
import { db } from "../../db";
import Spinner from "../global/Spinner";
import SignIn from "./SignIn";

const SlideTemplate = ({
  className,
  text1,
  text2,
  text3,
  text4,
  text5,
  text6,
  text7,
  inputList,
  skipFn,
  button: { disabled, text },
  isActive,
  submitFn,
  hideContent,
  logoOverride,
  id
}) => {
  const { t, composerData, isUserAuthenticated, userAuthInfo, showAppSpinner, isLoginRoute } = useContext(Context);
  const inputRef = useRef(null);
  const [isUserHasItineraries, setIsUserHasItineraries] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (isActive) {
      // TODO: this is causing the login screen margin to load incorrectly for stays.
      // userid => passcode => should go to stays personal info screen. Instead goes to stays dates screen.
      // The margin is the correct value required for the stays personal info screen.
      // Commenting out the below line makes it behave as it should.
      // inputRef.current.focus();
      // inputRef.current.style.border = '5px solid red';
    }
  }, [id, isActive]);

  useEffect(() => {
    let isMounted = true;
    setIsLoaded(true);
    const getStoredItineraries = async () => {
      const storedItineraries = await db.itineraries.toArray();
      if (storedItineraries.length > 0 || userAuthInfo?.logins?.all.length > 0) {
        if (isMounted) {
          setIsUserHasItineraries(true);
        }
      } else {setIsUserHasItineraries(false)}
      setIsLoaded(false)
    };
    getStoredItineraries();

    return () => {
      isMounted = false;
      setIsLoaded(false)
    };
  }, [userAuthInfo?.logins?.all.length, setIsUserHasItineraries, isLoginRoute]);

  const onInputChange = (val, cb) => {
    cb(val.replace(/^\s+/g, ''));
  };

  const placeholderText = <>placeholder<br />placeholder</>;

  const renderPromptMessage = () => {
    //it added to avoid prompt flickering
    if (isUserAuthenticated && isUserHasItineraries === null) {
      return null;
    }
    if (isUserAuthenticated && isUserHasItineraries === false) {
      return (
        <>
          {(showAppSpinner || isLoaded) ? (
            <div>
              <Spinner type="small"/>
            </div>
          ) : (
            <>
              <div
                className={`text text-6 ${!text6 ? 'invisible-text' : ''}`}
                dangerouslySetInnerHTML={{ __html: text6 || placeholderText }}
              />
              <div
                className={`text text-7 ${!text7 ? 'invisible-text' : ''}`}
                dangerouslySetInnerHTML={{ __html: text7 || placeholderText }}
              />
            </>
          )}
        </>
      )
    }
    };

  return (
    <div id={id} className={`login-slide ${className} ${hideContent ? 'hidden' : ''}`}>
      <img className="logo" src={logoOverride || composerData?.images?.logo?.filePath || '/images/vamoos.png'} alt="" />
      <div className="text-container-top">
        <div
          className={`text text-1 ${!text1 ? 'invisible-text' : ''}`}
          dangerouslySetInnerHTML={{ __html: text1 || placeholderText }}
        />
        <div
          className={`text text-2 ${!text2 ? 'invisible-text' : ''}`}
          dangerouslySetInnerHTML={{ __html: text2 || placeholderText }}
        />
        <div
          className={`text text-3 ${!text3 ? 'invisible-text' : ''}`}
          dangerouslySetInnerHTML={{ __html: text3 || placeholderText }}
        />
      </div>
      {
        inputList &&
        inputList.map(({ value, onChange, placeholder, className, type, autoFocus }, i) => (
          <TextInput
            key={i}
            value={value}
            onChange={e => onInputChange(e.target.value, onChange)}
            placeholder={placeholder}
            className={`login-input ${className}`}
            type={type}
            onKeyUp={e => {
              if (!isActive || disabled) return;
              if (e.code === 'Enter') submitFn();
            }}
            ref={i === 0 ? inputRef : null}
            autoFocus={autoFocus === true}
          />
        ))
      }
      <div
        className={`text text-4 ${!text4 ? 'invisible-text' : ''}`}
        dangerouslySetInnerHTML={{ __html: text4 || placeholderText }}
      />
      <Button disabled={disabled} onClick={() => submitFn()}>{text}</Button>
      {
        skipFn && <button onClick={() => skipFn()} className="skip">{t('login_skip_button')}</button>
      }
      <div
        className={`text text-5 ${!text5 ? 'invisible-text' : ''}`}
        dangerouslySetInnerHTML={{ __html: text5 || placeholderText }}
      />
      {className === "user-id-screen" && (
        <>
          {!isUserAuthenticated && (
            <>
              <SignIn />
            </>
          )}
          {renderPromptMessage()}
        </>
      )}
    </div>
  );
};

export default SlideTemplate;
