import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Spinner from '../Spinner';
import './index.scss';

export default function SimpleBackdrop() {
  return (
    <div className="loading-screen">
      <Backdrop
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
      >
        <Spinner />
      </Backdrop>
    </div>
  );
}
