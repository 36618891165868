import './Photobook.scss';

const PhotobookIcon = (props) => {
  return (
    <a href={props.photobookLink} target="_blank" rel="noreferrer">
      {
        <img
          className="photobook-icon"
          src={props.icon}
          alt=""
        />
      }
    </a>
  );
};

export default PhotobookIcon;
