import { useContext } from 'react';
import CookieConsent from "react-cookie-consent";
import React from "react";
import Context from '../../context';
import './index.scss';

const CookieBanner = () => {
  const { viewMode, t } = useContext(Context);
  // TODO translation
  return (
    <>
      <CookieConsent
        buttonText={t('web_cookie_banner_ok')}
        location="top"
        containerClasses={`cookie-consent-container cookie-consent-container-${viewMode}`}
        contentClasses={`cookie-consent-content cookie-consent-content-${viewMode}`}
        buttonWrapperClasses={`cookie-consent-button-wrapper cookie-consent-button-wrapper-${viewMode}`}
        buttonClasses={`cookie-consent-button cookie-consent-button-${viewMode}`}
      >
        <div dangerouslySetInnerHTML={ {
          __html: t('web_cookie_banner', { linkStart: '<a href="https://www.vamoos.com/privacy" target="_blank" rel="noreferrer">', linkEnd: '</a>' }) 
        } }></div>
      </CookieConsent>
    </>
  )
};

export default CookieBanner;