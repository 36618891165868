import { useEffect, useRef } from 'react';
import './InspirationBar.scss';

const InspirationBar = ({ items, currentSectionIdx, navigateTo }) => {
  const marginX = 20;
  const inspirationBarContainer = useRef();

  useEffect(() => {
    let marginLeft = 0;
    const items = Array.from(document.querySelectorAll('.inspiration-bar-item'));
    items.slice(0, currentSectionIdx).forEach(x => marginLeft += marginX * 2 + x.clientWidth);  // width of all scrolled items
    marginLeft += items[currentSectionIdx].clientWidth / 2 + marginX; // 1/2 of active item width
    const barSlide = document.getElementById('insp-bar');
    const halfViewportWidth = window.innerWidth / 2;

    //right padding for scrolling the last item
    currentSectionIdx === items.length - 1
      ? barSlide.style.paddingRight = `${(window.innerWidth - items[currentSectionIdx].clientWidth) / 2}px`
      : barSlide.style.paddingRight = '0';

    //To be able scrolling the inspiration bar on mobile view
    // the marginLeft is replaced to scrollLeft when margin goes into negative values
    if (marginLeft < halfViewportWidth) {
      inspirationBarContainer.current.scrollLeft = 0;
      barSlide.style.marginLeft = `calc(50vw - ${marginLeft}px)`;
    } else {
      barSlide.style.marginLeft = '0';
      inspirationBarContainer.current.style.scrollBehavior = 'smooth';
      inspirationBarContainer.current.scrollLeft = marginLeft - halfViewportWidth;
    }
  }, [currentSectionIdx]);

  const onKeyPressed = (event, idx) => {
    if (event.key === 'Enter') {
      navigateTo(idx)
    }
  }

  return (
    <div className="inspiration-bar-container"
         ref={inspirationBarContainer}>
      <ul
        className="inspiration-bar"
        id="insp-bar"
      >
        {
          items.map((x, i) => (
            <li
              style={{ margin: '0 ' + marginX + 'px' }}
              className={`inspiration-bar-item ${(i === currentSectionIdx ? ' active' : '')}`}
              key={i}
              onClick={() => navigateTo(x.positionIdx)}
              onKeyDown={(event) => onKeyPressed(event, x.positionIdx)}
              tabIndex="0"
            >
              <div className="title">{x.title}</div>
              <div className="indicator"  />
            </li>
          ))
        }
      </ul>
    </div>
  );
};

export default InspirationBar;
