import { useEffect } from 'react';

// @param {string} key - the name of the key to respond to, compared against event.key
// @param {function} action - the action to perform on key press

export default function useKeypress(key, deps, action) {

  useEffect(() => {
    function onKeydown(event) {
      if (event.key === key) action(event)
    }
    document.addEventListener('keydown', onKeydown);
    return () => document.removeEventListener('keydown', onKeydown);
  }, [action, deps, key]);
}
