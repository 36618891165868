import { useEffect } from "react";
import './InspirationSlide.scss';


const InspirationSlide = ({ data, logo }) => {


  useEffect(() => {
    const links = document.querySelectorAll('.inspiration-slide a:not([href^="tel:"])');

    links.forEach(link => {
      link.setAttribute('target', '_blank');
    });
  }, []);

  return (
    <div className="inspiration-slide">
      <div
        className="content"
        style={{ backgroundImage: `url("${data.imageNode.file.httpsUrl}")` }}
      >
        <div className="content-inner">
          { logo && <img className="logo" src={logo} alt="" /> }

          {
            (data.description || data.title) && (
              <div className="text-content">
                { data.title && <div className="title">{data.title}</div> }
                { data.description && <div className="description" dangerouslySetInnerHTML={{ __html: data.description }} /> }
              </div>
            )

          }
        </div>
        <div className="contact-button-gradient" />
      </div>
    </div>
  );
};

export default InspirationSlide;
