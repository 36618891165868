import { useContext, useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Context from '../../../context';
import HamburgerMenu from '../HamburgerMenu';
import './index.scss';
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import useKeypress from "../../../hooks/useKeypress";

const Header = ({ type, closePath, barebonesMode, buttonColor, backButtonClickFn }) => {
  const [generatedClosePath, setGeneratedClosePath] = useState('/');
  const { itin, removeItemsFromCustomHistory, viewMode, previewMode, customHistory }  = useContext(Context);
  const navigate = useNavigate();
  const backButtonHeader = useRef(null);
  const closeBriefButton = useRef(null);
  const { pathname } = useLocation();
  const logo = document.querySelector('div > div.container.container-desktop.container-brief > header > img')

  //hide the duplicate main logo for the daily/brief section
  useEffect(() => {
    if (!pathname.includes('daily') || logo === null) return;
    logo.style.visibility = 'hidden'
  }, [logo, pathname]);

  useEffect(() => {
    setGeneratedClosePath(() => {
      if (closePath !== undefined) {
        return (typeof closePath === 'number') ? customHistory[customHistory?.length + (closePath - 1)] : closePath;
      }
      if (itin) return `/${itin?.localData?.urlKey}`;
      return '/';
    });
  }, [closePath, customHistory, itin]);

  const renderHamburger = () => {
    if (previewMode || type === 'brief') return null;
    return (
      <div className="hamburger-btn-container">
        <HamburgerMenu hamburgerBtnClassName={buttonColor} />
      </div>
    );
  };

  useKeypress('Escape', [closeBriefButton, backButtonHeader], () => {
    if (closeBriefButton.current !== null) {
      closeBriefButton.current.click();
      navigate(closeBriefButton.current)
    } else if (backButtonHeader.current !== null) {
      backButtonHeader.current.click();
      navigate(backButtonHeader.current)
    }
  });

  const renderCloseBrief = () => {
    return (
      <div className="close-brief">
        <Link
          to={generatedClosePath}
          onClick={() => removeItemsFromCustomHistory(generatedClosePath)}
          className={"close-brief-btn"}
          ref={closeBriefButton}
        >
          <img src="/icons/exit.png" alt="" />
        </Link>
      </div>
    );
  };

  const renderBackButton = () => {
    if (generatedClosePath === null) return generatedClosePath;
    return (
      <IconButton
        className={`back-button button ${buttonColor}`}
        aria-label="delete"
        component={Link}
        to={generatedClosePath}
        onClick={() => {
          removeItemsFromCustomHistory(generatedClosePath);
          backButtonClickFn && backButtonClickFn();
        }}
        ref={backButtonHeader}
      >
        <ArrowBackIcon className="back-button-icon" />
      </IconButton>
    )
  };

  const renderButtonContent = () => {
    if (viewMode === 'desktop') {
      if (type === 'brief' && generatedClosePath) return renderCloseBrief();
      return (
        <>
          { barebonesMode && (closePath && pathname !== "/login/1") && renderBackButton() }
          { renderHamburger() }
        </>
      );
    }

    return (closePath && pathname !== "/login/1") ? renderBackButton() : renderHamburger();
  };

  return (
    <header className={`desktop-header header ${barebonesMode ? 'header-barebones' : ''}`}>
      { renderButtonContent() }
      { !barebonesMode && <img className={`logo ${(previewMode && !type) ? 'noLogoDisplay' : ''}`} src={itin?.operatorLogoNode?.file?.httpsUrl} alt=""/> }
    </header>
  );
};

export default Header;
