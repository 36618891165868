import Modal from './index';
import './ModalDefault.scss';

const ModalDefault = ({ header, message, buttons, children, className, ...props }) => {
  return (
    <Modal {...props}>
      <div className={`modal-body ${className ? className : ''}`}>
        { header && <div className="header">{header}</div> }
        { message && <div className="message">{message}</div> }
        { children }
        {
          (buttons?.length) && <div className="buttons">{buttons}</div>
        }
      </div>
    </Modal>
  );
};

export default ModalDefault;